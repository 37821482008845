import React, { useEffect, useState } from 'react';
import { ApplicationService, columns } from './ApplicationType'
import { doRequest } from '../Utils/utils';
import DataTable from '../ui/dataTable/datatable';
import Card from '../ui/card';
import LoadingBubbles from '../Common/LoadingBubbles';
import ApplicationsDataTable from './ApplicationsDataTable';

function ApplicationsTableView() {
  const [applications, setApplications] = useState<ApplicationService[]>([])
  const [applicationsLoading, setApplicationsLoading] = useState(false)

  useEffect(() => {
    setApplicationsLoading(true)
    doRequest('/api/v1/applications', 'get')
      .then(({ data, status }) => {
        if (status === 200) {
          setApplicationsLoading(false)
          setApplications(data.applications)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
    }}
    >
      <Card className="max-w-screen-xl m-auto w-full">
        <Card.Header className="mb-4">
          <Card.Title>Applications</Card.Title>
        </Card.Header>
        <Card.Content className="flex justify-center">
          {
            applicationsLoading
              ? <LoadingBubbles size={4} />
              : (
                <ApplicationsDataTable
                  data={applications}
                  hiddenColumns={['Manager', 'VP', 'Director']}
                />
              )
          }
        </Card.Content>
      </Card>
    </div>
  )
}

export default ApplicationsTableView;
