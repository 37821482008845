import React, { useEffect, useState } from 'react';
import './banner.css';

function Banner({
  children, type, onClose, style,
}) {
  const BANNER_OPTIONS = {
    warning: {
      label: 'Warning',
      value: 'warning',
      icon: 'icon_error_full',
    },
    error: {
      label: 'Error',
      value: 'error',
      icon: 'icon_plus-circle-filled',
    },
    info: {
      label: 'Info',
      value: 'info',
      icon: 'icon_info_full',
    },
    success: {
      label: 'Success',
      value: 'success',
      icon: 'icon_check-circle-full',
    },
  };
  const defaultType = BANNER_OPTIONS.info;

  const [options, setOptions] = useState({});
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    let option;
    if (typeof type === 'string') {
      option = BANNER_OPTIONS[type];
    }
    if (!option) {
      option = defaultType;
    }
    setOptions(option);
  }, []);

  function togglevisibility() {
    setVisible((prev) => !prev);
  }

  return (
    visible
    && (
      <div className={`banner ${options.value}`} style={style}>
        <div className="banner-header">
          <i className={`banner-icon ${options.icon}`} />
          <div className="banner-title">
            {options.label}
          </div>
        </div>
        <div className="banner-body">
          <div className="banner-content">
            {children}
          </div>
        </div>
        <div className="banner-footer">
          <i style={{ fontSize: 'large', cursor: 'pointer' }} className="icon_close" onClick={onClose || togglevisibility} />
        </div>
      </div>
    )
  );
}

export default Banner;
