// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0px auto;
  height: calc(100vh - 75px);
}
.userPage-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page-content {
  display: flex;
  flex-direction: column;
    flex:1;
  gap: 24px;
}
.content_row {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.content_column {
  display: flex;
  align-items: center;
  gap: 24px;
}
.setting-image_sm{
width: 30px;
}
.profile-footer{
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}`, "",{"version":3,"sources":["webpack://./src/recker/Pages/User-profile/profilePage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,gBAAgB;EAChB,0BAA0B;AAC5B;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;AACA;EACE,aAAa;EACb,sBAAsB;IACpB,MAAM;EACR,SAAS;AACX;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;AACA;AACA,WAAW;AACX;AACA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".page-container {\n  display: flex;\n  flex-direction: column;\n  max-width: 1200px;\n  margin: 0px auto;\n  height: calc(100vh - 75px);\n}\n.userPage-header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.page-content {\n  display: flex;\n  flex-direction: column;\n    flex:1;\n  gap: 24px;\n}\n.content_row {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n.content_column {\n  display: flex;\n  align-items: center;\n  gap: 24px;\n}\n.setting-image_sm{\nwidth: 30px;\n}\n.profile-footer{\n    display: flex;\n    gap: 24px;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 40px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
