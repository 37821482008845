import { useState } from 'react';

const useDropdown = (initValue, initOptions) => {
  const [value, setValue] = useState(initValue);
  const [options, setOptions] = useState(initOptions || []);

  // const removeOption = (option) = {

  // }

  const addOption = (option) => {
    setOptions((prev) => ([...prev, option]));
  };

  return {
    value, options, setValue, setOptions, addOption,
  };
};

export default useDropdown;
