import React from 'react';
import { cn } from '../../lib/utils';
import { Button } from '../button';
import { XIcon } from 'lucide-react';
import { Column } from '@tanstack/react-table';

type DataTableDropdownSelectedFiltersProps<TData, TValue> = {
  column: Column<TData, TValue>;
  title: string;
};

function DataTableDropdownSelectedFilters<TData, TValue>({
  column,
  title,
}: DataTableDropdownSelectedFiltersProps<TData, TValue>) {
  const selected = (column.getFilterValue() as string[]) ?? [];

  return (
    <div className={cn('flex flex-col gap-0.5')}>
      {selected.length > 0 && <p className="text-sm font-semibold">{title}</p>}
      <div className="flex flex-row flex-wrap gap-2">
        {selected && selected.map((s) => (
          <Button variant="outline" className="h-8 cursor-default gap-2" key={s}>
            {s}
            <XIcon
              className="h-4 w-4 cursor-pointer rounded-sm text-primary hover:bg-primary/10"
              onClick={() => column.setFilterValue(selected.filter((sel) => sel !== s))}
            />
          </Button>
        ))}
      </div>
    </div>
  );
}

export default DataTableDropdownSelectedFilters;
