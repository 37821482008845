import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { NavLink } from 'react-router-dom';
import { DecisionRecord } from '../Records/DecisionRecords/DecisionRecordType';

const LeaderReportingColumns: ColumnDef<DecisionRecord>[] = [
  {
    header: 'Test Name',
    cell: (cellInfo) => (
      <NavLink to={`/decisionrecords/${cellInfo.row.original.RecordNumber}`} className="font-bold">{cellInfo.row.original.TestName}</NavLink>
    ),
  },
  {
    header: 'Application',
    cell: (cellInfo) => (
      <NavLink to={`/applications/${cellInfo.row.original.Application.SysId}`} className="font-bold">{cellInfo.row.original.Application.Name}</NavLink>
    ),
  },
  {
    header: 'Last Test',
    cell: (cellInfo) => {
      if (!cellInfo.row.original.LastTest) return <div>Never Run</div>

      return new Intl.DateTimeFormat('en-US', {
        dateStyle: 'long',
        timeZone: 'America/New_York',
      }).format(new Date(cellInfo.row.original.LastTest.Date || new Date()))
    },
  },
  {
    header: 'Status',
    cell: (cellInfo) => {
      if (!cellInfo.row.original.LastTest) return <div className="rounded-md bg-muted text-foreground font-bold text-center w-20 px-2 py-1">Not Run</div>
      const status = cellInfo.row.original.LastTest.Status.charAt(0).toUpperCase() + cellInfo.row.original.LastTest.Status.slice(1)
      return <div className={`rounded-md ${status === 'Pass' ? 'bg-[#177a41]' : 'bg-[#94281c]'} text-muted font-bold text-center w-20 px-2 py-1`}>{status}</div>
    },
  },
  {
    header: 'Description',
    accessorKey: 'TestDescription',
  },
]

export default LeaderReportingColumns;
