import axios from 'axios';
import { Client } from '@one-thd/retooling-react';
// import initApp from '../../App.js';
let reinUserToken='';
let tokenExp=0

function isExpired(){

  return (tokenExp <= Math.floor(Date.now() / 1000) - 5)
}
export function modifyQueryParams(queryParams){
  if (history.pushState) {
    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?'+queryParams
    window.history.pushState({path:newurl},'',newurl);
  }
}
export function formatDate(s){
  var date =s.getFullYear() +"-" +("0"+(s.getMonth()+1)).slice(-2) +"-"+("0"+s.getDate()).slice(-2) +"T" +("0" + s.getHours()).slice(-2) + ":" + ("0" + s.getMinutes()).slice(-2)+":00";
  return date
}
function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    reinUserToken=token
    tokenExp=JSON.parse(jsonPayload).exp
    // return JSON.parse(jsonPayload);
};
export function deformatHTML(html){
  var htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
  if(html && typeof(html)=="string"){
    return html.replace(htmlRegexG, ''); 

  }
  return html
}
let count=0 
export function getUserGoogleToken() {
  // if (window.googleUser==null || window.googleUser.getAuthResponse(true) == null) {
  //   document.location.reload();
  // }
  console.log("in get google token");
  let id_token=null;
  let expires_at=1
  if (window && window.googleUser && window.googleUser.getAuthResponse(true)) {
     id_token =window.googleUser.getAuthResponse(true).id_token
     expires_at=window.googleUser.getAuthResponse(true).expires_at
     window["expTimestamp"]=window.googleUser.getAuthResponse(true).expires_at
  }

  const secondsSinceEpoch = Math.round(Date.now());
  let expired = false;
  if (secondsSinceEpoch >= expires_at) {
    expired = true;
  }
  console.log("is expired??", expired);
  if ((expired || id_token == null) && count < 3) {
    console.log("running init");
    initApp().then((resp) => {
      console.log("inited");
      count++;
      id_token = window.googleUser.getAuthResponse(true).id_token;
      // window["expTimestamp"]=window.googleUser.getAuthResponse(true).expires_at
      return id_token;
    }).catch((error) => {
      console.log("catching err", error);
      return(error)
    })
    } else {
        // window["expTimestamp"]=window.googleUser.getAuthResponse(true).expires_at
        return id_token;
    }
}

export async function getTokenFromReinVIAAPI(){
    let url=window.REACT_APP_RECKER_URL+'/login'
    let body={}
    const config = {};
    body.idToken=getUserGoogleToken()
    config.url = url;
    // config.params = queryParams;
    config.data = body;
    config.method = "post";
    return new Promise((resolve, reject) => {
      axios(config)
        .then((response) => {
          parseJwt(response.data.access_token)
          resolve(response);
        }).catch((error) => {
          console.log("error");
          resolve("Error getting REin token");
          console.log(error);
        });
      })
}
export async function getTokenFromReinVIAAPIWithGroups(){
    let url=window.REACT_APP_RECKER_URL+'/login?withGroups=true'
    let body={}
    const config = {};
    body.idToken=getUserGoogleToken()
    config.url = url;
    // config.params = queryParams;
    config.data = body;
    config.method = "post";
    return new Promise((resolve, reject) => {
      axios(config)
        .then((response) => {
          parseJwt(response.data.access_token)
          resolve(response);
        }).catch((error) => {
          console.log("error");
          resolve("Error getting REin token");
          console.log(error);
        });
      })
}
export async function doFileUploadRequest(resourceEndpoint, method, body, queryParams, urlParams = null) {
 let reinToken=reinUserToken
  if (reinUserToken=="" || isExpired()==true) {
    reinToken= await getTokenFromReinVIAAPI()
    if (reinToken.data && reinToken.data.access_token) {
      reinToken=reinToken.data.access_token
    }else{
      reinToken="12345"
    }
   }
  reinToken="Bearer "+reinToken

  // let headers={}
// let  headers= {
//   'Content-Type': 'multipart/form-data',
//   'Authorization': reinToken
//   }

  // config.url = url;
  let url=window.REACT_APP_RECKER_URL+resourceEndpoint
  const config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': reinToken
          },
          data: body,
          // body: body,
          method: method,
          url:url
          };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((response) => {
        resolve(response);
      }).catch((error) => {
        resolve(error.response);
        console.log(error);
      });
    })
}

export async function doPostRequest(resourceEndpoint, method, body, queryParams, urlParams = null) {
  return new Promise((resolve, reject) => {
    Client.request({
      url: resourceEndpoint,
      params: queryParams,
      data: body,
      method,
    }).then((response) => resolve(response)).catch((err) => resolve(err.response))
  })
}

export async function doRequest(resourceEndpoint, method, body, queryParams, cancelToken = null) {
  return new Promise((resolve, reject) => {
    Client.request({
      url: resourceEndpoint,
      params: queryParams,
      data: body,
      method,
      cancelToken: cancelToken?.token,
    }).then((response) => resolve(response)).catch((err) => reject(err))
  })
}

export async function doFileRequest(resourceEndpoint, method, body, queryParams, cancelToken = null) {
  return new Promise((resolve, reject) => {
    Client({
      url: resourceEndpoint,
      params: queryParams,
      data: body,
      responseType: 'arraybuffer',
      method,
      cancelToken: cancelToken?.token,
    }).then((response) => resolve(response)).catch((err) => reject(err))
  })
}

export function checkDateValidity(start, end){
  if (start<end) {
    return true

  }
  return false
}
export function isObjectEmpty(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) { return false; }
  }
  return true;
}

export function getServiceNowHost() {
  return window.location.host.includes("hdperfeng") ? "https://homedepot.service-now.com" : "https://homedepotdev.service-now.com"
}

const chgRegex = /^CHG[0-9]{7}$/
const incRegex = /^INC[0-9]{8}$/
const adrRegex = /^ADR[0-9]{7}$/

export function getServiceNowURLFor(recordNum) {
    return `${getServiceNowHost()}/nav_to.do?uri=${chgRegex.test(recordNum) ? "change_request.do" : incRegex.test(recordNum) || adrRegex.test(recordNum) ? "incident.do" : ""}?sysparm_query=number=${recordNum}`
}

export function getReinURLFor(recordNum) {
  return `${window.location.protocol}//${window.location.host}/${chgRegex.test(recordNum) ? "change" : incRegex.test(recordNum) ? "incident" : "incident"}?id=${recordNum}`
  // return `${window.NODE_ENV == "production" ? "https://rein.hdperfeng.gcp.homedepot.com" : "https://rein.hd-engineering.gcp.homedepot.com"}/${chgRegex.test(recordNum) ? "change" : incRegex.test(recordNum) ? "incident" : "incident"}?id=${recordNum}`
}
