import React, { useCallback, useState } from 'react';
import { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import MultistepForm, { Step } from '../../Form/multistep';
import PostTestingStep from '../FormSteps/PostTestingStep';
import { doRequest } from '../../Utils/utils';
import DecisionRecordStep from '../FormSteps/DecisionRecordStep';
import AttachmentStep from '../FormSteps/AttachmentStep';
import useAttachment from '../../Common/Hooks/useAttachment';
import { DecisionRecord } from '../DecisionRecords/DecisionRecordType';
import useNotification from '../../Common/Hooks/useNotification';

export default function NewTestRecord() {
  const navigate = useNavigate()
  const notifications = useNotification();

  const [attachments, setAttachments] = useState<File[]>([])
  const [decisionRecord, setDecisionRecord] = useState<DecisionRecord | null>(null)

  const { upload: uploadAttachment } = useAttachment({ POST: '/api/v1/attachments/:recordNum' })

  const steps: Step[] = [
    {
      label: 'Decision Record',
      title: 'Decision Record Information',
      component: useCallback((form: UseFormReturn) => <DecisionRecordStep form={form} decisionRecord={decisionRecord} onDecisionRecordChange={setDecisionRecord} />, [decisionRecord]),
    },
    {
      label: 'Testing',
      title: 'Testing Information',
      component: useCallback((form: UseFormReturn) => <PostTestingStep form={form} />, []),
    },
    {
      label: 'Attachments',
      title: 'Add Attachments',
      component: useCallback(() => <AttachmentStep attachments={attachments} onAttachmentsChange={setAttachments} />, [attachments]),
    },
    {
      label: 'Review',
      title: 'Review',
      component: useCallback((form: UseFormReturn) => (
        <div>
          {steps.map((step, i) => (
            <div>
              {i !== steps.length - 1
                ? (
                  <div>
                    <h3 className="text-lg mb-2">{step.title}</h3>
                    {step.component(form)}
                    {i !== steps.length - 2 ? <hr className="mt-6 mb-6" /> : null }
                  </div>
                )
                : null}
            </div>
          ))}
        </div>
      ), [decisionRecord]),
    },
  ]

  const submitAttachments = async (recordNumber: string): Promise<void> => {
    const promises: Promise<void>[] = []

    attachments.forEach((attachment) => {
      promises.push(uploadAttachment({ recordNum: recordNumber }, attachment))
    })

    return Promise.all(promises)
      .then(() => Promise.resolve()).catch(() => {
        // handle error
      })
  }

  const onSubmit: SubmitHandler<FieldValues> = (values) => {
    doRequest('/api/v1/testrunrecords', 'post', values, null)
      .then((data) => {
        if (data && data.status === 201 && data.data) {
          submitAttachments(data.data.record.RecordNumber).then(() => {
            navigate(`/testrecords/${data.data.record.RecordNumber}`, {
              state: {
                recordProp: data.data.record,
              },
            })
          })
        }
      })
      .catch((err) => {
        // handle error
        if (err.response.status === 403) {
          notifications.addToast({ type: 'error', message: 'You do not have permission to create a test record for this decision record.' })
        }
      })
  };

  return (
    <MultistepForm title="Create New Decision Record" steps={steps} onSubmit={onSubmit} />
  )
}
