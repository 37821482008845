import { NavLink, Link, useLocation, NavLinkProps } from "react-router-dom";
import React, { useEffect, useState } from "react";


import HeaderNavigation from "./Navigation";
import UserAvatar from "../User/UserAvatar.jsx";
import Search from "../../Search/Search.jsx";
import './header.css'
import ThemeSwitcher from "../../Utils/ThemeSwitcher";
import { Button } from "../../ui/button";
import { CommandIcon } from "lucide-react";


const Header = ({ userSettings }: {userSettings: any}) => {
  const [showErrModal, setShowErrModal] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);

  const ErrorModal = () => (
    <div className="modal-bg">
      <div className="modal-body">
        <div style={{ fontWeight: "bold", fontSize: "20px" }}> Error</div>
        <img
          style={{ width: "100%", marginBottom: "35px", height: "8px" }}
          src={require("../../../img/headerbar.png")}
        />
        Not a valid change or incident number.
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "65px",
          }}
        >
          <div
            className="button primary sm"
            onClick={() => {
              setShowErrModal(false);
            }}
          >
            Close{" "}
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className="header-wrapper">
      <div className="header-logo">
        <NavLink to="/home">
          <img
            src={require("../../../img/reliability_engineering_logo_small-new.png")}
          />
        </NavLink>
      </div>
      <HeaderNavigation />
      <Button
        variant="outline"
        className="relative inline-flex items-center justify-between gap-4 whitespace-nowrap rounded-[0.5rem] border border-input bg-background px-4 py-2 text-sm font-normal text-muted-foreground shadow-none transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
        onClick={() => setShowSearchModal(true)}
      >
        <i className="icon_search" />
        <span className="inline-flex">Search...</span>
        <kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
          <CommandIcon className="h-2.5 w-2.5" />
          <p className="text-xs">K</p>
        </kbd>
      </Button>

      <ThemeSwitcher />

      <UserAvatar userData={userSettings} />
      {showErrModal ? <ErrorModal /> : null}
      {showSearchModal && (
        <Search closeSearch={(e: any) => setShowSearchModal(false)} />
      )}
    </div>
  );
};

export default Header;