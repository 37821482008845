// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-items-card-container {
    width: auto;
    background-color: rgb(230, 230, 230);
    padding: 5px;
    margin: 5px;
    /* border: 1px solid lightgrey; */
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.action-items-card-container-header {
    font-weight: bold;
    font-size: 18px;
    padding-block: 10px;
    background-color: rgb(230, 230, 230);
    border-radius: 5px;
    margin: 5px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/recker/ActionItems/containers/CardContainer/actionItemsCardContainer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,oCAAoC;IACpC,YAAY;IACZ,WAAW;IACX,iCAAiC;IACjC,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,mBAAmB;IACnB,oCAAoC;IACpC,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC","sourcesContent":[".action-items-card-container {\n    width: auto;\n    background-color: rgb(230, 230, 230);\n    padding: 5px;\n    margin: 5px;\n    /* border: 1px solid lightgrey; */\n    border-radius: 5px;\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n}\n\n.action-items-card-container-header {\n    font-weight: bold;\n    font-size: 18px;\n    padding-block: 10px;\n    background-color: rgb(230, 230, 230);\n    border-radius: 5px;\n    margin: 5px;\n    padding: 10px;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
