import React, { useEffect, useState } from 'react';
import { NavLink, useLoaderData, useLocation, useParams } from 'react-router-dom';
import Loading from 'react-loading';
import { FieldValues, useForm } from 'react-hook-form';
import Card from '../../ui/card';
import { doRequest } from '../../Utils/utils';
import { Button } from '../../ui/button';
import { Input } from '../../ui/input';
import Form, { FormEntry } from '../../ui/form';
import { Textarea } from '../../ui/textarea';
import { TestRunRecord } from './TestRunRecordType';
import SidebarSectionRow from '../../Layout/Sidebar/SidebarSectionRow';
import SidebarSection from '../../Layout/Sidebar/SidebarSection';
import { useProfileContext } from '../../Utils/user';
import Sidebar from '../../Layout/Sidebar/Sidebar';
import UserSelector from '../../Layout/Sidebar/UserSelector';
import AttachmentsView from '../../Layout/AttachmentsView/AttachmentsView';
import NotFound from '../../SharedComponents/NotFound';

const formEntries: FormEntry[] = [
  {
    Name: 'Status',
    Label: 'Status',
    InputType: Input,
    AccessorKey: 'Status',
  },
  {
    Name: 'ActualResults',
    Label: 'Actual Results',
    InputType: Textarea,
    AccessorKey: 'ActualResults',
  },
  {
    Name: 'ActionItems',
    Label: 'Action Items',
    InputType: Textarea,
    AccessorKey: 'ActionItems',
  },

]

function TestRunRecordView() {
  const { trrId } = useParams();
  const profile = useProfileContext()

  const [canEdit, setCanEdit] = useState(false);
  const [isOwner, setIsOwner] = useState(false);

  const [editing, setEditing] = useState(false);

  const { recordProp } = useLocation()?.state as { recordProp: TestRunRecord } || { recordProp: null }

  const loaderData = useLoaderData() as any

  const [record, setRecord] = useState<TestRunRecord>()
  const [trrLoading, setTrrLoading] = useState(true)
  const [preEditState, setPreEditState] = useState<FieldValues>()

  const form = useForm()

  const closeEdit = () => {
    form.reset(preEditState)
    setEditing(false)
  }

  const getTestRecord = () => {
    doRequest(`/api/v1/testrunrecords/${trrId}`, 'get')
      .then(({ data }) => {
        setRecord(data.record)
        setTrrLoading(false)
      }).catch((e) => {
        console.error(e)
        setTrrLoading(false)
      })
  }

  const saveEdit = () => {
    if (!record) return
    const body = form.getValues();

    body.RecordNumber = record.RecordNumber;
    body.DecisionRecordNumber = record.DecisionRecord.RecordNumber;
    body.Date = record.Date;

    doRequest(`/api/v1/testrunrecords/${record.RecordNumber}`, 'patch', body)
      .then(({ status }) => {
        if (status === 200) {
          getTestRecord();
          setEditing(false);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    if (record != null) return

    if (loaderData && loaderData.status === 200) {
      setRecord(loaderData.data.record)
      setTrrLoading(false)
      return
    }

    getTestRecord()
  }, [])

  useEffect(() => {
    if (record) {
      const values: any = {}
      formEntries.forEach((e) => {
        values[e.Name] = record[e.AccessorKey]
      })
      setPreEditState(values)
      form.reset(values)
    }
  }, [record])

  // Check role for current user to see if they can edit
  useEffect(() => {
    if (record != null && profile != null) {
      if (record.CreatedBy === profile.Email) {
        setIsOwner(true);
        return;
      }

      record.DecisionRecord.Editors.forEach((e) => {
        if (e.Email === profile.Email) {
          setCanEdit(true);
          return;
        }

        if (e.LDAP.toUpperCase() === profile.LDAP.toUpperCase()) {
          setCanEdit(true);
        }
      });

      record.DecisionRecord.Testers.forEach((e) => {
        if (e.Email === profile.Email) {
          setCanEdit(true);
          return;
        }

        if (e.LDAP.toUpperCase() === profile.LDAP.toUpperCase()) {
          setCanEdit(true);
        }
      });
    }
  }, [record]);

  return (
    <div className="max-w-screen-2xl m-auto">
      { record != null && !trrLoading
        ? (
          <div className="flex justify-center max-w-8xl items-start gap-4 flex-wrap">
            <div className="flex-1 max-w-screen-xl flex gap-4 flex-col">
              <Card className="w-full">
                <Card.Header className="flex justify-between flex-row items-start gap-4">
                  <div className="flex-1">
                    <Card.Title>
                      {record.DecisionRecord.TestName}
                    </Card.Title>
                    {/* <Card.Description>{record.LastExecutionDate.toLocaleString()}</Card.Description> */}
                  </div>
                  <div className="flex gap-4 m-0">
                    {editing ? (
                      <Button className="hover:bg-red-600" onClick={() => closeEdit()}>
                        <i className="icon_close" />
                      </Button>
                    ) : null}
                    {!editing ? (
                      <Button disabled={!canEdit && !isOwner} onClick={() => setEditing(!editing)}>
                        <i className="icon_pencil text-lg mr-2" />
                        Edit
                      </Button>
                    ) : (
                      <Button className="hover:bg-green-600" onClick={() => saveEdit()}>
                        <i className="icon_check text-lg mr-2" />
                        Done
                      </Button>
                    )}
                  </div>
                </Card.Header>
                <Card.Content>
                  <div className="flex flex-col gap-4">
                    <div className="flex">
                      <div className="font-bold pt-2 w-1/3">Test Thesis</div>
                      <div className="p-2 bg-background rounded-sm border w-2/3 cursor-not-allowed">{record.DecisionRecord.Thesis}</div>
                    </div>
                    <div className="flex">
                      <div className="font-bold pt-2 w-1/3">Test Description</div>
                      <div className="p-2 bg-background rounded-sm border w-2/3 cursor-not-allowed">{record.DecisionRecord.TestDescription}</div>
                    </div>
                    <div className="flex">
                      <div className="font-bold pt-2 w-1/3">Estimated Duration</div>
                      <div className="p-2 bg-background rounded-sm border w-2/3 cursor-not-allowed">{record.DecisionRecord.EstimatedDuration || ' '}</div>
                    </div>
                    <div className="flex">
                      <div className="font-bold pt-2 w-1/3">Impacted Services</div>
                      <div className="p-2 bg-background rounded-sm border w-2/3 cursor-not-allowed">{record.DecisionRecord.ImpactedServices}</div>
                    </div>
                    <div className="flex">
                      <div className="font-bold pt-2 w-1/3">Identified Risks & Mitigation</div>
                      <div className="p-2 bg-background rounded-sm border w-2/3">{record.DecisionRecord.IdentifiedRisksAndMitigation}</div>
                    </div>
                  </div>
                  <hr className="my-4" />
                  <Form {...form}>
                    {formEntries.map((e) => (
                      <Form.Field
                        control={form.control}
                        name={e.Name}
                        disabled={!editing}
                        render={({ field }) => (
                          <>
                            <Form.Item className="flex justify-between align-baseline">
                              <Form.Label className="w-1/3 mt-4 font-bold">{e.Label}</Form.Label>
                              <Form.Control>
                                <e.InputType
                                  {...field}
                                  className="w-2/3"
                                />
                              </Form.Control>
                            </Form.Item>
                            <Form.Message />
                          </>
                        )}
                      />
                    ))}
                  </Form>
                  <hr className="mt-4" />
                  <AttachmentsView recordNumber={record.RecordNumber} canEdit />
                </Card.Content>
              </Card>
            </div>
            <Sidebar className="">
              <SidebarSection open title="Test Information">
                <SidebarSectionRow header="Record Number">
                  {record.RecordNumber}
                </SidebarSectionRow>
                <SidebarSectionRow header="Test Record Creator">
                  {record.CreatedBy}
                </SidebarSectionRow>
                <SidebarSectionRow header="Decision Record">
                  <NavLink to={`/decisionrecords/${record.DecisionRecord.RecordNumber}`}>
                    {record.DecisionRecord.RecordNumber}
                  </NavLink>
                </SidebarSectionRow>
                <SidebarSectionRow header="Executed Date">
                  {new Intl.DateTimeFormat('en-US', {
                    dateStyle: 'long',
                    timeZone: 'America/New_York',
                  }).format(new Date(record.DecisionRecord.LastExecutionDate))}
                </SidebarSectionRow>
                <SidebarSectionRow header="Record Created">
                  {new Intl.DateTimeFormat('en-US', {
                    dateStyle: 'long',
                    timeStyle: 'long',
                    timeZone: 'America/New_York',
                  }).format(new Date(record.CreatedAt))}
                </SidebarSectionRow>
                <SidebarSectionRow header="Last Updated">
                  {new Intl.DateTimeFormat('en-US', {
                    dateStyle: 'long',
                    timeStyle: 'long',
                    timeZone: 'America/New_York',
                  }).format(new Date(record.UpdatedAt))}
                </SidebarSectionRow>
              </SidebarSection>
              <SidebarSection open title="Application">
                <SidebarSectionRow header="Name">
                  <NavLink to={`/applications/${record.DecisionRecord.Application.SysId}`}>
                    {record.DecisionRecord.Application.Name}
                  </NavLink>
                </SidebarSectionRow>
                <SidebarSectionRow header="PSRB ID">
                  {record.DecisionRecord.Application.ParentBusinessApp.PSRBSolutionId}
                </SidebarSectionRow>
                <SidebarSectionRow header="Environment">
                  {record.DecisionRecord.Application.ParentBusinessApp.LifecyclePhase}
                </SidebarSectionRow>
                <SidebarSectionRow header="Tier">
                  {record.DecisionRecord.Application.SLATier}
                </SidebarSectionRow>
                <SidebarSectionRow header="Subject Matter Expert">
                  {record.DecisionRecord.Application.ParentBusinessApp.SubjectMatterExpert.Name}
                </SidebarSectionRow>
                <SidebarSectionRow header="Manager">
                  {record.DecisionRecord.Application.ParentBusinessApp.Manager.Name}
                </SidebarSectionRow>
                <SidebarSectionRow header="Director">
                  {record.DecisionRecord.Application.ParentBusinessApp.Director.Name}
                </SidebarSectionRow>
                <SidebarSectionRow header="VP">
                  {record.DecisionRecord.Application.ParentBusinessApp.VP.Name}
                </SidebarSectionRow>
              </SidebarSection>
              <SidebarSection open title="Roles">
                <SidebarSectionRow header="Testers">
                  <UserSelector
                    showManagement={false}
                    users={record.DecisionRecord.Testers || []}
                    addUserCallback={() => {}}
                    removeUserCallback={() => {}}
                  />
                </SidebarSectionRow>
              </SidebarSection>
            </Sidebar>
          </div>
        )
        : null}
      {trrLoading && <Loading />}
      {record == null && !trrLoading && <NotFound />}
      <div />
    </div>
  )
}

export default TestRunRecordView;
