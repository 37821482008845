import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';

import { DecisionRecord } from './DecisionRecordType';
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../ui/table';
import { Button } from '../../ui/button';
import { NavLink } from 'react-router-dom';
import { TestRunRecord } from '../TestRunRecords/TestRunRecordType';
import dayjs from 'dayjs';
import { Badge, badgeVariantOptions } from '../../ui/badge';
import _ from 'lodash';
import { Input } from '../../ui/input';
import DataTablePagination from '../../ui/dataTable/datatablePagination';
import DataTableColumnHeader from '../../ui/dataTable/dataTableColumnHeader';
import DataTableColumnVisibilityList from '../../ui/dataTable/dataTableColumnVisibilityList';
import DecisionRecordsDataTableFilters from './components/DecisionRecordsDataTableFilters';

const Name = ({ name }: { name: string }) => <p className="capitalize">{name.toLocaleLowerCase()}</p>;

type Props = {
  data: DecisionRecord[];
  hiddenColumns?: string[];
};

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

// keep accessor key for any columns that should use searching/filtering
const cols: ColumnDef<DecisionRecord>[] = [
  {
    id: 'Number',
    accessorKey: 'RecordNumber',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Number" />,
    enableSorting: true,
    sortingFn: 'alphanumeric',
    cell: (cell) => {
      const value = cell.row.original.RecordNumber;
      return (
        <Button variant="link" size="sm" className="p-0">
          <NavLink to={`/decisionrecords/${value}`} target="_blank">
            {value}
          </NavLink>
        </Button>
      );
    },
  },
  {
    id: 'Test Name',
    accessorKey: 'TestName', // replaced by actual test name field
    header: ({ column }) => <DataTableColumnHeader column={column} title="Test Name" searchable />,
    cell: (cell) => {
      const value = cell.row.original.TestName;
      return (
        <Button variant="link" size="sm" className="p-0">
          <NavLink to={`/decisionrecords/${cell.row.original.RecordNumber}`} target="_blank">
            {value}
          </NavLink>
        </Button>
      );
    },
  },
  {
    id: 'Application',
    accessorKey: 'Application.Name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Application" searchable />,
    cell: (cell) => {
      const value = cell.row.original.Application.Name;
      return (
        <Button variant="link" size="sm" className="p-0">
          <NavLink to={`/applications/${cell.row.original.Application.SysId}`} className="text-xs">
            {value}
          </NavLink>
        </Button>
      );
    },
  },
  {
    id: 'SLATier',
    accessorKey: 'Application.SLATier',
    header: ({ column }) => <DataTableColumnHeader column={column} title="SLA Tier" />,
    cell: (cell) => cell.row.original.Application.SLATier,
    filterFn: 'arrIncludesSome',
  },
  {
    id: 'Manager',
    accessorFn: (row) => row.Application.ParentBusinessApp.Manager.Name,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Manager" />,
    cell: (cell) => {
      const value = cell.row.original.Application.ParentBusinessApp.Manager.Name;
      return value ? <Name name={value} /> : 'N/A';
    },
    filterFn: 'arrIncludesSome',
  },
  {
    id: 'SrManager',
    accessorFn: (row) => row.Application.ParentBusinessApp.SeniorManager.Name,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Sr Manager" />,
    cell: (cell) => {
      const value = cell.row.original.Application.ParentBusinessApp.SeniorManager.Name;
      return value ? <Name name={value} /> : 'N/A';
    },
    filterFn: 'arrIncludesSome',
  },
  {
    id: 'Director',
    accessorFn: (row) => row.Application.ParentBusinessApp.Director.Name,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Director" />,
    cell: (cell) => {
      const value = cell.row.original.Application.ParentBusinessApp.Director.Name;
      return value ? <Name name={value} /> : 'N/A';
    },
    filterFn: 'arrIncludesSome',
  },
  {
    id: 'VP',
    accessorFn: (row) => row.Application.ParentBusinessApp.VP.Name,
    header: ({ column }) => <DataTableColumnHeader column={column} title="VP" />,
    cell: (cell) => {
      const value = cell.row.original.Application.ParentBusinessApp.VP.Name;
      return value ? <Name name={value} /> : 'N/A';
    },
    filterFn: 'arrIncludesSome',
  },
  {
    id: 'Environment',
    accessorFn: (row) => row.Application.ParentBusinessApp.LifecyclePhase,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Environment" searchable />,
    cell: (cell) => {
      const value = cell.row.original.Application.ParentBusinessApp.LifecyclePhase;
      let variant: keyof typeof badgeVariantOptions;
      if (!value) return 'N/A';
      switch (value.toLocaleLowerCase()) {
        case 'production':
          variant = 'destructive';
          break;
        case 'staging':
          variant = 'constructive';
          break;
        case 'development':
          variant = 'default';
          break;
        default:
          variant = 'default';
      }
      return <Badge variant={variant}>{value}</Badge>;
    },
  },
  {
    id: 'Description',
    header: 'Description',
    accessorKey: 'TestDescription',
  },
  {
    id: 'Last Execution Date',
    accessorKey: 'LastExecutionDate',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Last Execution Date" />,
    cell: (cell) => {
      const value = cell.row.original.LastExecutionDate;

      if (!value) {
        return 'N/A';
      }

      return value.toString() === '0001-01-01T00:00:00Z' ? (
        'Never Run'
      ) : (
        <Button variant="link" size="sm" className="p-0">
          <NavLink to={`/testrecords/${cell.row.original.LastTest?.RecordNumber}`} target="_blank" className="text-xs">
            {new Intl.DateTimeFormat('en-US', {
              dateStyle: 'long',
              timeZone: 'America/New_York',
            }).format(new Date(value))}
          </NavLink>
        </Button>
      );
    },
  },
  {
    id: 'TestTool',
    header: 'Test Tool',
    accessorKey: 'TestTool',
    filterFn: 'arrIncludesSome',
  },
];

function DecisionRecordsDataTable({ data, hiddenColumns }: Props) {
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: 'Number',
      desc: true,
    },
  ]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  const table = useReactTable({
    data,
    columns: cols,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
    },
  });

  useEffect(() => {
    const visibleColumns: VisibilityState = {};
    hiddenColumns?.forEach((column) => {
      visibleColumns[column] = false;
    });
    setColumnVisibility(visibleColumns);
  }, []);

  const getCSVData = () => {
    const { rows } = table.getFilteredRowModel();

    if (rows.length === 0) {
      return [[
        'Manager',
        'VP',
        'Director',
        'SeniorManager',
        'SubjectMatterExpert',
        'Env',
        'PSRBId',
        'ApplicationName',
        'SLATier',
        'DecisionRecord',
        'LastExecutionDate',
        'TestsRun',
        'TestsPassed',
        'TestsFailed',
        'TestTool'
      ]];
    }

    return rows.map((row) => {
      const r = row.original;
      return {
        Manager: r.Application.ParentBusinessApp.Manager.Name,
        VP: r.Application.ParentBusinessApp.VP.Name,
        Director: r.Application.ParentBusinessApp.Director.Name,
        SeniorManager: r.Application.ParentBusinessApp.SeniorManager.Name,
        SubjectMatterExpert: r.Application.ParentBusinessApp.SubjectMatterExpert.Name,
        Env: r.Application.ParentBusinessApp.LifecyclePhase,
        PSRBId: r.Application.ParentBusinessApp.PSRBSolutionId,
        ApplicationName: r.Application.ParentBusinessApp.Name,
        SLATier: r.Application.SLATier,
        DecisionRecord: r.RecordNumber,
        LastExecutionDate: r.LastExecutionDate,
        TestsRun: r.Counts.TestRunCount,
        TestsPassed: r.Counts.SuccessCount,
        TestsFailed: r.Counts.FailCount,
        TestTool: r.TestTool,
      }
    });
  };

  const filterPortalRef = React.useRef<HTMLDivElement>(null);

  return (
    <div className="w-full">
      <div className="mb-4 flex flex-col gap-1">
        <div className="flex flex-row gap-2">
          <Input
            placeholder="Search all fields..."
            value={(table.getState().globalFilter as string) ?? ''}
            onChange={(event) => table.setGlobalFilter(event.target.value)}
            className="h-8 max-w-sm"
          />
          <DecisionRecordsDataTableFilters table={table} portalRef={filterPortalRef} />
          <CSVLink data={getCSVData()} filename="decision-records.csv">
            <Button className="h-8 px-4 py-2 text-sm">Export to CSV</Button>
          </CSVLink>
          <DataTableColumnVisibilityList table={table} className="ml-auto h-8" />
        </div>
        <div className="flex w-full flex-col flex-wrap gap-1" ref={filterPortalRef} />
      </div>
      <div className="rounded-md border border-border">
        <Table className="border-none">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="py-2 last:border-r-0">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={cols.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} defaultPageSize={10} pageSizes={[5, 10, 15]} showFullPagination />
    </div>
  );
}

export default DecisionRecordsDataTable;
