import React, { useEffect, useState } from 'react'
import useRequest from '../../../Common/Hooks/useRequest'
import useDropdown from '../../../Common/Hooks/useDropdown'
import { ActionItem } from '../../types'
import ReactSelect from 'react-select'
import Tooltip from '../../../Common/Tooltip/Tooltip'
import { SHORT_DROPDOWN_STYLE } from '../../../Common/Dropdown/utils'
import _ from 'lodash'

type CreateJiraProps = {
  actionItem: ActionItem
  disabled: boolean
  onChange: (project: string) => void
}


const JiraTooltip = () => (
  <div>
    <div style={{ fontWeight: 'bold', fontSize: '20px', marginBottom: '25px', textAlign: 'center' }}> Where is my project? </div>
    <p>If a project is not listed in the dropdown it means that REin does not yet have permission to create issues in the project.</p>
    <ol style={{ paddingInline: 20 }}>
      <li>The Rein Service account will need to be added to the project by a <strong>Jira Admin.</strong></li>
      <li>Please go to <strong>Project Settings</strong>, then navigate to <strong>People.</strong> Click the <strong>Add People</strong> button.</li>
      <li>Search for <strong style={{ color: 'var(--color-primary)' }}>svc_cld_eng_ci@homedepot.onmicrosoft.com </strong> and add the Jira Service Account role.</li>
    </ol>
    <p>Once this is completed, REin will have access to the project. If you still do not see the project in the dropdown, please contact the <span style={{ color: 'var(--color-primary)' }}>RE-tooling team</span> on slack at <span style={{ color: 'var(--color-primary)' }}>#REin</span>.</p>
  </div>
);

function CreateJira({ actionItem, disabled, onChange }: CreateJiraProps) {
  const jiraProjectsDropdown = useDropdown(null, [])

  const getJiraProjects = useRequest('/api/v1/jiraprojects', 'GET')

  useEffect(() => {
    if (_.isEmpty(jiraProjectsDropdown.value) || !jiraProjectsDropdown.value.value) return
    onChange(jiraProjectsDropdown.value.value.split(':')[0])
  }, [jiraProjectsDropdown.value])

  useEffect(() => {
    handleGetJiraProjects()
  }, [])

  function handleGetJiraProjects() {
    getJiraProjects.sendRequest().then((response: any) => {
      if (response.successful) {
        jiraProjectsDropdown.setOptions(
          response.data.projects.map((jiraProject: string) => ({
            label: jiraProject,
            value: jiraProject,
          }))
        )
        if (actionItem.Jira.Project) {
          const project: string = response.data.projects.find((project: string) => project.includes(actionItem.Jira.Project))
          jiraProjectsDropdown.setValue({ label: project, value: project })
        }
      }
    })
  }

  const tooltipStyle = {
    boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.15)',
    backgroundColor: 'white',
    color: 'initial',
    border: '1px solid var(--color-border-input)',
    borderRadius: 5,
    opacity: 1,
    marginTop: 5
  }

  return (
    <>
      {!disabled &&  actionItem.Status!="Rejected" && (!actionItem.Jira.Id || actionItem.Jira.Deleted)
        ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ReactSelect
              options={jiraProjectsDropdown.options}
              onChange={jiraProjectsDropdown.setValue}
              value={jiraProjectsDropdown.value}
              placeholder='Select a Jira project'
              isClearable
              styles={SHORT_DROPDOWN_STYLE}
            />
            <div style={{ width: 'fit-content' }}>
              <Tooltip
                tip={<JiraTooltip />}
                style={tooltipStyle}
                timeoutSeconds={300}
              >
                <div className='action-item-project_info' style={{ width: 'auto' }}>Where is my Jira project?</div>
              </Tooltip>
            </div>
          </div>
        )
        : (
          <div style={{ width: 'fit-content' }}>
            {actionItem.Jira.Id
              ? !actionItem.Jira.Deleted
                ? <a
                    target="_blank"
                    style={{ display: 'flex', alignItems: 'center', color: 'var(--color-primary)', textDecoration: 'underline', }}
                    href={`https://thd.atlassian.net/browse/${actionItem.Jira.Id}`}
                  >
                    {actionItem.Jira.Id}
                  </a>
                :
                  <Tooltip tip={"This Jira issue has been deleted"} style={{ width: 200, textAlign: 'center' }} timeoutSeconds={0}>
                    <p style={{ textDecoration: 'line-through' }}>{actionItem.Jira.Id}</p>
                  </Tooltip>
              : <em style={{ display: 'flex', alignItems: 'center' }}>No Jira issue created.</em>
            }
          </div>
        )
      }
    </>
  )
}

export default CreateJira