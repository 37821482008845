import React from 'react'

function NotFound() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: 50, gap: '0.5em', position: 'absolute', top: 'calc(50% - 250px)', left: 'calc(50% - 200px)' }}>
      <i style={{ fontSize: '2.5em' }} className='icon_very_dissatisfied' />
      <h1>404</h1>
      <h4 style={{ margin: 0, color: 'grey' }}>Page Not Found</h4>
    </div>
  )
}

export default NotFound