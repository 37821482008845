import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import Form, { FormEntry } from '../../ui/form';
import Select from '../../ui/select';
import { Input } from '../../ui/input';
import { Textarea } from '../../ui/textarea';
import { Button } from '../../ui/button';
import { CalendarIcon } from 'lucide-react';
import { Calendar } from '../../ui/calendar';
import Popover from '../../ui/popover';
import { cn } from '../../lib/utils';
import { format } from 'date-fns';
import Tooltip from '../../Common/Tooltip/Tooltip';

type PostTestingStepProps = {
  form: UseFormReturn;
}

export default function PostTestingStep({ form }: PostTestingStepProps) {
  return (
    <div>
      <Form.Field
        control={form.control}
        name="Date"
        rules={{ required: 'Execution Date is required' }}
        render={({ field }) => (
          <Form.Item className="flex justify-between align-baseline">
            <Form.Label className="w-1/3 mt-4 flex-wrap justify-start">
              <p className="mr-2">
                Execution Date
                <span className="text-primary"> *</span>
              </p>
              <Tooltip
                tip="What date was the test performed?"
                className="font-normal text-primary h-fit"
                style={{ width: 200, marginTop: 5, fontWeight: 'normal' }}
                timeoutSeconds={5}
              >
                <i
                  className="icon_help-outlined h-fit"
                  style={{ fontSize: 18, cursor: 'help' }}
                />
              </Tooltip>
              <Form.Message className="w-full mt-1" />
            </Form.Label>
            <Form.Control>
              <Popover>
                <Popover.Trigger asChild>
                  <Button
                    variant="outline"
                    className={cn(
                      'w-[280px] justify-start text-left font-normal',
                      !field.value && 'text-muted-foreground',
                    )}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {field.value ? format(field.value, 'PPP') : <span>Pick a date</span>}
                  </Button>
                </Popover.Trigger>
                <Popover.Content className="w-auto p-0">
                  <Calendar
                    mode="single"
                    selected={field.value}
                    onSelect={field.onChange}
                    initialFocus
                  />
                </Popover.Content>
              </Popover>
            </Form.Control>
          </Form.Item>
        )}
      />
      <Form.Field
        control={form.control}
        name="Status"
        rules={{ required: 'Test Status is required' }}
        render={({ field }) => (
          <Form.Item className="flex justify-between align-baseline">
            <Form.Label className="w-1/3 mt-4 flex-wrap justify-start">
              <p className="mr-2">
                Status
                <span className="text-primary"> *</span>
              </p>
              <Tooltip
                tip="Did the test pass or fail?"
                className="font-normal text-primary h-fit"
                style={{ width: 200, marginTop: 5, fontWeight: 'normal' }}
                timeoutSeconds={5}
              >
                <i
                  className="icon_help-outlined h-fit"
                  style={{ fontSize: 18, cursor: 'help' }}
                />
              </Tooltip>
              <Form.Message className="w-full mt-1" />
            </Form.Label>
            <Form.Control>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <Select.Trigger className="w-2/3">
                  <Select.Value placeholder="Please select Test status..." />
                </Select.Trigger>
                <Select.Content>
                  <Select.Item value="pass">Pass</Select.Item>
                  <Select.Item value="fail">Fail</Select.Item>
                </Select.Content>
              </Select>
            </Form.Control>
          </Form.Item>
        )}
      />
      <Form.Field
        control={form.control}
        name="ActualResults"
        rules={{ required: 'Actual Results are required' }}
        render={({ field }) => (
          <Form.Item className="flex justify-between align-baseline">
            <Form.Label className="w-1/3 mt-4 flex-wrap justify-start">
              <p className="mr-2">
                Actual Results
                <span className="text-primary"> *</span>
              </p>
              <Tooltip
                tip="What were the results of the test?"
                className="font-normal text-primary h-fit"
                style={{ width: 200, marginTop: 5, fontWeight: 'normal' }}
                timeoutSeconds={5}
              >
                <i
                  className="icon_help-outlined h-fit"
                  style={{ fontSize: 18, cursor: 'help' }}
                />
              </Tooltip>
              <Form.Message className="w-full mt-1" />
            </Form.Label>
            <Form.Control>
              <Textarea {...field} className="w-2/3" />
            </Form.Control>
          </Form.Item>
        )}
      />
      <Form.Field
        control={form.control}
        name="ActionItems"
        render={({ field }) => (
          <>
            <Form.Item className="flex justify-between align-baseline">
              <Form.Label className="w-1/3 mt-4">Action Items</Form.Label>
              <Form.Control>
                <Textarea {...field} className="w-2/3" />
              </Form.Control>
            </Form.Item>
            <Form.Message />
          </>
        )}
      />
    </div>
  )
}
