/* global window */
import axios from 'axios';
import { Client } from '@one-thd/retooling-react';

const config = {};

async function loadConfig() {
  console.log("loading config");
  try {
    const res = await axios.get('/wyibswaowps.json');
    Object.keys(res.data).forEach((key) => {
      window[key] = window[key] || res.data[key];
      config[key] = res.data[key];
    });

    // workaround for env keys not available until after this call
    Client.interceptors.request.use(((config) => {
      return {
        ...config,
        baseURL: window.REACT_APP_RECKER_URL
      }
    }))
    
  } catch (err) {
    console.log('Could not load config');
  }
}


export default loadConfig;
