/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import './sidebar.css';
import './accordion.css';

function Sidebar({ children, className }) {
  const [sidebarStatus, setSidebarStatus] = useState(false);

  return (
    <div className={`${className} sidebar`}>
      {/* DETAILS SECTION */}
      <div
        className="sidebar-header bg-card text-primary border shadow-sm"
        onClick={(e) => setSidebarStatus(!sidebarStatus)}
      >

        {sidebarStatus ? (
          <i className="icon_caret-right text-primary"></i>
        ) : (
          <i className="icon_caret-left"></i>
        )}
      </div>
      <div className="sidebar-content">
        <div
          className={`sidebar-closed ${
            sidebarStatus ? "sidebar-icons-in" : ""
          }`}
        >
        </div>
        <div
          className={`sidebar-opened w-full ${
            sidebarStatus ? '' : 'sidebar-content-in'
          }`}
        >
          { children }
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
