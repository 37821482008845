import { useMatches } from "react-router-dom";
import React from "react";

export default function Breadcrumbs() {
  const matches = useMatches();
  const crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) => match.handle.crumb({ params: match.params, data: match.data }));

  return (
    <ol style={{
      marginBottom: '20px',
      display: 'flex',
      justifyContent: 'flex-start',
      gap: '12px',
    }}
    >
      {crumbs.map((crumb, index) => (
        <div key={`d-${matches.filter((match) => Boolean(match.handle?.crumb))[index].id}`} className="flex justify-start gap-3">
          { index !== 0 ? <div key={`gt-${crumb}`}>&gt;</div> : null }
          <li key={crumb}>{crumb}</li>
        </div>
      ))}
    </ol>
  );
}