import React, { useCallback, useContext, useEffect, useState, createContext } from 'react'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  useLocation,
  Outlet,
  createBrowserRouter,
  RouterProvider,
  Link
} from "react-router-dom";

import KPIs from "./recker/KPI/KPIs.jsx";
import CreateKPI from "./recker/KPI/CreateKPI.jsx";
import ActionItems from "./recker/ActionItems/ActionItemsNew.jsx";
import { AuthContext } from "@one-thd/retooling-react";
import TestComponents from "./recker/Test/TestComponents.jsx";
import UserProfilePage from "./recker/Pages/User-profile/UserProfile.jsx";
import useRequest from './recker/Common/Hooks/useRequest.js';
import Layout from './recker/SharedComponents/Layout.jsx';
import NotFound from './recker/SharedComponents/NotFound.jsx';
import DecisionRecordsList from './recker/Records/DecisionRecords/DecisionRecordsList';
import CreateDecisionRecord from './recker/Records/DecisionRecords/CreateDecisionRecord';
import DecisionRecordView from './recker/Records/DecisionRecords/DecisionRecord';
import ApplicationsTableView from './recker/Applications/ApplicationsTableView.tsx';
import ApplicationSingleView from './recker/Applications/ApplicationSingleView.tsx';
import { ToastProvider } from './recker/Common/Toasts/contexts/ToastContext';
import { ProfileContext } from './recker/Utils/user.js';
import Dashboard from './recker/Dashboard/Dashboard.tsx';
import NewDecisionRecord from './recker/Records/DecisionRecords/NewDecisionRecord';
import GettingStarted from './recker/Pages/GettingStarted/GettingStarted.tsx';
import NewTestRecord from './recker/Records/TestRunRecords/NewTestRecord.tsx';
import TestRunRecordView from './recker/Records/TestRunRecords/TestRunRecord.tsx';
import TestRunRecordsList from './recker/Records/TestRunRecords/TestRunRecordsList.tsx';
import { doRequest } from './recker/Utils/utils.js';
import ReportingView from './recker/Reporting/ReportingView';

function ReRoutes() {
  /* STATES */
  const [user, setUser] = useState({
    authedAsSre: false,
    authedAsAdmin: false,
    email: '',
    roles: [],
  });
  const [profile, setProfile] = useState({});

  /* CONTEXTS */
  const authContext = useContext(AuthContext);

  /* REQUESTS */
  const getUserSettings = useRequest('/api/v1/user', 'GET')

  /* EFFECTS */
  useEffect(() => {
    handleGetUserProfile();
  }, [])

  // Set the user settings
  useEffect(() => {
    const authedAsSre = authContext.currentUser.groups && authContext.currentUser.groups.includes("sre")
    const authedAsAdmin = authContext.currentUser.groups && authContext.currentUser.groups.includes("gg_cloud_gcp_hd-perf-engineering_support")
    const email = authContext.currentUser.email ? authContext.currentUser.email : ""
    const { roles } = authContext.currentUser

    setUser({
      authedAsSre,
      authedAsAdmin,
      email,
      roles,
    })
  }, [authContext])

  /* FUNCTIONS */

  function handleGetUserProfile() {
    getUserSettings.sendRequest()
      .then((resp) => {
        if (resp.successful) {
          setProfile(resp.data)
          // TODO this.getUsersActionItems()
        }
      }).catch((err) => {
        console.error(err)
      })
  }

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <ProfileContext.Provider value={profile}>
          <ToastProvider>
            <Layout userSettings={profile} atRisk={[]} />
          </ToastProvider>
        </ProfileContext.Provider>
      ),
      children: [
        {
          id: 'index',
          index: true,
          element: <Dashboard userEmail={user.email} />,
        },
        {
          id: 'any',
          path: '*',
          element: <NotFound />,
        },
        {
          id: 'home',
          path: 'home',
          element: <Dashboard userEmail={user.email} />,
        },
        {
          id: 'dr',
          path: 'decisionrecords',
          handle: {
            crumb: useCallback(() => <Link to="/decisionrecords/all">Decision Records</Link>),
          },
          children: [
            {
              id: 'dr-list',
              path: 'all',
              element: <DecisionRecordsList />,
            },
            {
              id: 'dr-create-old',
              path: 'create',
              element: <CreateDecisionRecord />,
              handle: {
                crumb: useCallback(() => <span>Create New</span>),
              },
            },
            {
              id: 'dr-new',
              path: 'new',
              element: <NewDecisionRecord />,
              handle: {
                crumb: useCallback(() => <span>New Decision Record</span>),
              },
            },
            {
              id: 'dr-single',
              name: 'Decision Record Single View',
              path: ':drId',
              element: <DecisionRecordView userEmail={user.email} />,
              loader: async ({ params }) => doRequest(`/api/v1/decisionrecords/${params.drId}`, 'GET').catch(() => null),
              handle: {
                crumb: useCallback((resp) => <span>{resp.data !== null ? resp.data.data.record.TestName : 'Not Found'}</span>),
              },
            },
          ],
        },
        {
          id: 'tests',
          path: 'testrecords',
          handle: {
            crumb: useCallback(() => <Link to="/testrecords/all">Test Run Records</Link>),
          },
          children: [
            {
              id: 'tests-list',
              path: 'all',
              element: <TestRunRecordsList />,
            },
            {
              id: 'tests-new',
              path: 'new',
              element: <NewTestRecord />,
              handle: {
                crumb: useCallback(() => <span>New Test Record</span>),
              },
            },
            {
              id: 'tests-single',
              path: ':trrId',
              element: <TestRunRecordView userEmail={user.email} />,
              loader: async ({ params }) => doRequest(`/api/v1/testrunrecords/${params.trrId}`, 'GET').catch(() => null),
              handle: {
                crumb: useCallback(({ data }) => (data !== null
                  ? (
                    <div className="flex gap-2">
                      <NavLink to={`/decisionrecords/${data.data.record.DecisionRecord.RecordNumber}`}>
                        <span>{data.data.record.DecisionRecord.TestName}</span>
                      </NavLink>
                      <span>{'>'}</span>
                      <span>{data.data.record.RecordNumber}</span>
                    </div>
                  ) : 'Not Found'
                )),
              },
            },
          ],
        },
        {
          id: 'app',
          path: 'applications',
          handle: {
            crumb: useCallback(() => <Link to="/applications/all">Applications</Link>),
          },
          children: [
            {
              id: 'app-list',
              path: 'all',
              element: <ApplicationsTableView />,
            },
            {
              id: 'app-single',
              path: ':appParam',
              element: <ApplicationSingleView userEmail={user.email} />,
              loader: async ({ params }) => doRequest(`/api/v1/applications/${params.appParam}`, 'GET').catch(() => null),
              handle: {
                crumb: useCallback(({ data }) => <span>{data !== null ? data.data.application.Name : 'Not Found'}</span>),
              },
            },
          ],
        },
        {
          id: 'reporting',
          path: 'reporting',
          handle: {
            crumb: useCallback(() => <div>Reporting</div>),
          },
          children: [
            {
              id: 'reporting-user',
              path: ':position/:userId',
              element: <ReportingView userEmail={user.email} />,
              loader: async ({ params }) => doRequest(`/api/v1/reporting/${params.userId}/decisionrecords?position=${params.position}`, 'GET'),
              handle: {
                crumb: useCallback(({ params }) => <span>{params.userId}</span>),
              },
            },
          ],
        },
        {
          path: 'gettingstarted',
          element: <GettingStarted />,
          handle: {
            crumb: useCallback(() => <span>Getting Started</span>),
          },
        },
      ],
    },
  ])

  return (
    <RouterProvider router={router} />
  );

//   return (
//     <Routes>
//       <Route path="/" element={<Layout userSettings={userSettings} atRisk={atRisk} />}>
//         <Route index element={<Homepage userEmail={user.email} />} />
//         <Route path="*" element={<NotFound />} />

//         <Route path="home" element={<Homepage userEmail={user.email} />} />
//         <Route path="admin" element={<Admin authedAsAdmin={user.authedAsAdmin} />} />

//         <Route path="test" element={<Test />} />
//         <Route path="history" element={<History labels={labels} userSettings={userSettings} />} />

//         <Route path="decisionrecords">
//           <Route path="all" element={<DecisionRecordsList />} />
//           <Route path="create" element={<CreateDecisionRecord />} />
//           <Route path=":drId" element={<DecisionRecordView userEmail={user.email} />} />
//         </Route>
//         <Route path="applications">
//           <Route path="all" element={<ApplicationsTableView />} />
//           <Route path=":appParam" element={<ApplicationSingleView userEmail={user.email} />} />
//         </Route>
// {/* 
//         <Route path="changes" element={<Changes labels={labels} userSettings={userSettings} />} />
//         <Route path="mychanges" element={<MyChanges userEmail={user.email} labels={labels} updateAbandonedBanner={updateAbandonedBanner} atRisk={atRisk} />} />

//         <Route path="createrfc" element={<CreateRFC userEmail={user.email} labels={labels} />} />
//         <Route path="incidents">
//           <Route path="current" element={<Incidents key={"current"} userSettings={userSettings} authedAsSre={user.authedAsSre} authedAsAdmin={user.authedAsAdmin} current={true} labels={labels} />} />
//           <Route path="tracked" element={<Incidents key={"tracked"} userSettings={userSettings} authedAsSre={user.authedAsSre} authedAsAdmin={user.authedAsAdmin} current={false} labels={labels} tracked />} />
//           <Route path="stats" element={<IncidentStats userEmail={user.email} />} />
//         </Route>
//         <Route path="tickets">
//           <Route path="open" element={<Tickets userTagSettings={userTagSettings} open={true} />} />
//           <Route path="closed" element={<Tickets userTagSettings={userTagSettings} open={false} />} />
//         </Route>

//         <Route path="bpm/create" element={<BPM key={"create"} userEmail={user.email} createNew={true} userRoles={user.roles} />} />
//         <Route path="bpm" element={<BPM key={"view"} userEmail={user.email} userRoles={user.roles} />} />

//         <Route path="bpmsearch" element={<BPMSearch labels={labels} />} />

//         <Route path="incident" element={<SingleIncView authedAsAdmin={user.authedAsAdmin} authedAsSre={user.authedAsSre} labels={labels} />} />
//         <Route path="change" element={<SingleChangeView userEmail={user.email} labels={labels} />} />

//         <Route path="kpis" element={<KPIs userEmail={user.email} />} />
//         <Route path="createkpi" element={<CreateKPI userEmail={user.email} createNew isEditing={true} />} />
//         <Route path="kpi" element={<CreateKPI userEmail={user.email} createNew={false} isEditing={false} />} />

//         <Route path="kpis/action-items" element={<ActionItems userEmail={user.email} />} />
//         <Route path="action-items" element={<ActionItems />} /> */}

//         <Route path="user-profile" element={<UserProfilePage lables={labels} user={userSettings} setProfileCallback={setProfileCallback} />} />
//         <Route path="test/comps" element={<TestComponents />} />
//       </Route>
//     </Routes>
//   );
}

export default ReRoutes;
