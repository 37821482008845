// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DhBYv1DhjxZFN1Q6lLv5 {
    padding: 4px;
    padding-top:12px;
    padding-bottom: 12px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.DhBYv1DhjxZFN1Q6lLv5.wSnpzG5o_K34IbjBaUfD {
    background-color: rgba(0, 0, 0, 0.07)
}

.DhBYv1DhjxZFN1Q6lLv5:hover {
    color: #fff !important;
    background-color: #f96303 !important;
    cursor: pointer;
}

.JWHqGmVLe4QwdNIlxWNn {
    background-color: #f96303;
}

.Ev8AOQ8H1klMBd9ei9L5 {
    font-weight: bolder;
}

.TLIxqJzRI5EUwlHxPPiW {
    font-size: small;
}`, "",{"version":3,"sources":["webpack://./src/recker/Search/Common/ChangeResult.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;IAChB,oBAAoB;IACpB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,8BAA8B;AAClC;;AAEA;IACI;AACJ;;AAEA;IACI,sBAAsB;IACtB,oCAAoC;IACpC,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".changeResult {\n    padding: 4px;\n    padding-top:12px;\n    padding-bottom: 12px;\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    justify-content: space-between;\n}\n\n.changeResult.even {\n    background-color: rgba(0, 0, 0, 0.07)\n}\n\n.changeResult:hover {\n    color: #fff !important;\n    background-color: #f96303 !important;\n    cursor: pointer;\n}\n\n.matchHighlight {\n    background-color: #f96303;\n}\n\n.matchField {\n    font-weight: bolder;\n}\n\n.matchText {\n    font-size: small;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"changeResult": `DhBYv1DhjxZFN1Q6lLv5`,
	"even": `wSnpzG5o_K34IbjBaUfD`,
	"matchHighlight": `JWHqGmVLe4QwdNIlxWNn`,
	"matchField": `Ev8AOQ8H1klMBd9ei9L5`,
	"matchText": `TLIxqJzRI5EUwlHxPPiW`
};
export default ___CSS_LOADER_EXPORT___;
