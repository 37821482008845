import { NavLink } from 'react-router-dom';
import React from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import autobind from 'class-autobind';
import { doRequest, isObjectEmpty } from '../Utils/utils.js';
import CustSelect from '../Common/CustSelect.jsx'
import CreateSelect from '../Common/CreateSelect.jsx'
import Tooltip from '../Common/Tooltip/Tooltip.jsx';
import '../ActionItems/ActionItems.css'
import Comments from './components/comments/Comments.jsx';
import Button from '../SharedComponents/Button.jsx';
import ActionItemModal from '../ActionItems/components/modals/ActionItemModal.jsx';

class ActionItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editJiraStatus:false,
      actionItemOwner:"",
      currnetUser:"",
      BpmOWner:"",
      expanded: false,
      showStatusButtons:false,
      showDeleteButton:false,
      disableEditActionItem: false,
      disableEditStatus:true,
      dropdownData: [{
        value: 'Prevent',
        label: 'Prevent'
      },
      {
        value: 'Mitigate',
        label: 'Mitigate'
      },
      {
        value: 'Process',
        label: 'Process'
      },
      {
        value: 'Other',
        label: 'Other'
      },],
      statusOptions: [
        // {
        //   value: 'Requested',
        //   label: 'Requested'
        // },
        // {
        //   value: 'Accepted',
        //   label: 'Accepted'
        // },
        // {
        //   value: 'Rejected',
        //   label: 'Rejected'
        // },
       {
          value: 'Complete',
          label: 'Complete'
        },
        {
          value: 'In Progress',
          label: 'In Progress'
        },
        {
          value: 'Blocked',
          label: 'Blocked'
        }
        ,]
        ,
      createInJira: false,
      sendSlackNotifications: true,
      isModalOpen: false,
    }

    autobind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.disableEditing != this.props.disableEditing){ 
      this.whoCanEditItem(this.state.currnetUser)
    }
    if (prevProps.expanded != this.props.expanded) {
      this.setState({ expanded: this.props.expanded })
    }
    if (!_.isEqual(prevProps, this.props)) {
      this.updateComponent()
    }
  }
  componentDidMount() {
    this.updateComponent()
  }

  updateComponent() {
    if (this.props.actionItem.info == "") {
      this.setState({ expanded: true })
    }
    if (this.props.actionItem.dropdownDataSelected) {
      this.setState({ dropdownDataSelected: this.props.actionItem.dropdownDataSelected })
    }
    if (this.props.actionItem.bugStatus) {
      let t = {}
      t.value = this.props.actionItem.bugStatus
      t.label = this.props.actionItem.bugStatus
      this.setState({ statusOptionSelected: t })
    }

    if (this.props.actionItem.type) {
      let t = {}
      t.value = this.props.actionItem.type
      t.label = this.props.actionItem.type
      this.setState({ dropdownDataSelected: t })
    }
    if (this.props.actionItem.sendSlackNotifications !== undefined) {
      this.setState({ sendSlackNotifications: this.props.actionItem.sendSlackNotifications })
    }
    if(this.props.actionItem.createdBy){
    this.setState({
      actionItemOwner:this.props.actionItem.createdBy
    })
    }
    let currnetUser = JSON.parse(localStorage.getItem("currentUser")).email
    this.whoCanEditItem(currnetUser)


    
    // if(this.props.actionItemOwner){
    //   let showStatusButtons = this.statusActionItemPage(this.props.actionItemOwner,this.props.actionItem.bugStatus)
    //     this.setState({
    //     showStatusButtons
    //   })
    // }

   this.setState({
    currnetUser
   })
  }

  dropdownCallback(event, key) {
    let e = {}
    e.target = {}
    e.target.value = event.label

    this.props.addTimelineItemInfo(e, this.props.id, "type")

    this.setState({ dropdownDataSelected: event }, () => {
      // window.localStorage.setItem("state", JSON.stringify(this.state))
    });
  }
  handleChangeCreate(event, key) {
    let e = {}
    event.label = event.label.replace("Create: ", "")
    e.target = {}
    e.target.value = event.label
  
    this.props.addTimelineItemInfo(e, this.props.id, "bugStatus")

    this.setState({ statusOptionSelected: event }, () => {
      // this.state.statusOptions.push(event)

      // window.localStorage.setItem("state", JSON.stringify(this.state))
    });
  }

  handleToggleSlackNotifications() {
    if (!this.props.disableEditing) {
      this.setState({ sendSlackNotifications: !this.state.sendSlackNotifications }, () => {
        let e = { target: { value: this.state.sendSlackNotifications } }
        this.props.addTimelineItemInfo(e, this.props.id, "sendSlackNotifications")
      })
    }
  }
  handleToggleJiraProj() {
    if (!this.props.disableEditing) {
      if (this.state.showJira) {
        this.props.addTimelineItemInfo("", this.props.id, "project")
        this.setState({ showJira: false })
      } else {
        this.setState({ showJira: true })
      }

    }
  }
  handleInputChange(e, a, id) {
    let t = {}
    t.value = e
    t.label = "Create: " + e
    if (a.action != "menu-close" && e !== "") {
      this.state.statusOptions.pop()
      this.state.statusOptions.push(t)
    }

  }

  closseAddProjModal(e) {
    if (e.target.id == "close") {
      this.setState({ projectAddModal: false })
    }
  }

  isDST(d) {
    d = new Date(d)
    let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
    let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(jan, jul) !== d.getTimezoneOffset();
  }
  setJiraValue() {
    let value = this.props.jiraProjects.filter(item => item.value.includes(this.props.actionItem.project))
    return value[0]
  }
  statusActionItemPage(ownerEmail,status){
    if(status != 'Requested'){
      return false
    }
    if(this.state.currnetUser.toLowerCase() == ownerEmail.toLowerCase()){
      return true
    }else {
      return false
    }
  }

  async handleSubmitActionItemStatus(action){
    let id = this.props.id || this.props.actionItem.id
    let url =`api/v1/bpms/action-items/${id}/${action}`
    let statusOptionSelected = `${action == 'accept' ? 'Todo':'Rejected'}`
    await doRequest(url,'patch', null,null).then(data=>{
      let res
      if(data.data.success){
        //update BPM state with new value
        res= data.data.success
        this.setState({
          statusOptionSelected:{
              value: statusOptionSelected,
              label: statusOptionSelected
          },
          showStatusButtons:false
        })
        let e = {}
        e.target = {}
        e.target.value = statusOptionSelected
        this.props.addTimelineItemInfo(e, this.props.id, "bugStatus")
      }
    })
  }
whoCanEditItem(currnetUser){
    let {isCreateNew,creator,actionItem,isKpiAI,disableEditing,AIowner} = this.props
    let actionItemOwner;
    if(this.props.AIowner){
      actionItemOwner = AIowner[this.props.id]
      this.setState({
        actionItemOwner
      })
    } 
    // debugger
  if(isCreateNew){
    this.setState({
      showDeleteButton:true,
      disableEditActionItem:false,
      disableEditStatus:true,
      showStatusButtons:false
    })
    return;
  }
  let showStatusButtons = actionItem.bugStatus == "Requested" ? true : false
  let editJiraStatus ;
  let disableEditStatus;

  if(actionItem.bugStatus == "Requested" || actionItem.bugStatus == "Rejected"){
    editJiraStatus = false
    disableEditStatus=true
  }else {
    editJiraStatus = true
    disableEditStatus=false
  }
  
  if(isKpiAI){
    if(currnetUser.toLowerCase() === this.props.actionItemOwner.Email.toLowerCase()){
     let disableEditActionItem = actionItem.bugStatus == "Rejected" ? true : false 
      this.setState({
        showDeleteButton:true,
        disableEditActionItem,
        disableEditStatus,
        showStatusButtons,
        editJiraStatus
      })
    }else {
      this.setState({
        showDeleteButton:false,
        disableEditActionItem:true,
        disableEditStatus:true,
        showStatusButtons:false
      })
    }
    return
  }
    if(!disableEditing){
      if(currnetUser.toLowerCase() === actionItemOwner?.email.toLowerCase() || this.props.canEdit==true){
     let disableEditActionItem = actionItem.bugStatus == "Rejected" ? true : false 
        this.setState({
          showDeleteButton:true,
          disableEditActionItem,
          disableEditStatus,
          showStatusButtons,
          editJiraStatus
        })
        return
      }else if(currnetUser.toLowerCase() === creator.toLowerCase()){
        this.setState({
          showDeleteButton:true,
          disableEditActionItem:false,
          showStatusButtons:false,
          editJiraStatus: false
        })
        return;
      }else if(actionItemOwner === undefined){
        this.setState({
          showDeleteButton:true,
          disableEditActionItem:false,
          disableEditStatus:true,
          showStatusButtons:false
        })
      }else {
        this.setState({
          showDeleteButton:false,
          disableEditActionItem:true,
          disableEditStatus:true,
          showStatusButtons:false
        })
      }
    }else {
      this.setState({
        showDeleteButton:false,
        disableEditActionItem:true,
        disableEditStatus:true,
        showStatusButtons:false
      })
  }
  }
  render() {
    const ProjectModel = () => (
      <div className="modal-bg" id="close" onClick={(e) => { this.closseAddProjModal(e) }}>
        <div className="modal-body-lg" style={{ zIndex: '1111' }}>
          <div style={{ fontWeight: 'bold', fontSize: '20px', marginBottom: '45px' }}> Where is my project? </div>
          If a project is not listed in the dropdown it means that REin does not yet have permission to create issues in the project.
          <div></div>
          <div style={{ height: '20px' }}></div>
          1. The Rein Service account will need to be added to the project by a <strong>Jira Admin.   </strong>         <div></div>

          2. Please go to<strong>Project Settings</strong> , then navigate to <strong>People.</strong> Click the <strong>Add People</strong> button.
          <div></div>

          3. Search for <strong>svc_cld_eng_ci@homedepot.onmicrosoft.com </strong> and add the Jira Service Account role.
          <div></div>

          <div style={{ height: '20px' }}></div>

          Once this is completed, REin will have access to the project. {!this.state.showUpdated ? <span>Please press <span style={{ fontStyle: 'italic', paddingLeft: '5px', color: '#f96302', cursor: 'pointer' }} onClick={() => { this.props.getProjects(); this.setState({ showUpdated: true }) }}>here</span> or refresh the page to update the projects list. </span> : <span>Projects successfully updated!</span>}
          <div></div>

          <div style={{ display: 'flex', justifyContent: 'right', marginTop: '145px' }}>
            <div className="button secondary sm" onClick={() => { this.setState({ projectAddModal: false }) }}>Okay</div>
          </div>
        </div>
      </div>
    );
    let selectedT
    if (this.props.actionItem.dueBy == "Invalid Date" || this.props.actionItem.dueBy == "" || typeof (this.props.actionItem.dueBy) == "undefined") {
      if (this.props.isKpi) {
        // TODO set for next week at KPI time
        selectedT = new Date().setHours(new Date().getHours() + 7 * 24)
        if (this.isDST(selectedT)) {
          selectedT = new Date(selectedT).setUTCHours(18, 0, 0, 0)
        } else {
          selectedT = new Date(selectedT).setUTCHours(19, 0, 0, 0)
        }
      } else {
        selectedT = new Date()
      }
      this.props.handleTimeChange(selectedT, this.props.id)
    } else {
      selectedT = new Date(this.props.actionItem.dueBy)
    }

    let url = ""
    this.props.actionItem.jiraId ? url = `https://thd.atlassian.net/browse/${this.props.actionItem.jiraId}` : null
    const JiraIcon = () => (

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '-5px', marginLeft: '-5px' }}>
        <img src={require("../../img/mark-contained-gradient-blue-jira-software.svg")} />
        <div style={{ fontSize: '9px', fontWeight: 'bold', marginTop: '-10px' }}>Jira</div></div>
    )
    const ShowJira = () => (
      <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
        <div>Project:</div>
        <div style={{ width: '250px', marginLeft: '5px' }}>
          <CustSelect
            disabled={this.state.disableEditActionItem}
            options={this.props.jiraProjects}
            value={this.setJiraValue()}
            dropdownCallback={(e) => { this.props.addTimelineItemInfo(e, this.props.id, "project") }} />
        </div></div>
    )
    return (

      <div>
        <ActionItemModal
          actionItemId={this.props.actionItem.id}
          closeCallback={() => this.setState({ isModalOpen: false })}
          open={this.state.isModalOpen}
          onChange={() => setTimeout(() => this.props.refresh(), 2000)}
        />
        <div className="rfcRow action-item" style={{}}>
          {this.state.expanded ? 
            <i style={{ color: '#f96302' }} className='icon_chevron-up' onClick={() => { this.setState({ expanded: !this.state.expanded }) }} /> :
            <i style={{ color: '#f96302' }} className='icon_chevron-right' onClick={() => { this.setState({ expanded: !this.state.expanded }) }} />}

          <div className="rfcInputDropdownCont" style={{ flex: 3, marginRight: '10px' }}>
            {this.props.isKpi ?
              <textarea
                className="input auto-resizing-textarea"
                style={{ padding: '1px', minHeight: 27, maxHeight: 100, width: '100%', marginRight: '10px' }}
                disabled={this.state.disableEditActionItem} type="text" value={this.props.actionItem.info}
                onChange={(e) => { this.props.addTimelineItemInfo(e, this.props.id, "info") }}
              />
              :
              <textarea
                className="input"
                style={{ padding: '1px', height: '29px', width: '100%', marginRight: '10px' }}
                disabled={this.state.disableEditActionItem} type="text" value={this.props.actionItem.info}
                onChange={(e) => { this.props.addTimelineItemInfo(e, this.props.id, "info") }}
              />
            }
          </div>
          <div style={{ width: '125px', }}>
            <CustSelect
              disabled={this.state.disableEditActionItem}
              options={this.state.dropdownData}
              value={!isObjectEmpty(this.state.dropdownDataSelected) ? this.state.dropdownDataSelected : null}
              dropdownCallback={(e) => { this.dropdownCallback(e, this.props.id) }} />
          </div>
     {
      !this.state.disableEditActionItem && this.props.assigned ?
      this.props.assigned:
              <div
              className="action-item-owner_disabled"
              >
                {
                  this.props.isKpiAI ?  
                  `${this.props.actionItemOwner.Name} ${this.props.actionItemOwner.UserId}` : `${this.props.AIowner[this.props.id]?.value}`
                }
            </div>
     }
          <div className="rfcInputDropdownCont" style={{ flex: 1 }}>
            <CreateSelect
              value={!isObjectEmpty(this.state.statusOptionSelected) ? this.state.statusOptionSelected : ''}
              disabled={this.state.disableEditStatus}
              options={this.state.statusOptions}
              onInputChange={(e, a, id) => { this.handleInputChange(e, a, id) }}
              dropdownCallback={(e) => { this.handleChangeCreate(e, this.props.id) }}
            />
          </div>

          <div className="rfcInputDropdownCont" style={{ width: '100px', display: 'flex', paddingRight: '10px', marginLeft: '10px' }}>
            <div style={{ display: 'flex', width: '100px', justifyContent: 'flex-start', alignItems: 'center' }}>
              <DatePicker
                onChange={(e) => { this.props.handleTimeChange(e, this.props.id) }}
                // showTimeSelect
                disabled={this.state.disableEditActionItem}
                selected={selectedT}
                // timeFormat="HH:mm"
                dateFormat="MMM d, yyyy"
                className={this.state.disableEditActionItem ? "datepickerCustInputDisabled" : "datepickerCustInput"}
              />
              <div className={this.state.disableEditActionItem ? "bringFront" : null} style={{ marginLeft: '-10px', marginTop: '-2px', display: 'flex', alignItems: 'center' }}>
                <div></div></div></div>
          </div>
          <div className="action-item_delete"> 
              {
                this.props.isKpi == "follow-up" ? null :
                <>
           
                {this.state.showDeleteButton && 
                  <i 
                  onClick={(e) => {this.props.deleteStep(this.props.id)}} 
                  className="icon_trash"></i>
                }
                  </>
              }
            </div>


        </div>
        <div style={{ textAlign: 'right', paddingRight: '3px', marginTop: '-3px', marginBottom: '-10px' }}>
          {/* {this.props.finalItem && this.props.isKpi !== "follow-up" ?  <button style={{ marginBlock: 0 }} type="button" className="primary sm button" onClick={(e) => { this.props.addStep(this.props.id) }} >Add Item</button> :null} */}

        </div>
        {this.state.expanded ?
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 1 }}>
              <div style={{ marginLeft: '20px', paddingTop: '15px', paddingBottom: '20px' }}>
                {this.props.disableEditing || this.props.isKpi == "follow-up" ? <div>
            {this.props.actionItem.jiraId ?
                    <div style={{ display: 'flex'}}>
                      {this.props.actionItem.jiraStatus == "Deleted"

                        ? <div></div> :
                        <a target="_blank" style={{ display: 'flex', alignItems: 'center' }} href={url}>
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '-5px', marginLeft: '-5px' }}>
                            <img src={require("../../img/mark-contained-gradient-blue-jira-software.svg")} />
                            <div style={{ fontSize: '9px', fontWeight: 'bold', marginTop: '-10px' }}>Jira</div>
                          </div>
                          <span style={{ color: 'orange', cursor: 'pointer', paddingRight: '8px', paddingLeft: '8px' }}>Jira Story: {this.props.actionItem.jiraId}</span>     </a>
                      }
                    </div>

                    : <div style={{ width: '27px', }}></div>
                  } 
                  </div>
                  :
                  <div >
                    <div style={{ alignItems: 'center'}}>
                      {this.state.editJiraStatus ?
                        <div>
                          {this.props.actionItem.jiraId ?
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                              {this.props.actionItem.jiraStatus == "Deleted" ?
                                <div>{this.state.recreateNewJira ? <div>   {this.state.showJira ?
                                  <div style={{ display: 'flex' }}>
                                    <JiraIcon />
                                    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '7px' }}>
                                      <input style={{ height: '15px', margin: '0px', marginRight: '3px' }} type="checkbox" onClick={() => { this.handleToggleJiraProj(); this.state.showJira ? this.setState({ recreateNewJira: false }) : null }} checked={this.state.showJira} className='action-item-slack_checkbox' id="" />
                                      Create Jira Story?

                                      {this.state.showJira ? <div><ShowJira /></div>
                                        : null}


                                    </div>
                                  </div>
                                  : null}
                                </div> :
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <JiraIcon /> <span style={{ paddingLeft: '13px', }}>Deleted<span style={{ color: 'orange', cursor: 'pointer', paddingRight: '13px', paddingLeft: '3px', fontStyle: 'italic' }} onClick={() => { this.setState({ recreateNewJira: true }); this.handleToggleJiraProj() }}> (create new story in Jira)</span></span>

                                  </div>}
                                </div> :
                                <div
                                className='action-item_jira-added'
                                style={{ display: 'flex', alignItems: 'center'}}>
                                  <JiraIcon />
                                  <a target="_blank" href={url}> <span style={{ color: 'orange', cursor: 'pointer', paddingRight: '8px', paddingLeft: '3px' }}>Jira Story: {this.props.actionItem.jiraId}</span>     </a>
                                </div>
                              }
                            </div>
                            :
                            <div style={{ display: 'flex' }}>
                            {

                          !this.state.disableEditActionItem && this.props.isCreateNew == undefined &&
                          <>
                              <JiraIcon />
                              <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '7px' }}>
                                <input style={{ height: '15px', margin: '0px', marginRight: '3px' }} type="checkbox" onClick={() => this.handleToggleJiraProj()} checked={this.state.showJira} className='action-item-slack_checkbox' id="" />
                                Create Jira Story?

                                {this.state.showJira ? <div><ShowJira /></div>
                                  : null}


                              </div>    
                              </>
                        }
                            </div>
                        
                          }
                        </div> : null
                      }
                    </div></div>}
                {this.props.isKpi &&
                  <div className='action-item-slack-wrapper'>
                    <Tooltip
                      style={{ width: 350 }}
                      tip={<p>This owner of this action item
                        <strong>{`${!this.state.sendSlackNotifications ? "WILL NOT" : "WILL"}`}</strong> get a slack reminder when it is created and when it is about to become overdue. Click to toggle.</p>}>
                      <img
                        className={`slack-icon ${!this.state.sendSlackNotifications ? "disabled" : ""}`}
                        src={require("../../img/Slack-stacked-logo-RGB.svg")} />
                    </Tooltip>
                    <input
                      style={{ height: '15px', margin: '0px' }}
                      type="checkbox"
                      onClick={() => this.handleToggleSlackNotifications()}
                      checked={this.state.sendSlackNotifications}
                      className='action-item-slack_checkbox'
                      id="" />
                    <div style={{ marginTop: '-2px' }}>
                      {this.state.sendSlackNotifications ?
                        <span>Slack alerts being sent to:
                          {this.props.assigned.props.dropdownDataSelected.assignedTo[this.props.id] ?
                            this.props.assigned.props.dropdownDataSelected.assignedTo[this.props.id].value :
                            this.props.actionItem.owner.value}
                            </span> : "No Slack alerts sent"}</div>
                  </div>}
              </div>
              {!this.props.isCreateNew && this.props.actionItem.id &&
                <div className="action-item_comments">  
                  <Comments 
                      creator={this.props.creator}
                      isEditing={this.props.disableEditing}
                      actionItemId={this.props.id}
                      iscreateNew={this.props.isCreateNew}
                      isKpiAI={this.props.isKpiAI}
                      id={this.props.actionItem.id}
                  />
                </div>
              }
            </div>
            {!this.props.isCreateNew && this.props.actionItem.id &&
              <div style={{ display: 'flex', justifyContent: 'end', marginRight: 20, marginTop: 5 }}>
                <button className='button primary sm' type='button' onClick={() => this.setState({ isModalOpen: true })}>View</button>
              </div>
            }
          </div> : null}

        {this.props.finalItem && this.props.isKpi !== "follow-up" && !this.props.disableEditing ?
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <span className="primary sm button" onClick={(e) => { 
              this.props.addStep(this.props.id) }}>Add item</span>
          </div> : <div style={{ width: '10px', height: '10px' }}></div>}

        {this.state.projectAddModal ? <ProjectModel /> : null}
      </div>
    )
  }
}
export default ActionItem