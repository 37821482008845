import React from 'react';

type SidebarSectionRowProps = {
  header: string,
  children: any,
}

function SidebarSectionRow({ header, children }: SidebarSectionRowProps) {
  return (
    <div className="sidebar-section-row">
      <div>
        <header>{header}</header>
      </div>
      <div className="w-full break-words">
        {children}
      </div>
    </div>
  );
}

export default SidebarSectionRow;
