import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider, LoadingPage } from '@one-thd/retooling-react';
import loadConfig from './recker/Utils/config';
import './css/index.css'
import './css/output.css'

import ReRoutes from './RoutesNew.jsx';
import { BrowserRouter } from 'react-router-dom';

const root = createRoot(document.getElementById('root'));

function App() {
  const authProviderConfig = {
    appName: "RECKER",
    gsiClientId: window.REACT_APP_GSI_CLIENT_ID,
    redirectFlow: true,
    reauthHost: window.REACT_APP_OAUTH_URL,
    reauthClientId: window.REACT_APP_OAUTH_CLIENT_ID,
    resourceIds: [
      "https://localhost:8080",
      "https://recker-api.hdperfeng.gcp.homedepot.com",
      "https://recker-api-stage.hd-engineering.gcp.homedepot.com",
    ]
  }
    return(
      <AuthProvider config={authProviderConfig}>
        <ReRoutes />
      </AuthProvider>
    )

}

root.render(<LoadingPage appName="RECKER" message="Loading..." />)

loadConfig().then(() => {
  setTimeout(() => {
    root.render(<App />)
  }, 1000)
});

export default App;
