import React, { useEffect, useState } from 'react'
import Card from '../ui/card'
import { NavLink } from 'react-router-dom';
import DataTable from '../ui/dataTable/datatable';
import { doRequest } from '../Utils/utils';
import { DecisionRecord, DashboardColumns as DRColumns } from '../Records/DecisionRecords/DecisionRecordType';
import { TestRunRecord, DashboardColumns as TRColumns } from '../Records/TestRunRecords/TestRunRecordType';

export default function Dashboard() {
  const [recentDecisionRecords, setRecentDecisionRecords] = useState<DecisionRecord[]>([])
  const [loadingRecentDecisionRecords, setLoadingRecentDecisionRecords] = useState<boolean>(true)

  const [recentTestRecords, setRecentTestRecords] = useState<TestRunRecord[]>([])
  const [loadingRecentTestRecords, setLoadingRecentTestRecords] = useState<boolean>(true)

  useEffect(() => {
    doRequest('/api/v1/decisionrecords', 'get', null, null)
      .then(({ data }) => {
        setRecentDecisionRecords(data.records)
        setLoadingRecentDecisionRecords(false)
      }).catch((err) => {
        console.error(err)
      })

    doRequest('/api/v1/testrunrecords', 'get', null, { sort: 'newest' })
      .then(({ data }) => {
        setRecentTestRecords(data.records)
        setLoadingRecentTestRecords(false)
      }).catch((err) => {
        console.error(err)
      })
  }, [])

  return (
    <>
      <h1 className="text-6xl font-bold text-center mt-10">REcker Destructive Testing</h1>
      <h2 className="text-2xl text-center mt-5 text-neutral-600">Select an option below to get started</h2>
      <div className="mt-20 flex justify-center w-4/5 gap-8 m-auto flex-wrap">
        <NavLink to="/gettingstarted">
          <Card className="w-72 h-52 hover:text-primary hover:cursor-pointer hover:bg-neutral-200">
            <Card.Content className="flex flex-col align-middle justify-evenly gap-4 p-8 h-full">
              <i className="icon_help-outlined text-7xl text-center text-primary opacity-80" />
              <Card.Title className="text-xl text-center">Getting Started</Card.Title>
            </Card.Content>
          </Card>
        </NavLink>
        <NavLink to="/decisionrecords/new">
          <Card className="w-72 h-52 hover:text-primary hover:cursor-pointer hover:bg-neutral-200">
            <Card.Content className="flex flex-col align-middle justify-evenly gap-4 p-8 h-full">
              <div className="text-5xl font-bold text-center text-primary opacity-80">DCR</div>
              <Card.Title className="text-xl text-center">New Decision Record</Card.Title>
            </Card.Content>
          </Card>
        </NavLink>
        <NavLink to="/testrecords/new">
          <Card className="w-72 h-52 hover:text-primary hover:cursor-pointer hover:bg-neutral-200">
            <Card.Content className="flex flex-col align-middle justify-evenly gap-4 p-8 h-full">
              <div className="text-5xl font-bold text-center text-primary opacity-80">TRR</div>
              <Card.Title className="text-xl text-center">New Test Run Record</Card.Title>
            </Card.Content>
          </Card>
        </NavLink>
        {/* <NavLink to={"/"}>
          <Card className="w-72 h-52 hover:text-primary hover:cursor-pointer hover:bg-neutral-200">
            <Card.Content className="flex flex-col align-middle justify-evenly gap-4 p-8 h-full">
              <i className="icon_person text-7xl text-center text-primary opacity-80" />
              <Card.Title className="text-xl text-center">My Records</Card.Title>
            </Card.Content>
          </Card>
        </NavLink> */}
      </div>
      <div className="mt-20 mx-auto flex justify-center gap-8 max-w-screen-2xl">
        <Card className="w-1/2">
          <Card.Title>Recent Decision Records</Card.Title>
          <Card.Content className="p-0">
            {loadingRecentDecisionRecords && <div className="text-center text-2xl">Loading...</div>}
            {recentDecisionRecords
              && !loadingRecentDecisionRecords
              && (
                <DataTable
                  data={recentDecisionRecords}
                  columns={DRColumns}
                  pageSize={5}
                  cellClassName="px-4 py-2"
                />
              )}
          </Card.Content>
        </Card>
        <Card className="w-1/2">
          <Card.Title>Recent Test Runs</Card.Title>
          <Card.Content className="p-0">
            {loadingRecentTestRecords && <div className="text-center text-2xl">Loading...</div>}
            {recentTestRecords
              && !loadingRecentTestRecords
              && (
                <DataTable
                  data={recentTestRecords}
                  columns={TRColumns}
                  pageSize={5}
                  cellClassName="px-4 py-2"
                />
              )}
          </Card.Content>
        </Card>
      </div>
    </>
  )
}