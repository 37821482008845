import React, { useCallback, useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { redirect, useLocation, useNavigate } from 'react-router-dom';

import MultistepForm, { Step } from '../../Form/multistep';
import ApplicationStep from '../FormSteps/ApplicationStep';
import PreTestingStep from '../FormSteps/PreTestingStep';
import { doRequest } from '../../Utils/utils';
import AttachmentStep from "../FormSteps/AttachmentStep";
import useAttachment from "../../Common/Hooks/useAttachment";
import { ApplicationService } from '../../Applications/ApplicationType';

export default function NewDecisionRecord() {
  const navigate = useNavigate()
  const [attachments, setAttachments] = useState<File[]>([])
  const [application, setApplication] = useState<ApplicationService>()
  const [testTool, setTestTool] = useState<string>('')

  const { applicationParam } = useLocation()?.state as { applicationParam: ApplicationService } || { applicationParam: null }

  const { upload: uploadAttachment } = useAttachment({ POST: '/api/v1/attachments/:recordNum' })

  useEffect(() => {
    if (applicationParam) {
      setApplication(applicationParam)
    }
  }, [])

  const steps: Step[] = [
    {
      label: 'Application',
      title: 'Application Information',
      component: useCallback((form: UseFormReturn) => <ApplicationStep form={form} application={application} onApplicationChange={setApplication} />, [application]),
    },
    {
      label: 'Testing',
      title: 'Testing Information',
      component: useCallback((form: UseFormReturn) => <PreTestingStep form={form} testTool={testTool} onTestToolChange={setTestTool} />, []),
    },
    {
      label: 'Attachments',
      title: 'Add Attachments',
      component: useCallback(() => <AttachmentStep attachments={attachments} onAttachmentsChange={setAttachments} />, [attachments]),
    },
    {
      label: 'Review',
      title: 'Review',
      component: useCallback((form: UseFormReturn) => (
        <div>
          {steps.map((step, i) => (
            <div>
              {i !== steps.length - 1
                ? (
                  <div>
                    <h3 className="text-lg mb-2">{step.title}</h3>
                    {step.component(form)}
                    {i !== steps.length - 2 ? <hr className="mt-6 mb-6" /> : null }
                  </div>
                )
                : null}
            </div>
          ))}
        </div>
      ), [attachments, application]),
    },
  ]

  const onSubmit: SubmitHandler<FieldValues> = (values) => {
    doRequest('/api/v1/decisionrecords', 'post', { ...values, ApplicationID: values.ApplicationSelect.SysId }, null)
      .then((data) => {
        if (data && data.status === 201 && data.data) {
          submitAttachments(data.data.record.RecordNumber)
            .then(() => {
              navigate(`/decisionrecords/${data.data.record.RecordNumber}`, {
                state: {
                  recordProp: data.data.record,
                },
              })
            })
        }
      })
      .finally(() => {
      })
  };

  const submitAttachments = async (recordNumber: string): Promise<void> => {
    const promises: Promise<void>[] = []

    attachments.forEach((attachment) => {
      promises.push(uploadAttachment({ recordNum: recordNumber }, attachment))
    })

    return Promise.all(promises)
      .then(() => Promise.resolve()).catch(() => {
        // handle error
      })
  }

  return (
    <MultistepForm title="Create New Decision Record" steps={steps} onSubmit={onSubmit} />
  )
}
