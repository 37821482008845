import { createContext, useContext } from 'react';
import { doRequest } from "./utils";

export const getUserData = async () => {
  let url = `/api/v1/user`;
  let res;
  await doRequest(url, "get", null, null).then((data) => {
    if (data.status == 200) {
      res = data.data;
    }
  });
  return res;
};

export const updateUserSettings = async (data) => {
  let url = `/api/v1/usersSettings`;
  let res;
  let body = data;
  await doRequest(url, "post", body, null).then((data) => {
    if (data.status == 200) {
      res = {
        type: "success",
        msg: "Setting updated Successfully",
      };
    } else {
      res = {
        type: "error",
        msg: "Something went wrong saving settings",
      };
    }
  }).catch(err=>{
    res = {
      type: "Info",
      msg: "Nothing to Submit",
    };
  })
  return res;
};

export const getUserNotificationPref = async (email) =>{
  try {
    let url = `/api/v1/user/${email}`;
    let res;
    await doRequest(url, "get", null, null).then((data) => {
      if (data.status == 200) {
        res = data.data.preferences;
      }
    });
    return res;
  } catch (error) {
    throw new Error(error)
  }
 
}

export const getUserByLdap = async (ldap)=>{
  try {
    let url = `/api/v1/search/users?q=${ldap}`;
    let res;
    await doRequest(url, "get", null, null).then((data) => {
      if (data.status == 200) {
        res = data.data.match.usersByLDap;
      }
    });
    return res;
  } catch (error) {
    throw new Error(error)
  }

}

export const ProfileContext = createContext();

export const useProfileContext = () => {
  return useContext(ProfileContext);
}