import { doRequest } from "./utils";

export const getAllComments = async (id) => {
  let url = `/api/v1/comments/${id}`;
  let res;
  await doRequest(url, "get", null, null).then((data) => {
    if (data.status == 200 && data.data.comments) {
      res = data.data.comments;
    }
  });

  return res
};

export const deleteComment = async (id) => {
  let url = `/api/v1/comments/${id}`;
  let res;
  await doRequest(url, 'delete', null, null).then(data=>{
    res = data;
  })
  return res

};

export const createComment = async (commentData, id) => {
  let url = `/api/v1/comments/${id}`;
  let body = commentData;
  let res ;
  await doRequest(url, "post", body, null).then((data) => {
    res = data;
  });
  return res
};
