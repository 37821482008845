import { useCallback, useState } from 'react';
import axios from 'axios';
import { doRequest } from '../../Utils/utils';

const useRequest = (url, method) => {
  const [data, setData] = useState({});
  const [error, setError] = useState({ message: '', status: 0 });
  const [loading, setLoading] = useState(false);

  let cancelTokenSource = null;

  const sendRequest = useCallback(async (options = {
    body: null, queryParams: null, urlParams: null,
  }) => {
    setLoading(true);
    const response = {
      error: {
        message: '',
        status: 0,
      },
      data: {},
      successful: false,
    };

    let localURL = '';
    if (url instanceof Function) {
      localURL = url();
    } else {
      localURL = url;
    }
    if (options.urlParams) {
      Object.keys(options.urlParams).forEach((param) => {
        localURL = localURL.replace(`:${param}`, options.urlParams[param]);
      });
    }
    cancelTokenSource = axios.CancelToken.source();

    await doRequest(localURL, method, options.body, options.queryParams, cancelTokenSource)
      .then((resp) => {
        if (resp.status === 200 && resp.data) {
          response.data = resp.data;
          response.successful = true;
          response.error.message = '';
          response.error.status = 0;
        } else {
          response.error.message = resp.data.error || resp.data.message || resp.data.errors || 'There was an error completing a request.';
          response.error.status = resp.status;
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          response.error.message = 'Request was cancelled.';
          return;
        }
        response.error.message = err.response.data.error
          || err.response.data.message
          || err.response.data.errors
          || err.response.data;
        if (Array.isArray(response.error.message)) {
          response.error.message = response.error.message.join(', ');
        }
        if (!(typeof response.error.message === 'string')) {
          response.error.message = 'There was an error completing a request.';
        }
      })
      .finally(() => {
        setLoading(false);
        setData(response.data);
        setError(response.error);
        cancelTokenSource = null;
      });
    return response;
  }, [url]);

  const clearError = useCallback(() => {
    setError('');
  }, [url]);

  const cancel = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel();
    }
  };

  return {
    data, error, loading, sendRequest, clearError, cancel,
  };
};

export default useRequest;
