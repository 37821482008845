import _ from 'lodash';
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export type ToastType = 'success' | 'error' | 'warning' | 'info';
export type Toast = {
  type: ToastType,
  message: string,
  temporary?: boolean,
  temporaryDuration?: number,
  snoozeable?: boolean,
  snoozeDuration?: number,
  id?: string,
};

export type ToastContextType = {
  toasts: Array<Toast>,
  addToastNotification: (toast: Toast) => string,
  addPersistentToastNotification: (toast: Toast) => string,
  removeToastNotification: (id: string) => void,
  clearToastNotifications: () => void,
  clearTemporaryToastNotifications: () => void,
  updateToastNotification: (id: string, message: string, type: ToastType) => void,
};

const ToastContext = createContext<ToastContextType>(null as unknown as ToastContextType,);

export function ToastProvider({ children }: { children: React.ReactNode }) {
  const [toasts, setToasts] = useState(Array<Toast>);
  const [prevLocation, setPreviousLocation] = useState('');
  
  const location = useLocation();

  useEffect(() => {
    if (prevLocation !== location.pathname) {
      setPreviousLocation(location.pathname)
      clearTemporaryToastNotifications()
    }
  }, [location])

  function addPersistentToastNotification(toast: Toast): string {
    toast.temporary = false;
    return addToastNotification(toast);
  }

  function addToastNotification(toast: Toast): string {
    if (!toast.id) {
      toast.id = _.uniqueId('toast_');
    }
    console.log(toast)

    if (toast.temporary === undefined) {
      toast.temporary = true;
    }

    setToasts((prev) => [...prev, toast]);

    if (toast.temporary && toast.temporaryDuration) {
      setTimeout(() => {
        removeToastNotification(toast.id!);
      }, toast.temporaryDuration);
    }

    return toast.id;
  }

  function removeToastNotification(id: string) {
    setToasts((prev) => [...prev.filter((val) => val.id !== id)]);
  }

  function clearTemporaryToastNotifications() {
    setToasts((prev) => [...prev.filter((toast) => !toast.temporary)])
  }

  function clearToastNotifications() {
    setToasts([]);
  }

  function updateToastNotification(id: string, message: string, type: ToastType) {
    setToasts((prev) => [...prev.map((toast) => {
      if (toast.id === id) {
        toast.message = message;
        toast.type = type;
      }
      return toast;
    })]);
  }

  return (
    <ToastContext.Provider value={{
      toasts,
      addToastNotification,
      addPersistentToastNotification,
      removeToastNotification,
      clearToastNotifications,
      clearTemporaryToastNotifications,
      updateToastNotification,
    }}
    >
      {children}
    </ToastContext.Provider>
  );
}

export default ToastContext;
