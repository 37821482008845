import React from 'react';
import { NavLink } from 'react-router-dom';
import { getInitials } from '../../Utils/general';
import './user.css';
import { stringToColor, textBrightness } from '../../ActionItems/utils';

function UserAvatar({ userData }) {
  const { FirstName, LastName } = userData;

  const FULL_NAME = `${FirstName} ${LastName}`.toUpperCase();

  return (
    <div className="header-user">
      <NavLink
        className="header-profile"
        to="/"
        style={{
          backgroundColor: stringToColor(FULL_NAME),
          color: textBrightness(stringToColor(FULL_NAME)),
        }}
      >
        <p>{getInitials(`${FirstName} ${LastName}`)}</p>
      </NavLink>
    </div>
  );
}

export default UserAvatar;
