import { DropdownValue } from "./MultiDropdown";

export const SHORT_DROPDOWN_STYLE = {
  container: (base) => ({
    ...base,
    fontSize: '11px',
    zIndex: 10,
  }),
  option: (base) => ({
    ...base,
    color: '#666',
    // backgroundColor: state.isSelected ? 'rgba(0,126,255,.08)' : 'white',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    alignSelf: 'center',
    height: 15,
  }),
  input: (base) => ({
    ...base,
    height: 20,
    color: '#666',
    marginTop: -6,
  }),
  singleValue: (base) => ({
    ...base,
    color: '#666',
  }),
  valueContainer: (base) => ({
    ...base,
    height: 'max-content',
    padding: '0 8px',
  }),
  control: (base) => ({
    ...base,
    minHeight: 30,
    height: 'max-content',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#f96302',
    borderRadius: 0,
  }),
  indicatorsContainer: (base) => ({
    ...base,
    height: 25,
    alignSelf: 'center',
  }),
  menu: (base) => ({
    ...base,
    marginTop: 1,
    borderRadius: 0,
  }),
};

export function createDropdownValuesFromStrings(strings: string[]): DropdownValue[] {
  return strings.map((s) => ({ label: s, value: s.toLowerCase() }));
}