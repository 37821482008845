/* eslint-disable no-mixed-operators */
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
const PFP_COLOR_MAP = new Map();

export function stringToColor(str, bright = false) {
  if (PFP_COLOR_MAP.get(str)) {
    return PFP_COLOR_MAP.get(str);
  }
  let hash = 0;
  str.split('').forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let color = '#';
  const brightness = bright ? 0.95 : 1.5;
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += (Math.round(value / brightness)).toString(16).padStart(2, '0');
  }
  PFP_COLOR_MAP.set(str, color);
  return color;
}

export function textBrightness(hexValue) {
  const numericValue = parseInt(hexValue.split('#')[1], 16);
  const r = numericValue >> 16 & 0xFF;
  const g = numericValue >> 8 & 0xFF;
  const b = numericValue & 0xFF;
  const brightness = Math.round(
    ((parseInt(r, 10) * 299) + (parseInt(g, 10) * 587) + (parseInt(b, 10) * 114)) / 1000,
  );
  return (brightness > 125) ? 'black' : 'white';
}

export function canUserEditActionItem(email, actionItem) {
  // check if user is the creator of the BPM
  if (actionItem.BPM && actionItem.BPM.createdBy) {
    if (email.toLowerCase() === actionItem.BPM.createdBy.toLowerCase()) {
      return true;
    }
  }
  // check if user is the creator of the action item
  if (email.toLowerCase() === actionItem.created_by.toLowerCase()) return true;
  // check if user is the assignee of the action item
  if (email.toLowerCase() === actionItem.Assignee.Email.toLowerCase()) return true;
  // check if user is a collaborator of the BPM
  if (actionItem.BPM && actionItem.BPM.collaborators && actionItem.BPM.collaborators.length !== 0) {
    return actionItem.BPM.collaborators.some(
      (collab) => email.toLowerCase() === collab.User.Email.toLowerCase()
    );
  }
  return false;
}

export const STATUS_OPTIONS = [
  {
    value: 'Todo',
    label: 'Todo',
  }, {
    value: 'Complete',
    label: 'Complete',
  },
  {
    value: 'In Progress',
    label: 'In Progress',
  },
  {
    value: 'Blocked',
    label: 'Blocked',
  },
];

export const TYPE_OPTIONS = [
  {
    value: 'Prevent',
    label: 'Prevent',
  },
  {
    value: 'Mitigate',
    label: 'Mitigate',
  },
  {
    value: 'Process',
    label: 'Process',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];
