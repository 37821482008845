import React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { Box } from '../../Common/Box/Box';
import LoadingBubbles from '../../Common/LoadingBubbles';
import { doPostRequest } from '../../Utils/utils';

import styles from './Card.module.css'
import { MiniDate } from './MiniDate';
import { NavLink } from 'react-router-dom';
import { TimelineDates } from './TimelineDates';

export const IncidentCard = ({ number, onFailure }) => {

    const [isLoading, setIsLoading] = useState(true)
    const [incident, setIncident] = useState(null)

    const getIncidentInfo = useCallback(async (number) => {

        setIsLoading(true)

        const response = await doPostRequest("/api/v1/incidents/" + number, "get", null, { forcePull: true }, null)

        if (response.status === 200) {
            setIncident(response.data.incident)
            setIsLoading(false)
        } else {
            onFailure?.()
        }

    }, [setIncident, setIsLoading])

    useEffect(() => {
        getIncidentInfo(number)
    }, [number])

    return (
        <NavLink to={`/incident?id=${number}`} target="_blank" className={`${styles.changeCard}`} key={number}>
            <Box flex across aC jB>
                <Box flex across aC jS>
                    <h3>{number}</h3>
                    {!isLoading && <span style={{ marginLeft: 8, fontWeight: 300, textTransform: 'uppercase' }}>{incident.State}</span>}
                </Box>
                {!isLoading && <span>{incident.Team.Name}</span>}
            </Box>
            {isLoading ? <Box flex jC aC><LoadingBubbles /></Box> :
                <Box flex down jS aStr>
                    <Box flex across jB aC>
                        <span>{incident.MadeSLA ? "Meets SLA" : "Does Not Meet SLA"}</span>
                        <span>{incident.Impact}</span>
                    </Box>
                    <Box flex down jB aStr mT={16} mB={8}>
                        <TimelineDates events={{ "Opened": incident.Opened, "Start": incident.IncidentStartDateTime, "Closed": incident.Closed, "Restored": incident.Restored }} />
                    </Box>
                    <Box mT={8} mB={8} aS><b>{incident.ShortDescription}</b></Box>
                    <Box mB={8} aS><pre style={{ fontFamily: '"Open Sans", sans-serif', whiteSpace: 'pre-wrap' }}>{incident.Description}</pre></Box>
                </Box>
            }
        </NavLink>
    )
}