import React from 'react';
import { ChevronDownIcon } from 'lucide-react';
import { Table } from '@tanstack/react-table';
import DropdownMenu from '../dropdownMenu';
import { Button } from '../button';

type Props<TData> = {
  table: Table<TData>;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

function DataTableColumnVisibilityList<TData>({ table, ...props }: Props<TData>) {
  return (
    <DropdownMenu>
      <DropdownMenu.Trigger asChild>
        <Button variant="outline" className="ml-auto" {...props}>
          Columns <ChevronDownIcon className="h-4 w-4 text-primary" />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content align="end">
        {table
          .getAllColumns()
          .filter((column) => column.getCanHide())
          .map((column) => {
            return (
              <DropdownMenu.CheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onCheckedChange={(value) => column.toggleVisibility(!!value)}
              >
                {column.id}
              </DropdownMenu.CheckboxItem>
            );
          })}
      </DropdownMenu.Content>
    </DropdownMenu>
  );
}

export default DataTableColumnVisibilityList;
