import {
  ArrowDownIcon,
  ArrowUpIcon,
  CaretSortIcon,
  EraserIcon,
  EyeNoneIcon,
  MagnifyingGlassIcon,
} from '@radix-ui/react-icons';
import { Column } from '@tanstack/react-table';
import { cn } from '../../lib/utils';
import DropdownMenu from '../dropdownMenu';
import { Button } from '../button';
import { Input } from '../input';
import { useEffect, useRef } from 'react';
import { useBoolean, useIsMounted } from 'usehooks-ts';

interface DataTableColumnHeaderProps<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title: string;
  searchable?: boolean;
}

const SearchInput = ({ column }: { column: Column<any, any> }) => {
  const isMounted = useIsMounted();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isMounted()) {
      const timer = setTimeout(() => {
        inputRef.current?.focus();
      }, 0);

      return () => clearTimeout(timer);
    }
  }, [isMounted]);

  return (
    <>
      <Input
        value={(column.getFilterValue() as string) ?? ''}
        onChange={(e) => column.setFilterValue(e.target.value)}
        ref={inputRef}
        className="h-8"
        onClick={(e) => e.stopPropagation()}
        onBlur={() => inputRef.current?.focus()}
        onMouseMove={() => inputRef.current?.focus()}
      />
      {inputRef.current?.focus()}
    </>
  );
};

export default function DataTableColumnHeader<TData, TValue>({
  column,
  title,
  className,
  searchable = false,
}: DataTableColumnHeaderProps<TData, TValue>) {
  const isSearchOpen = useBoolean(false);

  if (!column.getCanSort() && !column.getCanFilter() && !column.getCanHide()) {
    return <div className={cn(className)}>{title}</div>;
  }

  function handleHide() {
    column.toggleVisibility(false);
    column.setFilterValue(undefined);
  }

  return (
    <div className={cn('flex items-center space-x-2', className)}>
      <DropdownMenu>
        <DropdownMenu.Trigger asChild>
          <Button variant="ghost" size="sm" className="-ml-3 h-8 data-[state=open]:bg-accent">
            <span>{title}</span>
            {column.getIsFiltered() && <MagnifyingGlassIcon className="ml-2 h-4 w-4 text-primary" />}
            {column.getIsSorted() === 'desc' ? (
              <ArrowDownIcon className="ml-2 h-4 w-4 text-primary" />
            ) : column.getIsSorted() === 'asc' ? (
              <ArrowUpIcon className="ml-2 h-4 w-4 text-primary" />
            ) : (
              <CaretSortIcon className="ml-2 h-4 w-4 text-primary" />
            )}
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content align="start">
          <DropdownMenu.Item onClick={() => column.toggleSorting(false)}>
            <ArrowUpIcon className="mr-2 h-3.5 w-3.5 text-primary" />
            Asc
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={() => column.toggleSorting(true)}>
            <ArrowDownIcon className="mr-2 h-3.5 w-3.5 text-primary" />
            Desc
          </DropdownMenu.Item>
          {column.getIsSorted() && (
            <DropdownMenu.Item onClick={() => column.clearSorting()}>
              <EraserIcon className="mr-2 h-3.5 w-3.5 text-primary" />
              Clear
            </DropdownMenu.Item>
          )}
          {column.getCanHide() && (
            <>
              <DropdownMenu.Separator />
              <DropdownMenu.Item onClick={handleHide}>
                <EyeNoneIcon className="mr-2 h-3.5 w-3.5 text-primary" />
                Hide
              </DropdownMenu.Item>
            </>
          )}
          {searchable && (
            <>
              <DropdownMenu.Separator />
              <DropdownMenu.Sub open={isSearchOpen.value} onOpenChange={isSearchOpen.setValue}>
                <DropdownMenu.SubTrigger>
                  <MagnifyingGlassIcon className="mr-2 h-3.5 w-3.5 text-primary" />
                  Search
                </DropdownMenu.SubTrigger>
                <DropdownMenu.Portal>
                  <DropdownMenu.SubContent>
                    <DropdownMenu.Item onClick={(e) => e.stopPropagation()}>
                      <SearchInput column={column} />
                    </DropdownMenu.Item>
                    <DropdownMenu.Separator />
                    <DropdownMenu.Item onClick={() => column.setFilterValue(undefined)}>
                      <EraserIcon className="mr-2 h-3.5 w-3.5 text-primary" />
                      Clear Search
                    </DropdownMenu.Item>
                  </DropdownMenu.SubContent>
                </DropdownMenu.Portal>
              </DropdownMenu.Sub>
            </>
          )}
        </DropdownMenu.Content>
      </DropdownMenu>
    </div>
  );
}
