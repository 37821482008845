import { Column } from '@tanstack/react-table';
import _ from 'lodash';
import React from 'react';
import { cn } from '../../lib/utils';
import { Button } from '../button';
import { XIcon } from 'lucide-react';

type Props<TData, TValue> = {
  column: Column<TData, TValue>;
  title: string;
};

function DataTableSearchSelectedFilters<TData, TValue>({ column, title }: Props<TData, TValue>) {
  if (!column || !_.isString(column?.getFilterValue()) || column.getFilterValue() === '') {
    return null;
  }
  return (
    <div className={cn('flex flex-col gap-0.5')}>
      <p className="text-sm font-semibold">{title}</p>
      <Button variant="outline" className="h-8 w-fit gap-2">
        <p>{column.getFilterValue() as string}</p>
        <XIcon className="h-4 w-4 text-primary" onClick={() => column.setFilterValue(undefined)} />
      </Button>
    </div>
  );
}

export default DataTableSearchSelectedFilters;
