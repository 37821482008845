import React from "react";
import { Box } from "../../Common/Box/Box";

import styles from "./MiniDate.module.css";

const MONTHS = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];
const DAYS = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

export const MiniDate = ({ date }) => {
  return (
    // <Box flex across aC jS style={{ fontSize: 10 }}>
    //     <Box flex down aE jC mR={1}>
    //         <Box>{DAYS[date.getDay()]}</Box>
    //         <Box>{date.getFullYear()}</Box>
    //     </Box>
    //     <Box flex down aC jC mL={1} mR={1}>
    //         <Box>{MONTHS[date.getMonth()]} </Box>
    //         <Box>{date.getDate()}</Box>
    //     </Box>
    //     <Box flex down aStr jC mL={1}>
    //         <Box flex across aC jS>
    //             <Box>{("00" + date.getHours() % 12).slice(-2)}:</Box>
    //             <Box>{("00" + date.getMinutes()).slice(-2)}</Box>

    //         </Box>
    //         <Box flex across aC jB>
    //             <Box>{("00" + date.getSeconds()).slice(-2)}</Box>
    //             <Box>{date.getHours() > 12 ? "PM" : "AM"}</Box>
    //         </Box>
    //     </Box>
    // </Box>
    <Box flex across aC jS style={{ fontSize: 12 }}>
      <Box flex down aStr jC>
        <Box className={styles.day}>{DAYS[date.getDay()]}</Box>
        <Box className={styles.month}>{MONTHS[date.getMonth()]} </Box>
        <Box className={styles.date}>{date.getDate()}</Box>
        <Box className={styles.year}>{date.getFullYear()}</Box>
      </Box>

      <Box flex down aStr jC mL={4}>
        <Box flex across aC jS>
          <Box className={styles.hours}>{("00" + (date.getHours() > 12 ? date.getHours() % 12 : date.getHours())).slice(-2)}:</Box>
          <Box className={styles.minutes}>{("00" + date.getMinutes()).slice(-2)}</Box>
        </Box>
        <Box flex across aC jC>
          {/* <Box className={styles.seconds}>{("00" + date.getSeconds()).slice(-2)}</Box> */}
          <Box className={styles.amPm}>{date.getHours() >= 12 ? "PM" : "AM"}</Box>
        </Box>
      </Box>
    </Box>
  );
};
