import React from 'react'

type SlackIconProps = {
  size: number
  greyScale?: boolean
}

function SlackIcon({ size, greyScale }: SlackIconProps) {
  return (
    <div style={{ width: size, height: size }}>
      <img
        src={require("../../../img/Slack-mark.svg")}
        alt="Slack Logo"
        style={{ width: size * 2, margin: '-50%', filter: greyScale ? 'grayscale(100%)' : 'none' }}
      />
    </div>
  )
}

export default SlackIcon