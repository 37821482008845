// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-wrapper {
    position: relative;
}

.tooltip-target {
    cursor: help;
}

.tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 0.5rem;
    font-size: 0.8rem;
    border-radius: 4px;
    /* white-space: normal; */
    z-index: 9999;
    opacity: 0.9;
    flex-wrap: wrap;
}

.tooltip::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #333 transparent;
    pointer-events: none;
    z-index: 9999;
}`, "",{"version":3,"sources":["webpack://./src/recker/Common/Tooltip/tooltip.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,2BAA2B;IAC3B,sBAAsB;IACtB,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;IACzB,aAAa;IACb,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,SAAS;IACT,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;IACnB,sDAAsD;IACtD,oBAAoB;IACpB,aAAa;AACjB","sourcesContent":[".tooltip-wrapper {\n    position: relative;\n}\n\n.tooltip-target {\n    cursor: help;\n}\n\n.tooltip {\n    position: absolute;\n    top: 100%;\n    left: 50%;\n    transform: translateX(-50%);\n    background-color: #333;\n    color: #fff;\n    padding: 0.5rem;\n    font-size: 0.8rem;\n    border-radius: 4px;\n    /* white-space: normal; */\n    z-index: 9999;\n    opacity: 0.9;\n    flex-wrap: wrap;\n}\n\n.tooltip::before {\n    content: \"\";\n    position: absolute;\n    bottom: 100%;\n    left: 50%;\n    margin-left: -5px;\n    border-width: 5px;\n    border-style: solid;\n    border-color: transparent transparent #333 transparent;\n    pointer-events: none;\n    z-index: 9999;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
