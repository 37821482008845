import Select from 'react-select'
import React, { Component } from 'react'

let styles = {
  option: (base, state) => ({
    ...base,
    color: '#666',
    // backgroundColor: state.isSelected ? 'rgba(0,126,255,.08)' : 'white',
  }),
  indicatorSeparator: base => ({
    ...base,
    width: 0,
  }),
  input: (base) => ({
    ...base,
    marginTop:-3,
    height:30,
    color: '#666',
  }),
  singleValue: (base) => ({
    ...base,
    color: '#666',
  }),
  valueContainer:base => ({
    ...base,
    height:30,
    marginTop: '-4px',
  }),
  control: (base, state) => ({
    ...base,
    // backgroundColor: state.isDisabled ? '#f9f9f9' : 'white',
    minHeight: 30,
    height:30,
    color:'#666',
    borderRadius: '.25em',
    borderWidth: .5,
    borderColor: 'rgb(204, 204, 204)',
    boxShadow: '0 0 0 0 #2684F3',
  }),
  dropdownIndicator: (base, state) => {
    if (state.isDisabled) {
      return ({
        display: 'none'
      })
    } else {
      return ({
        ...base,
        color: '#f96302',
        borderRadius: 0,
      })
    }
  },
  indicatorsContainer: base=>({
    ...base,
    height:30,
  }),
  menu: base => ({
    ...base,
    marginTop: -4,
    borderRadius: 0,
  }),
};
let noOptions="testing123"

function CustSelect({
  options,
  noOptionsMessage,
  value,
  disabled,
  dropdownCallback,
  placeholder,
  onInputChange
}) {
  return (
    <Select
      options={options}
      styles={styles}
      noOptionsMessage={() => noOptionsMessage}
      isDisabled={disabled}
      value={value}
      onChange={dropdownCallback}
      onInputChange={onInputChange}
      placeholder={placeholder}
    />
  )
}

export default CustSelect
