// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider {
    width: 100%;
    height: 2px;
    position: relative;
    display: flex;
}

.divider-orange {
    flex: 1;
    background-color: #f96302;
    height: 100%;
    display: inline-block;
}

.divider-grey {
    flex: 3;
    background-color: lightgrey;
    height: 100%;
    display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/recker/Common/Divider/divider.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,OAAO;IACP,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,OAAO;IACP,2BAA2B;IAC3B,YAAY;IACZ,qBAAqB;AACzB","sourcesContent":[".divider {\n    width: 100%;\n    height: 2px;\n    position: relative;\n    display: flex;\n}\n\n.divider-orange {\n    flex: 1;\n    background-color: #f96302;\n    height: 100%;\n    display: inline-block;\n}\n\n.divider-grey {\n    flex: 3;\n    background-color: lightgrey;\n    height: 100%;\n    display: inline-block;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
