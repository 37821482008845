// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-profile {
    display: flex;
    border: 1px solid white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.header-user{
    display: flex;
    align-items: center;
    justify-content: center;
}
.header-user a:hover{
    /* box-shadow: ; */
    border: 1px solid #d46c3d;
    color: #d46c3d;
}`, "",{"version":3,"sources":["webpack://./src/recker/Common/User/user.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":[".header-profile {\n    display: flex;\n    border: 1px solid white;\n    width: 30px;\n    height: 30px;\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: white;\n}\n.header-user{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.header-user a:hover{\n    /* box-shadow: ; */\n    border: 1px solid #d46c3d;\n    color: #d46c3d;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
