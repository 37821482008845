import React, { useCallback, useEffect, useState } from 'react';
import { doRequest } from '../../Utils/utils';
import DataTable from '../../ui/dataTable/datatable';
import { TestRunRecord, Columns } from './TestRunRecordType';
import Card from '../../ui/card';
import LoadingBubbles from '../../Common/LoadingBubbles';
import TestRunRecordsDataTable from './TestRunRecordsDataTable';

function TestRunRecordsList() {
  // Set state for component
  const [records, setRecords] = useState<TestRunRecord[]>([]);
  const [loading, setLoading] = useState(false);

  // Fetch Decision Records from API
  useEffect(() => {
    setLoading(true);
    doRequest('/api/v1/testrunrecords', 'get').then((data) => {
      setLoading(false);
      setRecords(data.data.records);
    }).catch((err) => {
      console.error(err)
    });
  }, []);

  return (
    <Card className="m-auto max-w-screen-xl">
      <Card.Header>
        <Card.Title>All Test Run Records</Card.Title>
      </Card.Header>
      <Card.Content className="flex justify-center">
        {loading ? <LoadingBubbles size={4} /> : <TestRunRecordsDataTable data={records} />}
      </Card.Content>
    </Card>
  );
}

export default TestRunRecordsList;
