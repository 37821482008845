import React, { useContext, useEffect } from 'react'
import Toast from './Toast'
import ToastContext from './contexts/ToastContext'

function ToastContainer() {
  const { toasts, removeToastNotification, clearToastNotifications } = useContext(ToastContext);

  const closeAllBar = () => (
    <div className="toast">
      <div style={{ padding: 10, display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        <div style={{ fontWeight: 'bold' }}>
          Notifications:
        </div>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => clearToastNotifications()}
        >
          Close all
          <i style={{ marginLeft: 5 }} className='icon_close' />
        </div>
      </div>
    </div>
  )

  return (
    <div className='toast-container'>
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          onClose={() => removeToastNotification(toast)}
          type={toast.type}
          id={toast.id}
          snoozeable={toast.snoozeable}
          snoozeDuration={toast.snoozeDuration}
        >
          {toast.message}
        </Toast>
      ))}
      {toasts.length > 1 &&
        closeAllBar()
      }
    </div>
  )
}

export default ToastContainer