import { Box } from '@one-thd/retooling-react';
import React, {useMemo} from 'react';
import { MiniDate } from './MiniDate';


export const TimelineDates = ({ events }) => {

    return(
        <Box down aStr jC>
            <Box flex across jB>
                {
                    Object.keys(events).filter((event) => !isNaN(Date.parse(events[event]))).sort((eventA, eventB) => (Date.parse(events[eventA]) > Date.parse(events[eventB]) ? 1 : -1)).map((event) => (
                        <Box flex down aC>
                            <MiniDate date={new Date(events[event])} />
                            <span style={{ fontSize: 8, textTransform: 'uppercase', marginTop: 4 }}>{event}</span>
                            <div style={{position: 'relative'}}>
                                <span style={{position: 'absolute', bottom: -18, transform: 'translateX(-50%)'}}>⬤</span>
                            </div>
                        </Box>
                    ))
                }
            </Box>
            <Box flex style={{marginTop: 8, marginBottom: 8, borderTop: '2px solid #999'}} />
        </Box>
    )

}