import React from 'react';
import { NavLink } from 'react-router-dom';

import style from './ChangeResult.module.css'
import { DecisionRecord } from '../../Records/DecisionRecords/DecisionRecordType';

type DecisionRecordResultProps = {
  record: DecisionRecord;
  idx: number;
  query: string;
}

export default function DecisionRecordResult({ record, idx, query }: DecisionRecordResultProps) {
  let matchingText: any = []
  const regEscape:any = (v: any) => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

  const findMatchingText = (key: string, text: string) => {
    if (new RegExp(query, "i").test(text)) {
      const pos = text.indexOf(query)
      const matchText = text.split(new RegExp(query, "ig"))
      matchingText.push({field: key, text: (
        <div>
          {matchText.map((m, i) => {
            return <>{m} {i < matchText.length - 1 ? <span className={style.matchHighlight}>{query}</span> : null}</>
          })}
        </div>
      )})
    }
  }

  if (query && query.length > 3) {
    findMatchingText('Thesis', record.Thesis)
    findMatchingText('Test Description', record.TestDescription)
    findMatchingText('Results', record.ActualResults)
    findMatchingText('Record Number', record.RecordNumber)
    findMatchingText('Identified Risks & Mitigation', record.IdentifiedRisksAndMitigation)
    findMatchingText('Impacted Services', record.ImpactedServices)
  }

  return (
        <NavLink to={`/decisionrecords/${record.RecordNumber}`} target="_blank" className={`${style.changeResult} ${idx % 2 == 0 ? style.even : ''}`} key={record.ID}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                <i style={{ margin: 4 }} className="icon_assignment" />
                <div style={{ width: 128, display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'space-between', marginRight: 8, flexShrink: 0 }}>
                    <b>{record.RecordNumber}</b>
                    <span>{record.Status}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'space-between' }}>
                    {matchingText.map(({field, text}: {field: string, text: string}) => {
                      return (
                        <div key={`match-${field}`}>
                          <div className={style.matchField}>{field}</div>
                          <div className={style.matchText}>{text}</div>
                        </div>
                      )
                    })}
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'space-between', flexShrink: 0 }}>
                {/* <div style={{ marginBottom: 4 }}>{change.AssignedTo.FirstName} {change.AssignedTo.LastName}</div> */}
                <div className='resultSubexperienceLabel'>
                    {/* <i className='icon_information-outlined' onMouseEnter={() => ReactTooltip.rebuild()} data-tip={change.Subexperience.ExperienceProjectId.Experience.Name} /> */}
                    <span>{record.Application.LifecyclePhase}</span>
                </div>
            </div>
        </NavLink>
    )
}