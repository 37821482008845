import React from 'react';
import { DownloadIcon, FileIcon, XCircleIcon } from 'lucide-react';
import { cn } from '../../lib/utils';
import { Button } from '../../ui/button';

type AttachmentItemProps = {
  attachment: File;
  canEdit: boolean;
  handleRemoveAttachment: (attachment: File) => void;
}

export default function AttachmentItem({ attachment, canEdit, handleRemoveAttachment }: AttachmentItemProps) {
  return (
    <div key={attachment.name} className={cn('border-2 border-border border-dashed rounded-lg w-40 h-40 text-muted-foreground transition-colors')}>
      { canEdit && (
        <div className="relative w-full h-fit z-10">
          <a href={URL.createObjectURL(attachment)} download={attachment.name} aria-label={`download ${attachment.name}`}>
            <Button variant="ghost" size="icon" className="absolute top-0 left-0">
              <DownloadIcon className="cursor-pointer text-primary" />
            </Button>
          </a>
          <Button variant="ghost" size="icon" className="absolute top-0 right-0">
            <XCircleIcon className="cursor-pointer text-destructive" onClick={() => handleRemoveAttachment(attachment)} />
          </Button>
        </div>
      )}
      <a href={URL.createObjectURL(attachment)} download={attachment.name}>
        <div className="relative w-full h-full gap-10 overflow-hidden">
          { attachment.type && attachment.type.startsWith('image') ? (
            <img
              className="max-h-20 absolute left-1/2 top-14 -translate-x-1/2 -translate-y-1/2"
              src={URL.createObjectURL(attachment)}
              alt={attachment.name}
            />
          )
            : (
              <div className="absolute left-1/2 top-14 -translate-x-1/2 -translate-y-1/2">
                <FileIcon strokeWidth={1} className="size-20 font-extralight absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
                <p className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-xs text-[0.5rem] font-bold">
                  {attachment.type && attachment.type.includes(';') ? attachment.type.split('/')[1].split(';')[0].toLocaleUpperCase() : attachment.type.split('/')[1].toLocaleUpperCase()}
                </p>
              </div>
            )}
          <span className="absolute top-24 text-base text-center break-all">{attachment.name.length < 25 ? attachment.name : `${attachment.name.substring(0, 25)}...`}</span>
        </div>
      </a>
    </div>
  )
}
