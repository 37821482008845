// Comments 
export const getInitials = (name) => {
    let initials = "";
    let arr = name.split(" ");
    arr.map((item) => {
      initials = initials + item.charAt(0);
    });
    return initials;
  };

  export const convertTimeToString = (date) => {
    if (!date) {
      return "N/A";
    }

    const dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) {
      return "N/A";
    }

    if (dateObj.getTime() < 0) {
      return "N/A";
    }
    
    return (
      dateObj.toLocaleString("en-US", {
        dateStyle: "medium",
        timeStyle: "medium",
        timeZone: "America/New_York",
        hour12: false,
      }) + " ET"
    );
  };

  export const formatEmailToName = (email) => {
    if (!email) return 'N/A';
    const nameArr = email.split('@')[0].split('_');
    return nameArr.map((name) => name.charAt(0).toUpperCase() + name.slice(1)).join(' ').trim();
  }


  export const guid = () => {
    let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return (
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4()
    );
  };
  
  export const sortByDate = (list=[], key) =>{
    let sorted =  list.sort((a,b)=>{
       return new Date(b[key]) - new Date(a[key]);
     })
   return sorted
   }
   
   export const  convertToDateOrVersion =(version)=>{
    if (!version) {
      return "Not Available"
    }
    if (!version.toLowerCase().includes("v")) {
      return new Date(version).toLocaleString("en-US", {
        timeZone: 'America/New_York',
        dateStyle: 'long',
        timeStyle: 'long'
      })
    }
    return version
  }