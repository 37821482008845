import useRequest from '../../Common/Hooks/useRequest';
import CustSelect from '../../Common/CustSelect'
import React, { useState, useContext, useEffect } from "react";
import useNotification from '../../Common/Hooks/useNotification';
import { add } from 'lodash';

let timeout;
export default function UserSelector({
  showManagement,
  users,
  addUserCallback,
  removeUserCallback
}) {
  const [dropdownUsers, setUsers] = useState([])
  const [ddSelection, setddSelection] = useState(null)
  const getUsersFromInput = useRequest('/api/v1/search/users', 'GET');
  const [dropdownMsg, setDropdownMsg] = useState('Start typing a name...')

  const notifications = useNotification();

  const getUsers = (e) => {
    getUsersFromInput.sendRequest({ queryParams: { q: e } })
      .then((resp) => {
        if (resp.successful) {
          let allUserDataArr = []
          const usersArr = []
          if (resp.data.match.usersByLN) {
            allUserDataArr = allUserDataArr.concat(resp.data.match.usersByLN)
          }
          if (resp.data.match.usersByFN) {
            allUserDataArr = allUserDataArr.concat(resp.data.match.usersByFN)
          }
          if (resp.data.match.usersByLDap) {
            allUserDataArr = allUserDataArr.concat(resp.data.match.usersByLDap)
          }
          if (allUserDataArr.length !== 0) {
            allUserDataArr = [...new Set(allUserDataArr)]
          }
          allUserDataArr.map((user) => {
            const temp = {}
            const collaboratorExists = users.filter((alreadyExistingCollab) => alreadyExistingCollab.userId === user.UserId);
            if (collaboratorExists.length === 0) {
              temp.value = user.NameId;
              temp.label = user.NameId;
              temp.userId = user.UserId
              usersArr.push(temp)
            }
          })
          if (usersArr.length === 0) {
            setDropdownMsg('No matching users')
          }

          setUsers(usersArr)
          // setLocalActionItem(resp.data.action_item);
        }
      });
  }

  const getUsersInput = (e) => {
    if (e !== '') {
      setDropdownMsg('Loading....')

      // this.setState({noOptionsMessageUsers:'Loading...'})
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        getUsers(e)
        clearTimeout(timeout);
      }, 1000);
    }
  }

  const addCollaborator = (value) => {
    addUserCallback(value.userId)
  };

  const removeCollaborator = (value) => {
    removeUserCallback(value.LDAP)
  };

  return (
    <div>
      <div style={{ width: '250px' }}>
        {showManagement
          ? (
            <CustSelect
            //  showError={}
              placeholder="Select..."
              options={dropdownUsers}
              onInputChange={(e) => {
                getUsersInput(e);
              }}
              noOptionsMessage={dropdownMsg}
              value={
              ddSelection
            }
              dropdownCallback={(e) => {
                addCollaborator(e);
              }}
            />
          )
          : null}
      </div>
      <div>
        {users.map((user) => (
          <div>
            {`${user.FirstName} ${user.LastName}`}
            {showManagement === true
              ? <i className="icon_hightlight-off" style={{ color: '#f96302' }} onClick={() => { removeCollaborator(user) }} /> : null}
          </div>
        ))}
      </div>

    </div>
  );
}
