import React, { useEffect, useState } from 'react';
import { doRequest } from '../../Utils/utils';
import { DecisionRecord } from './DecisionRecordType';
import Card from '../../ui/card';
import LoadingBubbles from '../../Common/LoadingBubbles';
import DecisionRecordsDataTable from './DecisionRecordsDataTable';

function DecisionRecordsList() {
  // Set state for component
  const [decisionRecords, setDecisionRecords] = useState<DecisionRecord[]>([]);
  const [loading, setLoading] = useState(false);

  // Fetch Decision Records from API
  useEffect(() => {
    setLoading(true);
    doRequest('/api/v1/decisionrecords', 'get').then((data) => {
      setLoading(false);
      setDecisionRecords(data.data.records);
    }).catch((err) => {
      console.error(err)
    });
  }, []);

  return (
    <Card className="m-auto max-w-screen-2xl">
      <Card.Header>
        <Card.Title>All Decision Records</Card.Title>
      </Card.Header>
      <Card.Content className="flex justify-center">
        {loading ? (
          <LoadingBubbles size={4} />
        ) : (
          <DecisionRecordsDataTable
            data={decisionRecords}
            hiddenColumns={['Description', 'Manager', 'VP', 'Director', 'Number', 'TestTool']}
          />
        )}
      </Card.Content>
    </Card>
  );
}

export default DecisionRecordsList;
