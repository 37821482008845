import React, {useCallback } from 'react'

import styles from '../Search.module.css'

const TypeFilter = ({types, value: activeFilters, onFiltersChanged}) => {

    const onFilterClicked = useCallback((type) => {
        if (type == "clear") return onFiltersChanged({})
        const newFilters = { ...activeFilters, [type]: !activeFilters[type] }
        if(Object.keys(newFilters).length > 0 && !Object.keys(newFilters).reduce((anyActive, filter) => anyActive || newFilters[filter], false)) return onFiltersChanged({})
        onFiltersChanged(newFilters)
    }, [activeFilters, onFiltersChanged])

    return (
        <div>
            <div style={{ paddingLeft: 18, marginTop: 16, marginBottom: 4, fontWeight: 300 }}>Filter by Type: </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 4, paddingLeft: 18 }}>
                {
                    types.map(label => {

                        const type = label.toLowerCase()

                        return (
                            <div
                                className={`${styles.typeFilter} ${activeFilters[type] ? styles.active : ''}`}
                                onClick={() => onFilterClicked(type)}
                                key={type}
                            >
                                {label}
                            </div>
                        )
                    })
                }
                {(Object.keys(activeFilters).length > 0 && Object.keys(activeFilters).reduce((anyActive, filter) => anyActive || activeFilters[filter], false)) && <i className='icon_close' style={{ color: '#999', marginLeft: 4 }} onClick={() => onFilterClicked('clear')} />}
            </div>
        </div>
    )
}

export default TypeFilter