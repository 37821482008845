import React, { useState } from 'react';
import './tooltip.css';
import { cn } from '../../lib/utils';

function Tooltip({ children, tip, style = {}, timeoutSeconds, className = '' }) {
  const [show, setShow] = useState(false);
  let timeout = null;

  const handleMouseEnter = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    setShow(true);
  };

  const handleMouseLeave = () => {
    timeout = setTimeout(() => {
      setShow(false);
    }, timeoutSeconds || 0);
  };

  return (
    <div className={cn('tooltip-wrapper', className)}>
      <div
        className="tooltip-target"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
      {show
        && (
        <div
          style={style}
          className="tooltip"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {tip}
        </div>
        )}
    </div>
  );
}

export default Tooltip;
