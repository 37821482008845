import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { Box } from '@one-thd/retooling-react';
import LoadingBubbles from '../Common/LoadingBubbles';
import { doPostRequest, getReinURLFor } from '../Utils/utils';
import ActionableItems from './Searches/ActionableItems';

import ReactTooltip from 'react-tooltip';

import styles from './Search.module.css'
import { ChangeCard } from './Common/ChangeCard';
import {IncidentCard} from './Common/IncidentCard'
import ChangesSearch from './Searches/ChangesSearch';
import IncidentsSearch from './Searches/IncidentsSearch';
import TypeFilter from './Common/TypeFilter';
import BPMsSearch from './Searches/BPMsSearch';
import DecisionRecordsSearch from './Searches/DecisionRecordsSearch';

const chgRegex = /^CHG[0-9]{7}$/i;
const incRegex = /^INC[0-9]{8}$/i;

const Search = (props) => {

    const [searchInput, setSearchInput] = useState("")
    
    const [hideResults, setHideResults] = useState(false);
    const [showChangeResult, setShowChangeResult] = useState(false);
    const [showIncidentResult, setShowIncidentResult] = useState(false);
    const [activeFilters, setActiveFilters] = useState({})

    const inputRef = useRef(null)

    const onInputChange = useCallback(async (e) => {

        setSearchInput(e.target.value)
        
        setHideResults(chgRegex.test(e.target.value) || incRegex.test(e.target.value))
        setShowChangeResult(chgRegex.test(e.target.value))
        setShowIncidentResult(incRegex.test(e.target.value))
    }, [setSearchInput, setHideResults, setShowChangeResult, setShowIncidentResult])

    const handleKeydown = useCallback((e) => {
        if (e.keyCode === 27) {
            e.stopPropagation()
            if (searchInput.length > 0) {
                onInputChange({target: {value: ""}})
            } else if (searchInput.length == 0) {
                props.closeSearch()
            }
        }
        if ((e.ctrlKey || e.metaKey) && e.key == "f") {
            e.stopPropagation()
            props.closeSearch()
        }
        if (e.keyCode === 13) {
            if (hideResults) {
                e.stopPropagation()
                window.location = getReinURLFor(searchInput)
            }
        }
    }, [searchInput, onInputChange, hideResults])

    const handleFiltersChanged = (filters) => {
        setActiveFilters(filters)
    }

    const handleSingleCardFailure = useCallback(() => {
        setShowChangeResult(false)
        setShowIncidentResult(false)
        setHideResults(false)
    }, [setShowChangeResult, setShowIncidentResult, setHideResults])

    useEffect(() => {
        inputRef.current.focus()
    }, [])

    const filtersActive = useMemo(() => (Object.keys(activeFilters).length > 0), [activeFilters])

    return (
        <div id="search" className={styles.searchContainer} onClick={(e) => e.target.id == "search" ? props.closeSearch() : null}>
            <div className={styles.searchForm}>
                
                <div className={styles.searchInputContainer}>
                    <i style={{ marginRight: 8 }} className='icon_search' />
                    <input ref={inputRef} placeholder='Search / Go To' value={searchInput} onChange={onInputChange} onKeyDown={handleKeydown} />
                    {
                        hideResults && 
                        <>
                        <Box down aC jC>
                            <span className={styles.shortcutKey}>⏎</span>
                            <span style={{fontSize: 8}}>open</span>
                        </Box>
                        <span>&nbsp;&nbsp;</span>
                        </>
                    }
                    <Box down aC jC>
                        <Box across aC>
                            <span className={styles.shortcutKey}>⌘</span>
                            <span className={styles.shortcutKey}>F</span>
                        </Box>
                        <span style={{fontSize: 8}}>in page</span>
                    </Box>
                    <span>&nbsp;&nbsp;</span>
                    <Box down aC jC>
                        <Box across aC>
                            <span className={styles.shortcutKey}>Esc</span>
                        </Box>
                        <span style={{fontSize: 8}}>close</span>
                    </Box>
                    <span className={styles.searchClearIcon} onClick={(e) => {props.closeSearch()}}>X</span>
                </div>
                
                { showChangeResult && <ChangeCard number={searchInput} onFailure={handleSingleCardFailure} /> }
                { showIncidentResult && <IncidentCard number={searchInput} onFailure={handleSingleCardFailure} />}

                <div className={`${styles.searchResultsContainer} ${hideResults ? styles.hidden : ''}`}>
                    <TypeFilter types={["Decision Records"]} value={activeFilters} onFiltersChanged={handleFiltersChanged} />
                    {(!filtersActive || activeFilters.changes) && <DecisionRecordsSearch query={searchInput} />}
                </div>

            </div>
            <ReactTooltip className='tooltipStyling' style={{ opacity: 1 }} />
        </div>
    )
}

export default Search;