import { Client } from '@one-thd/retooling-react';
import { useState } from "react";
import { doRequest } from "../../Utils/utils";

type Attachment = {
  name: string;
  url: string;
}

type ExtractParams<S extends string> = S extends `${infer _Start}:${infer Param}/${infer Rest}`
  ? Param | ExtractParams<`/${Rest}`>
  : S extends `${infer _Start}:${infer Param}`
  ? Param
  : never;

function useAttachment<G extends string, P extends string, D extends string>(urls: {
  GET?: G, POST?: P, DELETE?: D
}) {
  type GetURLParams = ExtractParams<G>;
  type PostURLParams = ExtractParams<P>;
  type DeleteURLParams = ExtractParams<D>;

  type RouteParams<T extends string> = {
    [P in T]: string;
  };

  const [attachment, setAttachment] = useState<Attachment | null>(null);

  const generateUrl = <T extends string>(pattern: string, params: RouteParams<T>): string => {
    return pattern.replace(/:[a-zA-Z]+/g, (match) => {
      const key = match.slice(1);
      return params[key];
    });
  }

  const upload = async (params: RouteParams<PostURLParams>, file: File): Promise<void> => {
    if (!urls.POST) {
      throw new Error('POST URL is not defined');
    }

    const url = generateUrl<PostURLParams>(urls.POST, params)

    const formData = new FormData();
    formData.append('file', file);

    return doRequest(url, 'POST', formData)
  }

  const deleteFile = async (params: RouteParams<DeleteURLParams>): Promise<void> => {
    if (!urls.DELETE) {
      throw new Error('DELETE URL is not defined');
    }

    const url = generateUrl<DeleteURLParams>(urls.DELETE, params);

    return doRequest(url, 'DELETE');
  }

  const load = async(params: RouteParams<GetURLParams>): Promise<Attachment> => {
    if (!urls.GET) {
      throw new Error('GET URL is not defined');
    }

    const url = generateUrl<GetURLParams>(urls.GET, params);

    const attachment: Attachment = {
      name: '',
      url: '',
    }

    attachment.url = await Client.request({
      url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      return URL.createObjectURL(response.data);
    });

    return attachment;
  }

  return { upload, load, delete: deleteFile, attachment };
}

export default useAttachment;