import React from "react";

import { Box } from "@one-thd/retooling-react";

export const PersonChip = ({ person, label, right }) => {
  return (
    <Box down aE={right} aS={!right} jC mB={8}>
      <span style={{ backgroundColor: "#efefef", borderRadius: 8, padding: 4, minWidth: 64 }}>
        <i className="icon_person" /> {person && person.FirstName}{" "}
        {person && person.LastName}
      </span>
      <span style={{ fontSize: 8 }}>{label}</span>
    </Box>
  );
};
