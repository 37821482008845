import React from 'react'
import { formatDistanceToNow } from 'date-fns';

function Overdue({ due_by }: { due_by: string  }) {
  const now = new Date();
  const target = new Date(due_by);

  const overdue = now.getTime() - target.getTime();
  const difference = formatDistanceToNow(target, { addSuffix: true });

  return (
    <div style={{ color: overdue < 0 ? 'blue' : 'red' }}>
      {`Due ${difference}`}
    </div>
  );
}

export default Overdue