import React from 'react';
import ReactLoading from 'react-loading';

function LoadingBubbles({ size }) {
  return (
    <div>
      <ReactLoading
        type="bubbles"
        color="#666666"
        height={size == 'xs' ? 16 : size == 'sm' ? 32 : 100}
        width={size == 'xs' ? 16 : size == 'sm' ? 32 : 100}
      />
    </div>
  );
}

export default LoadingBubbles;
