import React, { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import Form, { FormEntry } from '../../ui/form';
import Select from '../../ui/select';
import { Input } from '../../ui/input';
import { Textarea } from '../../ui/textarea';
import { Button } from '../../ui/button';
import { CalendarIcon } from 'lucide-react';
import { Calendar } from '../../ui/calendar';
import Popover from '../../ui/popover';
import { cn } from '../../lib/utils';
import { format } from 'date-fns';
import Tooltip from '../../Common/Tooltip/Tooltip.jsx';
import { on } from 'events';
import { doRequest } from '../../Utils/utils';

type PreTestingStepProps = {
  form: UseFormReturn;
  testTool: string;
  onTestToolChange: (testTool: string) => void;
}

export default function PreTestingStep({ form, testTool, onTestToolChange }: PreTestingStepProps) {
  const [testTools, setTestTools] = React.useState<string[]>([]);

  useEffect(() => {
    doRequest('/api/v1/testtools', 'get', null, null)
      .then(({ data }) => {
        setTestTools(data.map((tool: any) => tool.name))
      })
  }, [])

  return (
    <div>
      <Form.Field
        control={form.control}
        name="TestName"
        rules={{ required: 'Test Name is required' }}
        render={({ field }) => (
          <Form.Item className="flex justify-between align-baseline">
            <Form.Label className="w-1/3 mt-4 flex-wrap justify-start">
              <p className="mr-2">
                Test Name
                <span className="text-primary"> *</span>
              </p>
              <Form.Message className="w-full mt-1" />
            </Form.Label>
            <Form.Control>
              <Input {...field} className="w-2/3" />
            </Form.Control>
          </Form.Item>
        )}
      />
      <Form.Field
        control={form.control}
        name="TestTool"
        rules={{ required: 'Test Tool is required' }}
        render={({ field }) => (
          <Form.Item className="flex justify-between align-baseline">
            <Form.Label className="w-1/3 mt-4 flex-wrap justify-start">
              <p className="mr-2">
                Test Tool
                <span className="text-primary"> *</span>
              </p>
              <Tooltip
                tip="What tool was used to perform this test? If a tool is not listed, please reach out in the #recker slack channel to request it be added."
                className="font-normal text-primary h-fit"
                style={{ width: 200, marginTop: 5, fontWeight: 'normal' }}
                timeoutSeconds={5}
              >
                <i
                  className="icon_help-outlined h-fit"
                  style={{ fontSize: 18, cursor: 'help' }}
                />
              </Tooltip>
              <Form.Message className="w-full mt-1" />
            </Form.Label>
            <Form.Control>
              <Select
                onValueChange={(v) => {
                  field.onChange(v)
                  onTestToolChange(v)
                }}
                defaultValue={field.value}
              >
                <Select.Trigger className="w-2/3">
                  <Select.Value placeholder={!testTools ? 'Loading Test Tools...' : 'Please select Test Tool...'} />
                </Select.Trigger>
                <Select.Content>
                  {testTools.map((tool) => (
                    <Select.Item key={tool} value={tool}>{tool}</Select.Item>
                  ))}
                </Select.Content>
              </Select>
            </Form.Control>
          </Form.Item>
        )}
      />
      <Form.Field
        control={form.control}
        name="Thesis"
        rules={{ required: 'Test Thesis is required' }}
        render={({ field }) => (
          <Form.Item className="flex justify-between align-baseline">
            <Form.Label className="w-1/3 mt-4 flex-wrap justify-start">
              <p className="mr-2">
                Test Thesis
                <span className="text-primary"> *</span>
              </p>
              <Tooltip
                tip="Why the test is being run. What are the expected results?"
                className="font-normal text-primary h-fit"
                style={{ width: 200, marginTop: 5, fontWeight: 'normal' }}
                timeoutSeconds={5}
              >
                <i
                  className="icon_help-outlined"
                  style={{ fontSize: 18, cursor: 'help' }}
                />
              </Tooltip>
              <Form.Message className="w-full mt-1" />
            </Form.Label>
            <Form.Control>
              <Textarea {...field} className="w-2/3" />
            </Form.Control>
          </Form.Item>
        )}
      />
      <Form.Field
        control={form.control}
        name="TestDescription"
        rules={{ required: 'Test Description is required' }}
        render={({ field }) => (
          <Form.Item className="flex justify-between align-baseline">
            <Form.Label className="w-1/3 mt-4 flex-wrap justify-start">
              <p className="mr-2">
                Test Description
                <span className="text-primary"> *</span>
              </p>
              <Tooltip
                tip="Describe high level on what you are doing with the test."
                className="font-normal text-primary h-fit"
                style={{ width: 200, marginTop: 5, fontWeight: 'normal' }}
                timeoutSeconds={5}
              >
                <i
                  className="icon_help-outlined"
                  style={{ fontSize: 18, cursor: 'help' }}
                />
              </Tooltip>
              <Form.Message className="w-full mt-1" />
            </Form.Label>
            <Form.Control>
              <Textarea {...field} className="w-2/3" />
            </Form.Control>
          </Form.Item>
        )}
      />
      <Form.Field
        control={form.control}
        name="IdentifiedRisksAndMitigation"
        rules={{ required: 'Identified Risks & Mitigations are required' }}
        render={({ field }) => (
          <Form.Item className="flex justify-between align-baseline">
            <Form.Label className="w-1/3 mt-4 flex-wrap justify-start">
              <p className="mr-2">
                Identified Risks & Mitigations
                <span className="text-primary"> *</span>
              </p>
              <Tooltip
                tip="Impact of the test if done in production. Mitigation - short summary of how the risk can be mitigated."
                className="font-normal text-primary h-fit"
                style={{ width: 200, marginTop: 5, fontWeight: 'normal' }}
                timeoutSeconds={5}
              >
                <i
                  className="icon_help-outlined"
                  style={{ fontSize: 18, cursor: 'help' }}
                />
              </Tooltip>
              <Form.Message className="w-full mt-1" />
            </Form.Label>
            <Form.Control>
              <Textarea {...field} className="w-2/3" />
            </Form.Control>
          </Form.Item>
        )}
      />
      <Form.Field
        control={form.control}
        name="ImpactedServices"
        rules={{ required: 'Impacted Services are required' }}
        render={({ field }) => (
          <Form.Item className="flex justify-between align-baseline">
            <Form.Label className="w-1/3 mt-4 flex-wrap justify-start">
              <p className="mr-2">
                Impacted Services
                <span className="text-primary"> *</span>
              </p>
              <Tooltip
                tip="What services are dependent on this targeted service for the test?"
                className="font-normal text-primary h-fit"
                style={{ width: 200, marginTop: 5, fontWeight: 'normal' }}
                timeoutSeconds={5}
              >
                <i
                  className="icon_help-outlined h-fit"
                  style={{ fontSize: 18, cursor: 'help' }}
                />
              </Tooltip>
              <Form.Message className="w-full mt-1" />
            </Form.Label>
            <Form.Control>
              <Input {...field} className="w-2/3" />
            </Form.Control>
          </Form.Item>
        )}
      />
      <Form.Field
        control={form.control}
        name="EstimatedDuration"
        render={({ field }) => (
          <Form.Item className="flex justify-between align-baseline">
            <Form.Label className="w-1/3 mt-4 flex-wrap">
              <p>
                Estimated Duration
              </p>
              <Form.Message className="w-full mt-1" />
            </Form.Label>
            <Form.Control>
              <Input {...field} className="w-2/3" />
            </Form.Control>
          </Form.Item>
        )}
      />
    </div>
  )
}
