import React, { useState, useCallback, useRef, useEffect } from 'react'

import axios from 'axios'
import { Client } from '@one-thd/retooling-react'

import LoadingBubbles from '../../Common/LoadingBubbles'
import DecisionRecordResult from '../Common/DecisionRecordResult'
import { DecisionRecord } from '../../Records/DecisionRecords/DecisionRecordType'


const DecisionRecordsSearch = ({ query }: {query: string}) => {

    const fetchTimeout = useRef<any>()
    const cancelSource = useRef<any>()
    const [isLoading, setIsLoading] = useState(false)
    const [matches, setMatches] = useState<DecisionRecord[]>([])

    const [maxMatches, setMaxMatches] = useState(5)
    const [searchedQuery, setSearchedQuery] = useState<string>("")

    const onQueryChange = useCallback((query: string) => {

        if (cancelSource.current) {
            cancelSource.current.cancel("Operation re-submitted with different parameters.")
        }

        cancelSource.current = axios.CancelToken.source()
        clearTimeout(fetchTimeout.current)

        if (query.length > 3) {
            const timeout = setTimeout(async () => {
                setIsLoading(true)
                try {
                    const response = await Client.get("/api/v1/search/decisionrecords", { params: { q: query }, cancelToken: cancelSource.current.token })
                    if (response.data.results) {
                        setMatches(response.data.results)
                        setMaxMatches(5)
                        setSearchedQuery(query)
                    }
                    setIsLoading(false)
                }
                catch (e) {
                    if (axios.isCancel(e)) {
                        console.info("cancelled previous request")
                    } else {
                        setMatches([])
                        setMaxMatches(5)
                    }
                    setMatches([])
                    setMaxMatches(5)
                    setIsLoading(false)
                }
            }, 300)
            fetchTimeout.current = timeout
        }
    }, [query, setIsLoading, setMatches, setMaxMatches])

    useEffect(() => {
        onQueryChange(query)
    }, [query])

    return (
        <div>
            <div style={{ paddingLeft: 4, marginBottom: 8, marginTop: 8, fontWeight: 300, display: 'flex', alignItems: 'center' }}><i style={{ margin: 4 }} className="icon_assignment" />Search Results in DecisionRecords&nbsp;<span style={{ backgroundColor: '#f96303', color: '#fff', borderRadius: 8, padding: 4, fontWeight: 'bold', fontSize: 10 }}>{matches.length}</span> {isLoading && <div style={{ marginLeft: 8, marginRight: 8 }}><LoadingBubbles size='xs' /></div>}</div>
            {
                matches.length > 0 && matches.map((match: DecisionRecord, idx: number) => {
                    if (idx > maxMatches) { return }
                    return (
                        <DecisionRecordResult record={match} idx={idx} query={searchedQuery} />
                    )
                })
            }
            {

                matches.length > maxMatches && <div onClick={() => setMaxMatches(maxMatches + 10)} style={{ padding: 4, textAlign: 'center', cursor: 'pointer' }}>Show More</div>
            }
        </div>
    )
}

export default DecisionRecordsSearch