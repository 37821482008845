import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function FilterSection({
  style, children, title, isCollapsible, isSubSection, open,
}) {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    if (!isCollapsible) {
      setIsOpen(true);
    }
  }, []);

  function handleClick() {
    if (isCollapsible) {
      setIsOpen(!isOpen);
    }
  }

  function getIcon() {
    return isOpen ? <i className="icon_minus" style={{ marginBlock: 'auto', fontWeight: 'bold', fontSize: 14 }} /> : <i className="icon_plus" style={{ marginBlock: 'auto', fontSize: 14 }} />;
  }

  return (
    <div style={style} className={`filter-section${isOpen && isCollapsible && isSubSection ? ' open' : ''}`}>
      <div
        style={{
          display: 'flex', justifyContent: 'space-between', alignContent: 'center', cursor: isCollapsible ? 'pointer' : 'auto',
        }}
        onClick={handleClick}
        role="button"
        tabIndex={0}
        onKeyUp={(e) => { if (e.key === 'Enter') { handleClick(); } }}
      >
        <div style={{
          marginBlock: 5, marginLeft: 3, fontSize: 14, marginTop: 0, fontWeight: 'bold',
        }}
        >
          {title}
        </div>
        {isCollapsible && getIcon()}
      </div>
      <div className={`filter-section-contents-wrapper${isOpen ? ' open' : ''}`}>
        <div className="filter-section-contents">
          {children}
        </div>
      </div>
    </div>
  );
}
FilterSection.propTypes = {
  isCollapsible: PropTypes.bool,
  isSubSection: PropTypes.bool,
  title: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  children: PropTypes.arrayOf(PropTypes.element),
};
FilterSection.defaultProps = {
  isSubSection: false,
  isCollapsible: false,
  style: {},
  children: [],
};

export default FilterSection;
