import React, { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import _ from 'lodash';
import ReactLoading from 'react-loading';

import Form, { FormEntry } from '../../ui/form';
import Select from '../../ui/select';
import { Input } from '../../ui/input';
import Popover from '../../ui/popover';
import { Button } from '../../ui/button';
import { Check, ChevronsUpDown } from 'lucide-react';
import Command from '../../ui/command';
import { cn } from '../../lib/utils';
import {ApplicationService, Subexperience} from '../../Applications/ApplicationType';
import { useResizeObserver } from 'usehooks-ts';
import { doRequest } from '../../Utils/utils';
import LoadingBubbles from '../../Common/LoadingBubbles';
import { id } from 'date-fns/locale';
import { Link } from 'react-router-dom';

type ApplicationStepProps = {
  form: UseFormReturn;
  application: ApplicationService | undefined;
  onApplicationChange: (app: ApplicationService) => void;
}

export default function ApplicationStep({ form, application, onApplicationChange }: ApplicationStepProps) {
  const [appsOpen, setAppsOpen] = useState(false)
  const [applications, setApplications] = useState<ApplicationService[]>([])
  const [appsLoading, setAppsLoading] = useState(false)
  // const [selectedApp, setSelectedApp] = useState<ApplicationOrService>()
  const [selectedAppLoading, setSelectedAppLoading] = useState(false)

  const getApplications = (query: string) => {
    if (query.length > 1) {
      setAppsLoading(true)
      setApplications([])
      doRequest('/api/v1/applications/typeahead', 'get', null, { q: query })
        .then(({ data }) => {
          setAppsLoading(false)
          setApplications(data.results)
        })
    }
  }

  const getApplicationInfo = (appId: string) => {
    doRequest(`/api/v1/applications/${appId}`, 'get', null, null)
      .then((data) => {
        onApplicationChange(data.data.application)
        setSelectedAppLoading(false)
      })
  }

  useEffect(() => {
    if (application) {
      form.setValue('ApplicationSelect', application)
    }
  }, [application])

  const debounce = _.debounce((query) => getApplications(query), 500);

  const appSelectRef = React.useRef<HTMLButtonElement>(null);
  const { width } = useResizeObserver({
    ref: appSelectRef,
    box: 'border-box',
  });

  return (
    <div>
      <Form.Field
        control={form.control}
        name="ApplicationSelect"
        rules={{ required: 'Application is required' }}
        render={({ field }) => (
          <Form.Item className="flex justify-between align-baseline">
            <Form.Label className="w-1/3 mt-4 flex-wrap">
              <p>
                Application or Service
                <span className="text-primary"> *</span>
              </p>
              <Form.Message className="w-full mt-1" />
            </Form.Label>
            <Form.Control>
              <Popover open={appsOpen} onOpenChange={setAppsOpen}>
                <Popover.Trigger asChild>
                  <Button
                    ref={appSelectRef}
                    variant="outline"
                    role="combobox"
                    aria-expanded={appsOpen}
                    className="w-2/3 justify-between"
                  >
                    {field.value
                      ? field.value.Name
                      : 'Select Application...'}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </Popover.Trigger>
                <Popover.Content className="p-0" style={{ width: `${width}px` }}>
                  <Command>
                    <Command.Input onValueChange={(e) => debounce(e)} className="pl-2 border-opacity-0" placeholder="Search Applications..." />
                    <Command.Empty className="flex justify-center">
                      { appsLoading
                        ? (
                          <ReactLoading
                            type="bubbles"
                            height={48}
                            width={48}
                            color="#666666"
                            className="my-4"
                          />
                        )
                        : 'No Application Found'}
                    </Command.Empty>
                    <Command.Group>
                      {!appsLoading && applications && applications.map((app: ApplicationService) => (
                        <Command.Item
                          key={app.Name}
                          value={app.Name}
                          onSelect={() => {
                            field.onChange(app)
                            getApplicationInfo(app.SysId)
                            setSelectedAppLoading(true)
                            setAppsOpen(false)
                          }}
                        >
                          <Check
                            className={cn(
                              'mr-2 h-4 w-4',
                              field.value === app.Name ? 'opacity-100' : 'opacity-0',
                            )}
                          />
                          {app.Name}
                        </Command.Item>
                      ))}
                    </Command.Group>
                  </Command>
                </Popover.Content>
              </Popover>
            </Form.Control>
          </Form.Item>
        )}
      />
      {selectedAppLoading && <LoadingBubbles size={2} />}
      {application && (
        <div className="bg-muted rounded-lg p-4 mt-4 flex flex-col gap-4">
          <div className="flex">
            <div className="font-bold min-w-40">Application Name: </div>
            <div>{application.Name}</div>
          </div>
          <div className="flex">
            <div className="font-bold min-w-40">PSRB ID: </div>
            <div>{application.ParentBusinessApp.PSRBSolutionId}</div>
          </div>
          <div className="flex">
            <div className="font-bold min-w-40">Short Description: </div>
            <div>{application.ParentBusinessApp.ShortDescription}</div>
          </div>
          <div className="flex flex-row flex-wrap gap-y-4">
            <div className="flex w-1/2">
              <div className="font-bold min-w-40">Environment: </div>
              <div>{application.ParentBusinessApp.LifecyclePhase}</div>
            </div>
            <div className="flex w-1/2">
              <div className="font-bold min-w-40">SLA Tier: </div>
              <div>{application.SLATier}</div>
            </div>
            <div className="flex w-1/2">
              <div className="font-bold min-w-40">Manager: </div>
              <div>{application.ParentBusinessApp.Manager.Name}</div>
            </div>
            <div className="flex w-1/2">
              <div className="font-bold min-w-40">Director: </div>
              <div>{application.ParentBusinessApp.Director.Name}</div>
            </div>
            <div className="flex w-1/2">
              <div className="font-bold min-w-40">VP: </div>
              <div>{application.ParentBusinessApp.VP.Name}</div>
            </div>
          </div>
        </div>
      )}
      {
        !application
          ? (
            <div className="mt-8 flex justify-start gap-1">
              <i>Can&#39;t find your Application listed? </i>
              <Link to="https://thd.atlassian.net/wiki/spaces/~557058eee03509b0c34ec6ac1b424791e24af1/blog/2024/04/30/2440512785/How+to+schedule+PSRB+review+session" target="_blank">
                <i>Click Here to find out how to get your application added to the CMDB</i>
              </Link>
            </div>
          ) : null
      }
    </div>
  )
}
