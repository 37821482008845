import React from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import PageHeader from '../../SharedComponents/PageHeader';
import '../../Form/form.css'
import { doRequest } from '../../Utils/utils';

type Inputs = {
  thesis: string;
  testDescription: string;
  actualResults: string;
  identifiedRisksAndMitigation: string;
  impactedServices: string;
  environment: string;
  estimatedDuration: string;
  lastExecutionDate: Date;
  actionItems: string;
  status: string;
};

function CreateDecisionRecord() {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (body) => {
    doRequest('/api/v1/decisionrecords', 'post', body, null)
      .then((data) => {
        if (data && data.status === 200 && data.data) {
          console.log(data)
        }
      })
      .finally(() => {
      })
  };

  return (
    <>
      {/* <div style={{ marginTop: '38px' }}>
        <PageHeader title="REcker - New Decision Record" displayMessage />
      </div> */}
      <div className="form-wrapper">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-section">
            <h2>New Decision Record</h2>
            <div className="form-section-content">
              <label>
                Thesis
                <div className="form-field">
                  <textarea {...register('thesis')} />
                </div>
              </label>
              <label>
                Test Description
                <div className="form-field">
                  <textarea {...register('testDescription')} />
                </div>
              </label>
              <label>
                Actual Results
                <div className="form-field">
                  <textarea {...register('actualResults')} />
                </div>
              </label>
              <label>
                Identified Risks & Mitigations
                <div className="form-field">
                  <textarea {...register('identifiedRisksAndMitigation')} />
                </div>
              </label>
              <label>
                Impacted Services
                <div className="form-field">
                  <input {...register('impactedServices')} />
                </div>
              </label>
              <label>
                Environment
                <div className="form-field">
                  <select {...register('environment')}>
                    <option>Staging</option>
                    <option>Production</option>
                  </select>
                </div>
              </label>
              <label>
                <div>Estimated Duration</div>
                <div className="form-field">
                  <input {...register('estimatedDuration')} />
                </div>
              </label>
              <label>
                Last Execution Date
                <div className="form-field">
                  <i style={{color:'rgb(249, 99, 2)', paddingRight:'2px', paddingLeft:'5px'}} className="icon_date" />
                  <Controller
                    control={control}
                    name="lastExecutionDate"
                    render={({ field }) => (
                      <DatePicker
                        placeholderText="Select date"
                        onChange={(date) => field.onChange(date)}
                        dateFormat="MMM d, yyyy"
                        selected={field.value}
                      />
                    )}
                  />
                </div>
              </label>
              <label>
                Action Items
                <div className="form-field">
                  <textarea {...register('actionItems')} />
                </div>
              </label>
              <label>
                Status
                <div className="form-field">
                  <input {...register('status')} />
                </div>
              </label>
            </div>
          </div>
          <input type="submit" onSubmit={handleSubmit(onSubmit)} />
        </form>
      </div>
    </>
  );
}

export default CreateDecisionRecord;
