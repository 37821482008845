import React from 'react'
import { ColumnDef } from "@tanstack/react-table";
import { ApplicationService, Subexperience } from "../../Applications/ApplicationType";
import { NavLink } from "react-router-dom";
import { Button } from '../../ui/button';
import { ArrowUpDown } from 'lucide-react';
import { TestRunRecord } from '../TestRunRecords/TestRunRecordType';

type TestRunCounts = {
  TestRunCount: number;
  SuccessCount: number;
  FailCount: number;
}

export type DecisionRecord = {
  ID: number;
  CreatedBy: string;
  CreatedAt: Date;
  UpdatedAt: Date;
  RecordNumber: string;
  TestName: string;
  Thesis: string;
  TestDescription: string;
  ActualResults: string;
  IdentifiedRisksAndMitigation: string;
  ImpactedServices: string;
  EstimatedDuration: string;
  LastExecutionDate: Date;
  ActionItems: string;
  Status: string;
  Application: ApplicationService
  ApplicationID: string
  AssignmentGroup: string;
  Editors: any[];
  Testers: any[];
  LastTest: TestRunRecord | null;
  Counts: TestRunCounts;
  TestTool: string;
}

export const Columns: ColumnDef<DecisionRecord>[] = [
  {
    header: 'Record #',
    accessorKey: 'RecordNumber',
    cell: (cellInfo) => (
      <NavLink to={`/decisionrecords/${cellInfo.row.original.RecordNumber}`} className="font-bold">{cellInfo.row.original.RecordNumber}</NavLink>
    ),
  },
  {
    header: 'Application',
    cell: (cellInfo) => (
      <NavLink to={`/applications/${cellInfo.row.original.Application.SysId}`} className="font-bold">{cellInfo.row.original.Application.Name}</NavLink>
    ),
  },
  {
    header: 'Environment',
    cell: (cellInfo) => (
      <div>{cellInfo.row.original.Application.LifecyclePhase}</div>
    ),
  },
  {
    header: 'TestName',
    accessorKey: 'TestName',
  },
  {
    header: 'Description',
    accessorKey: 'TestDescription',
  },
];

export const AppPageColumns: ColumnDef<DecisionRecord>[] = [
  {
    header: 'Test Name',
    cell: (cellInfo) => (
      <NavLink to={`/decisionrecords/${cellInfo.row.original.RecordNumber}`} className="font-bold">{cellInfo.row.original.TestName}</NavLink>
    ),
  },
  {
    header: 'Last Execution Date',
    cell: (cellInfo) => {
      const record = cellInfo.row.original

      return (
        <span>
          {
          record.LastExecutionDate.toString() === '0001-01-01T00:00:00Z' ? 'Never Run'
            : new Intl.DateTimeFormat('en-US', {
              dateStyle: 'long',
              timeZone: 'America/New_York',
            }).format(new Date(record.LastExecutionDate))
        }
        </span>
      )
    },
  },
  {
    header: 'Last Test Status',
    cell: (cellInfo) => {
      if (!cellInfo.row.original.LastTest) return <div className="rounded-md bg-muted text-foreground font-bold text-center w-20 px-2 py-1">Not Run</div>
      const status = cellInfo.row.original.LastTest.Status.charAt(0).toUpperCase() + cellInfo.row.original.LastTest.Status.slice(1)
      return <div className={`rounded-md ${status === 'Pass' ? 'bg-[#177a41]' : 'bg-[#94281c]'} text-muted font-bold text-center w-20 px-2 py-1`}>{status}</div>
    },
  },
  {
    header: 'Description',
    accessorKey: 'TestDescription',
  },
  {
    header: 'Estimated Duration',
    accessorKey: 'EstimatedDuration',
  },
];

// export const AppPageColumns: ColumnDef<DecisionRecord>[] = [
//   {
//     header: ({ column }) => (
//       <Button
//         variant="ghost"
//         onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
//       >
//         Record #
//         <ArrowUpDown className="ml-2 h-4 w-4" />
//       </Button>
//     ),
//     accessorKey: 'RecordNumber',
//     cell: (cellInfo) => (
//       <NavLink to={`/decisionrecords/${cellInfo.row.original.RecordNumber}`} className="font-bold">{cellInfo.row.original.RecordNumber}</NavLink>
//     ),
//   },
//   {
//     header: ({ column }) => (
//       <Button
//         variant="ghost"
//         onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
//       >
//         Date
//         <ArrowUpDown className="ml-2 h-4 w-4" />
//       </Button>
//     ),
//     accessorKey: 'LastExecutionDate',
//     cell: (cellInfo) => (
//       <>
//         {new Date(cellInfo.row.original.LastExecutionDate).toDateString()}
//       </>
//     ),
//   },
//   {
//     header: 'Environment',
//     accessorKey: 'Environment',
//     cell: (cellInfo) => cellInfo.row.original.Environment.charAt(0).toUpperCase() + cellInfo.row.original.Environment.slice(1),
//   },
//   {
//     header: 'Status',
//     accessorKey: 'Status',
//     cell: (cellInfo) => {
//       const status = cellInfo.row.original.Status.charAt(0).toUpperCase() + cellInfo.row.original.Status.slice(1)
//       return <div className={`rounded-sm ${status === 'Pass' ? 'bg-[#177a41]' : 'bg-[#94281c]'} text-muted font-bold text-center`}>{status}</div>
//     },
//   },
// ];

export const DashboardColumns: ColumnDef<DecisionRecord>[] = [
  {
    header: 'TestName',
    accessorKey: 'TestName',
    cell: (cellInfo) => (
      <NavLink to={`/decisionrecords/${cellInfo.row.original.RecordNumber}`} className="font-bold">{cellInfo.row.original.TestName}</NavLink>
    ),
  },
  {
    header: 'Application',
    accessorKey: 'Application',
    cell: (cellInfo) => (
      <NavLink to={`/applications/${cellInfo.row.original.Application.SysId}`} className="font-bold text-ellipsis">{cellInfo.row.original.Application.Name}</NavLink>
    ),
  },
  {
    header: 'Sr Manager',
    accessorFn: (row) => row.Application.ParentBusinessApp.SeniorManager.Name,
  },
];
