import React from 'react'
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom'
import Header from '../Common/Header/Header'
import ReinDocumentTitle from '../Common/ReinDocumentTitle'
import ToastContainer from '../Common/Toasts/ToastContainer'
import Banner from '../Common/Banner/Banner'
import Breadcrumbs from './Breadcrumbs'

function Layout({ userSettings, atRisk }) {
  const location = useLocation();

  return (
    <div>
      <Header userSettings={userSettings} />
      <ReinDocumentTitle />
      <div
        className='body-container'
        style={{
          marginTop:'25px',
          paddingBottom: '32px',
        }}
      >
        <div>
          {window.location.host.includes('recker.hd-engineering') || window.NODE_ENV === 'stage'
            ? (
              <div>
                <Link to="https://recker.hdperfeng.gcp.homedepot.com">
                  <Banner className="bg-red-600" type="error">You are currently using our stage environment. Please switch to the current production version of REcker by clicking here.</Banner>
                </Link>
              </div>
            ) : null}
        </div>
        <div style={{ position: 'sticky', top: 0, height: 1, zIndex: 999999, width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'start' }}>
          <ToastContainer />
        </div>
        <div>
          <Breadcrumbs/>
        </div>
        <Outlet />
      </div>
    </div>
  )
}

export default Layout