import React from 'react';
import NavigationItem from './NavigationItem';
import './header.css';
import { useResizeObserver } from 'usehooks-ts';
import NavigationMenu from '../../ui/navigation-menu';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { cn } from '../../lib/utils';
import { isArray } from 'lodash';
import { NavigationMenuLinkProps } from '@radix-ui/react-navigation-menu';

type Component = {
  title: string;
  to: string;
  description: string;
  Icon?: string;
};

const siteMenu: Component[] = [
  {
    title: 'REducer',
    to: 'https://reducer.hdperfeng.gcp.homedepot.com/',
    description:
      'An application that runs in Kubernetes designed to help reduce our cloud footprint and help ensure we are compliant with security standards.',
  },
  {
    title: 'REhub',
    to: 'https://rehub.homedepot.com/',
    description:
      'The central location for all major homedepot.com Reliability Engineering data and tools.',
  },
  {
    title: 'REin',
    to: 'https://rein.hdperfeng.gcp.homedepot.com/',
    description:
      'Incident and Change management tool.',
  },
]

const drMenu: Component[] = [
  {
    title: 'All Decision Records',
    to: '/decisionrecords/all',
    description: 'View all available Decision Records for all Applications.',
    Icon: 'icon_view-list',
  },
  {
    title: 'All Test Records',
    to: '/testrecords/all',
    description: 'View all available Test Run Records for all Applications.',
    Icon: 'icon_view-list',
  },
  // {
  //   title: 'My Decision Records',
  //   to: '/decisionrecords/all',
  //   description: 'See Decision Records you created.',
  //   Icon: 'icon_assignment',
  // },
];

const appMenu: Component[] = [
  {
    title: 'All Applications',
    to: '/applications/all',
    description: 'View all Applications with Decision Records.',
    Icon: 'icon_store-menu',
  },
];

const newMenu: Component[] = [
  // {
  //   title: 'New Test',
  //   to: '/tests/new',
  //   description:
  //     'Create a new Test. This will also create an associated Decision Record.',
  //   Icon: "icon_plus_circle_outline",
  // },
  {
    title: 'New Decision Record',
    to: '/decisionrecords/new',
    description:
      'Create a new Decision Record.',
    Icon: 'icon_plus_circle_outline',
  },
  {
    title: 'New Test Record',
    to: '/testrecords/new',
    description:
      'Create a new Test Run Record.',
    Icon: 'icon_plus_circle_outline',
  },
]

function HeaderNavigation() {
  const navbarRef = React.useRef<HTMLUListElement>(null);
  const { width } = useResizeObserver({
    ref: navbarRef,
  });

  return (
    <div className="header-navigation_wrapper">
      <NavigationMenu>
        <NavigationMenu.List>
          <NavigationMenu.Item>
            <NavLink to="/" >
              <NavigationMenu.Trigger className="font-bold text-2xl mr-auto">
                REcker
              </NavigationMenu.Trigger>
            </NavLink>
            <NavigationMenu.Content>
              <List width={400} components={siteMenu} className="grid-cols-1" />
            </NavigationMenu.Content>
          </NavigationMenu.Item>
        </NavigationMenu.List>
      </NavigationMenu>
      <NavigationMenu>
        <NavigationMenu.List ref={navbarRef}>
          <NavigationMenu.Item className="bg-transparent">
            <NavLink className={NavigationMenu.Style()} to="/home">
              Home
            </NavLink>
          </NavigationMenu.Item>
          <NavigationMenu.Item className="bg-transparent">
            <NavigationMenu.Trigger>
              Records
            </NavigationMenu.Trigger>
            <NavigationMenu.Content>
              <List width={width || 0} components={drMenu} />
            </NavigationMenu.Content>
          </NavigationMenu.Item>
          <NavigationMenu.Item>
            <NavigationMenu.Trigger>
              Applications
            </NavigationMenu.Trigger>
            <NavigationMenu.Content>
              <List width={width || 0} components={appMenu} />
            </NavigationMenu.Content>
          </NavigationMenu.Item>
          <NavigationMenu.Item>
            <NavLink className={NavigationMenu.Style()} to="/gettingstarted">
              Getting Started
            </NavLink>
          </NavigationMenu.Item>
          <NavigationMenu.Item>
            <NavigationMenu.Trigger>
              Create New
            </NavigationMenu.Trigger>
            <NavigationMenu.Content>
              <List width={width || 0} components={newMenu}/>
            </NavigationMenu.Content>
          </NavigationMenu.Item>
        </NavigationMenu.List>
      </NavigationMenu>
    </div>
    // <nav className="header-navigation">
    //   {nav.map((item) => <NavigationItem item={item} />)}
    // </nav>
  );
}

export default HeaderNavigation;

type ListProps = {
  width: number;
  components: Component[];
  className?: string;
};

function List({ width, components, className }: ListProps) {
  return (
    <ul className={cn("grid grid-cols-1 gap-3 p-4", className)} style={{ width: `${width}px` }}>
      {components.map((component: Component) => (
        <ListItem
          key={component.title}
          title={component.title}
          to={component.to}
          Icon={component.Icon}
        >
          {component.description}
        </ListItem>
      ))}
    </ul>
  );
}

type ListItemProps = {
  title: string;
  Icon?: string;
  to: string;
} & NavLinkProps;

const ListItem = React.forwardRef<
  React.ElementRef<typeof NavLink>,
  ListItemProps
>(({ className, title, children, Icon, to, ...props }) => (
  <li>
    <NavLink to={to} {...props}>
      <div
        className={cn(
          'block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
          className,
        )}
      >
        <div className="flex items-center gap-2 text-sm font-semibold leading-none text-foreground">
          {title}
          {Icon && <i className={`text-xl ${Icon}`} />}
        </div>
        <p className="text-sm leading-snug text-muted-foreground">
          {typeof children === 'function'
            ? children({
              isActive: false,
              isPending: false,
              isTransitioning: false,
            })
            : children}
        </p>
      </div>
    </NavLink>
  </li>
));
ListItem.displayName = 'ListItem';