import React from 'react'
import { ColumnDef } from "@tanstack/react-table";
import { NavLink } from "react-router-dom";
import { Button } from '../../ui/button';
import { ArrowUpDown } from 'lucide-react';
import { DecisionRecord } from '../DecisionRecords/DecisionRecordType';

export type TestRunRecord = {
  ID: number;
  CreatedBy: string;
  CreatedAt: Date;
  UpdatedAt: Date;
  RecordNumber: string;
  ActualResults: string;
  Date: Date | null;
  ActionItems: string;
  Status: string;
  DecisionRecord: DecisionRecord;
}

export const Columns: ColumnDef<TestRunRecord>[] = [
  {
    header: 'Date',
    accessorKey: 'Date',
  },
  {
    header: 'Record #',
    accessorKey: 'RecordNumber',
    cell: (cellInfo) => (
      <NavLink to={`/testrecords/${cellInfo.row.original.RecordNumber}`} className="font-bold">{cellInfo.row.original.RecordNumber}</NavLink>
    ),
  },
  {
    header: 'DCR #',
    accessorKey: 'DecisionRecord.RecordNumber',
    cell: (cellInfo) => (
      <NavLink to={`/decisionrecords/${cellInfo.row.original.DecisionRecord.RecordNumber}`} className="font-bold">{cellInfo.row.original.DecisionRecord.RecordNumber}</NavLink>
    ),
  },
  {
    header: 'Status',
    accessorKey: 'Status',
  },
];

export const DecisionRecordColumns: ColumnDef<TestRunRecord>[] = [
  {
    header: 'Date',
    accessorKey: 'Date',
    cell: (cellInfo) => (
      <>
        {new Intl.DateTimeFormat('en-US', {
          dateStyle: 'long',
          timeZone: 'America/New_York',
        }).format(new Date(cellInfo.row.original.Date || new Date()))}
      </>
    ),
  },
  {
    header: 'Record #',
    accessorKey: 'RecordNumber',
    cell: (cellInfo) => (
      <NavLink to={`/testrecords/${cellInfo.row.original.RecordNumber}`} className="font-bold">{cellInfo.row.original.RecordNumber}</NavLink>
    ),
  },
  {
    header: 'Status',
    accessorKey: 'Status',
    cell: (cellInfo) => {
      const status = cellInfo.row.original.Status.charAt(0).toUpperCase() + cellInfo.row.original.Status.slice(1)
      return <div className={`rounded-md ${status === 'Pass' ? 'bg-[#177a41]' : 'bg-[#94281c]'} text-muted font-bold text-center w-fit px-4 py-1`}>{status}</div>
    },
  },
];

export const DashboardColumns: ColumnDef<TestRunRecord>[] = [
  {
    header: 'Record #',
    accessorKey: 'RecordNumber',
    cell: (cellInfo) => (
      <NavLink to={`/testrecords/${cellInfo.row.original.RecordNumber}`} className="font-bold">{cellInfo.row.original.RecordNumber}</NavLink>
    ),
  },
  {
    header: 'Application',
    cell: (cellInfo) => (
      <NavLink to={`/applications/${cellInfo.row.original.DecisionRecord.Application.SysId}`} className="font-bold">{cellInfo.row.original.DecisionRecord.Application.Name}</NavLink>
    ),
  },
  {
    header: 'Test Name',
    cell: (cellInfo) => (
      <NavLink to={`/decisionrecords/${cellInfo.row.original.DecisionRecord.RecordNumber}`} className="font-bold">{cellInfo.row.original.DecisionRecord.TestName}</NavLink>
    ),
  },
  {
    header: 'Status',
    accessorKey: 'Status',
    cell: (cellInfo) => {
      const status = cellInfo.row.original.Status.charAt(0).toUpperCase() + cellInfo.row.original.Status.slice(1)
      return <div className={`rounded-md ${status === 'Pass' ? 'bg-[#177a41]' : 'bg-[#94281c]'} text-muted font-bold text-center w-fit px-4 py-1`}>{status}</div>
    },
  },
];

// export const AppPageColumns: ColumnDef<DecisionRecord>[] = [
//   {
//     header: ({ column }) => (
//       <Button
//         variant="ghost"
//         onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
//       >
//         Record #
//         <ArrowUpDown className="ml-2 h-4 w-4" />
//       </Button>
//     ),
//     accessorKey: 'RecordNumber',
//     cell: (cellInfo) => (
//       <NavLink to={`/decisionrecords/${cellInfo.row.original.RecordNumber}`} target="_blank" className="font-bold">{cellInfo.row.original.RecordNumber}</NavLink>
//     ),
//   },
//   {
//     header: ({ column }) => (
//       <Button
//         variant="ghost"
//         onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
//       >
//         Date
//         <ArrowUpDown className="ml-2 h-4 w-4" />
//       </Button>
//     ),
//     accessorKey: 'LastExecutionDate',
//     cell: (cellInfo) => (
//       <>
//         {new Date(cellInfo.row.original.LastExecutionDate).toDateString()}
//       </>
//     ),
//   },
//   {
//     header: 'Environment',
//     accessorKey: 'Environment',
//     cell: (cellInfo) => cellInfo.row.original.Environment.charAt(0).toUpperCase() + cellInfo.row.original.Environment.slice(1),
//   },
//   {
//     header: 'Status',
//     accessorKey: 'Status',
//     cell: (cellInfo) => {
//       const status = cellInfo.row.original.Status.charAt(0).toUpperCase() + cellInfo.row.original.Status.slice(1)
//       return <div className={`rounded-sm ${status === 'Pass' ? 'bg-[#177a41]' : 'bg-[#94281c]'} text-muted font-bold text-center`}>{status}</div>
//     },
//   },
// ];
