import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import _ from "lodash"

function ReinDocumentTitle() {
  const navigate = useNavigate();
  const paths = {
    "/changes":           "Changes",
    "/mychanges":         "My Changes",
    "/createrfc":         "Create RFC",
    "/change":            () => (window.location.href.split("=")[1]),
    "/incidents/current": "Incidents",
    "/incidents/tracked": "Tracked Incidents",
    "/incident":          () => (window.location.href.split("=")[1]),
    "/tickets/open":      "Open Tickets",
    "/tickets/closed":    "Closed Tickets",
    "/bpm/create":        "Create BPM",
    "/bpmsearch":         "BPM Search",
    "/bpm":               () => ("BPM-" + window.location.href.split("=")[1]),
    "/kpis":              "KPI Search",
    "/createkpi":         "Create KPI",
    "/action-items":      "Action Items",
    "/history":           "History",
    "/user-profile":      "User Profile",
    '/decisionrecords': 'Decision Records',

  }

  useEffect(() => {
    document.title = getTitle(paths)
  }, [navigate])
  
  function getTitle(path) {
    let title = ""
    Object.keys(paths).forEach((key) => {
      if (window.location.pathname === key) {
        if (_.isFunction(paths[key])) {
          title = paths[key]()
        } else {
          title = paths[key]
        }
      }
    })
    if (title === "") {
      return "REcker"
    } else {
      return title
    }
  }

  return (
    <></>
  )
}

export default ReinDocumentTitle