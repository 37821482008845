import React, { useEffect, useState } from 'react';
import './toast.css';
import formatDistance from 'date-fns/formatDistance'

function Toast({ id, type, children, onClose, snoozeable, snoozeDuration }) {
  const TOAST_STATE_OPTIONS = {
    opening: 'opening',
    closing: 'closing',
    opened: 'opened',
    closed: 'closed',
  }
  const DEFAULT_SNOOZE_DURATION = 86400 * 1000;

  const [toastOptions, setToastOptions] = useState({ icon: '', title: '' });
  const [toastState, setToastState] = useState(TOAST_STATE_OPTIONS.opening);
  const [visisble, setVisible] = useState(true);

  useEffect(() => {
    const snoozeTime = Number(localStorage.getItem('toastSnooze-' + id))
    const now = new Date().getTime()

    if (snoozeTime && snoozeTime > now) {
      setVisible(false)
    } else if (snoozeTime < now) {
      localStorage.removeItem('toastSnooze-' + id)
    }
  }, [])

  useEffect(() => {
    const options = {
      icon: '',
      title: '',
    };
    if (type === 'warning') {
      options.title = 'Warning';
      options.icon = 'icon_error_full';
    } else if (type === 'info') {
      options.title = 'Info';
      options.icon = 'icon_info_full';
    } else if (type === 'success') {
      options.title = 'Success';
      options.icon = 'icon_check-circle-full';
    } else {
      options.title = 'Error';
      options.icon = 'icon_plus-circle-filled';
    }
    setToastOptions(options);
  }, [type]);

  function closeToast() {
    if (snoozeable) {
      const futureTime = Date.now() + (_.isNumber(snoozeDuration) ? snoozeDuration : DEFAULT_SNOOZE_DURATION);
      localStorage.setItem('toastSnooze-' + id, futureTime);
    }
    setToastState(TOAST_STATE_OPTIONS.closing);
  }

  function handleAnimationEnd() {
    if (toastState === TOAST_STATE_OPTIONS.closing) {
      setVisible(false);
      onClose?.();
    }
    if (toastState === TOAST_STATE_OPTIONS.opening) {
      setToastState(TOAST_STATE_OPTIONS.opened)
    }
  }

  function millisecondsToHumanReadableString(duration) {
    const now = new Date();
    const target = new Date(now - duration);

    return formatDistance(now, target)
  }

  if (!visisble) {
    return null;
  }

  return (
    <div
      className={`toast${ ` ${toastState}`}`}
      onAnimationEnd={handleAnimationEnd}
    >
      <div className={`toast-tab${type ? ` ${type}` : ''}`} />
      <div className="toast-content">
        <i className={`toast-icon ${toastOptions.icon}`} />
        <div className="toast-information">
          <div className="toast-header">
            <p className="toast-header-title">
              {toastOptions.title}
            </p>
          </div>
          <div className="toast-body">
            {children}
          </div>
        </div>

        {snoozeable
          ? <i
              title={`Snooze for ${millisecondsToHumanReadableString(_.isNumber(snoozeDuration) ? snoozeDuration : DEFAULT_SNOOZE_DURATION)}`}
              className="icon_timer" onClick={closeToast}
            />
          : <i className="icon_close" onClick={closeToast} />}
      </div>
    </div>
  );
}

export default Toast;
