import React, { useEffect, useRef } from 'react';
import { useBoolean, useIsMounted } from 'usehooks-ts';
import DropdownMenu from '../dropdownMenu';
import { Column } from '@tanstack/react-table';
import { EraserIcon } from 'lucide-react';
import { Input } from '../input';
import { Button } from '../button';
import Command from '../command';

const SearchInput = ({ column }: { column: Column<any, any> }) => {
  const isMounted = useIsMounted();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isMounted()) {
      const timer = setTimeout(() => {
        inputRef.current?.focus();
      }, 0);

      return () => clearTimeout(timer);
    }
  }, [isMounted]);

  return (
    <>
      <Input
        value={(column.getFilterValue() as string) ?? ''}
        onChange={(e) => column.setFilterValue(e.target.value)}
        ref={inputRef}
        className="h-8"
        onClick={(e) => e.stopPropagation()}
        onMouseMove={() => inputRef.current?.focus()}
      />
      {inputRef.current?.focus()}
    </>
  );
};

interface Props<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title: string;
  isSubMenu?: boolean;
  Icon?: React.ComponentType<any>;
}

function DataTableSearchFilter<TData, TValue>({
  column,
  title,
  isSubMenu = false,
  Icon,
  ...props
}: Props<TData, TValue>) {
  const isSearchOpen = useBoolean();

  return (
    <>
      {isSubMenu ? (
        <DropdownMenu.Sub open={isSearchOpen.value} onOpenChange={isSearchOpen.setValue}>
          <DropdownMenu.SubTrigger>
            {Icon && <Icon className="mr-2 h-4 w-4" />}
            Search {title}
          </DropdownMenu.SubTrigger>
          <DropdownMenu.Portal>
            <DropdownMenu.SubContent>
              <DropdownMenu.Item onClick={(e) => e.stopPropagation()}>
                <SearchInput column={column} />
              </DropdownMenu.Item>
              <DropdownMenu.Separator />
              <DropdownMenu.Item onClick={() => column.setFilterValue(undefined)}>
                <EraserIcon className="mr-2 h-3.5 w-3.5 text-primary" />
                Clear Search
              </DropdownMenu.Item>
            </DropdownMenu.SubContent>
          </DropdownMenu.Portal>
        </DropdownMenu.Sub>
      ) : (
        <DropdownMenu>
          <DropdownMenu.Trigger asChild>
            <Button variant="outline" className="h-8 gap-2">
              {Icon && <Icon className="h-4 w-4" />}
              <p>{title}</p>
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            <DropdownMenu.Item onClick={(e) => e.stopPropagation()}>
              <SearchInput column={column} />
            </DropdownMenu.Item>
            <DropdownMenu.Separator />
            <DropdownMenu.Item onClick={() => column.setFilterValue(undefined)}>
              <EraserIcon className="mr-2 h-3.5 w-3.5 text-primary" />
              Clear Search
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu>
      )}
    </>
  );
}

export default DataTableSearchFilter;
