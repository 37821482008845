import React, { FunctionComponent, ReactNode, useState } from 'react';
import { FieldValues, SubmitHandler, UseFormReturn, useForm } from 'react-hook-form';
import Card from '../ui/card';
import Form from '../ui/form';

type Step = {
  label: string;
  title: string;
  component: FunctionComponent<UseFormReturn>;
}

type MultistepFormProps = {
  title: string;
  steps: Step[];
  onSubmit: SubmitHandler<FieldValues>
}

export default function MultistepForm({ title, steps, onSubmit }: MultistepFormProps) {
  const [currentStep, setCurrentStep] = useState(0)

  const form = useForm();

  return (
    <div className="max-w-screen-xl m-auto flex flex-wrap justify-between gap-6 align-middle">
      <Card className="p-8 w-full flex items-center justify-center">
        <Card.Content className="p-0">
          <div className="flex justify-center">
            <div className="flex gap-2 items-center">
              {steps.map((step, i) => (
                <div className="flex items-start gap-2">
                  {i !== 0 ? <div className="h-12 flex flex-col justify-center"><div className={`transition-colors ease-in-out duration-300 w-32 h-1 ${i <= currentStep ? 'bg-primary' : 'bg-inactive'}`} /></div> : null}
                  <button type="button" className="flex items-center flex-col hover:cursor-pointer" onClick={() => setCurrentStep(i)}>
                    <div className={`z-10 transition-colors ease-in-out duration-300 flex items-center justify-center rounded-full h-12 w-12 m-auto ${i <= currentStep ? 'bg-primary' : 'bg-inactive'}`}>
                      <div className="text-primary-foreground font-bold text-lg">
                        {i + 1}
                      </div>
                    </div>
                    <div className={`${i === currentStep ? 'animate-ping' : ''} absolute mt-2 transition-colors ease-in-out duration-1000 rounded-full h-8 w-8 m-auto bg-primary`} />
                    <div className="">
                      {step.label}
                    </div>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </Card.Content>
      </Card>
      <Card className="p-8 mb-8 w-full">
        <Card.Header>
          <Card.Title>{steps[currentStep].title}</Card.Title>
        </Card.Header>
        <Card.Content>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="w-full pl-6 pr-6">
              {steps[currentStep].component(form)}
              <div className="flex flex-row-reverse justify-between mt-8">
                {currentStep !== steps.length - 1
                  ? (
                    <button type="button" className="bg-primary text-primary-foreground p-2 rounded-md" onClick={() => setCurrentStep(currentStep + 1)}>
                      Next Step
                    </button>
                  )
                  : null}
                {currentStep === steps.length - 1
                  ? (
                    <button type="submit" className="bg-primary text-primary-foreground p-2 rounded-md">
                      Submit
                    </button>
                  )
                  : null}
                {currentStep !== 0
                  ? (
                    <button type="button" className="bg-primary text-primary-foreground p-2 rounded-md" onClick={() => setCurrentStep(currentStep - 1)}>
                      Previous Step
                    </button>
                  )
                  : null}
              </div>
            </form>
          </Form>
        </Card.Content>
      </Card>
    </div>
  )
}

export { Step }
