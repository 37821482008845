// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-button {
  text-transform: capitalize;
  color: var(--color-primary);
  background-color: #fbfbfb;
  border: none;
  outline: 0;
  cursor: pointer;
  padding: 8px 16px;
  font-weight: 600;
  letter-spacing: 0;
  text-decoration: none;
  width: auto;
  display: flex;
  align-items: center;
  gap: 4px;

  border-radius: 0.25em;
  font-weight: 600;
}
.primary {
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 0.25em;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 12%), 0 1px 2px 0 rgb(0 0 0 / 24%);
}
.disabled {
  background-color: lightgrey;
  color: white;
  cursor: not-allowed;
}
.delete {
  background-color: red;
  color: #fff;
}
.no-outline {
  border: none;
  background-color: transparent;
  color: #6d6d6d;
  font-style: italic;
  font-weight: 400;
}
.btn-sm{
    padding: 4px 8px;
  }
.btn-text {
  background-color: transparent;
  padding: 0px;
  color: var(--color-primary);
  font-style: italic;
  font-weight: 400;
  color: var(--color-primary);
}
.btn-text svg {
  fill: var(--color-primary);
}
`, "",{"version":3,"sources":["webpack://./src/recker/SharedComponents/button.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,2BAA2B;EAC3B,yBAAyB;EACzB,YAAY;EACZ,UAAU;EACV,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;EACjB,qBAAqB;EACrB,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,QAAQ;;EAER,qBAAqB;EACrB,gBAAgB;AAClB;AACA;EACE,sCAAsC;EACtC,WAAW;EACX,qBAAqB;EACrB,oEAAoE;AACtE;AACA;EACE,2BAA2B;EAC3B,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,qBAAqB;EACrB,WAAW;AACb;AACA;EACE,YAAY;EACZ,6BAA6B;EAC7B,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB;AACA;IACI,gBAAgB;EAClB;AACF;EACE,6BAA6B;EAC7B,YAAY;EACZ,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,2BAA2B;AAC7B;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".custom-button {\n  text-transform: capitalize;\n  color: var(--color-primary);\n  background-color: #fbfbfb;\n  border: none;\n  outline: 0;\n  cursor: pointer;\n  padding: 8px 16px;\n  font-weight: 600;\n  letter-spacing: 0;\n  text-decoration: none;\n  width: auto;\n  display: flex;\n  align-items: center;\n  gap: 4px;\n\n  border-radius: 0.25em;\n  font-weight: 600;\n}\n.primary {\n  background-color: var(--color-primary);\n  color: #fff;\n  border-radius: 0.25em;\n  box-shadow: 0 0 2px 0 rgb(0 0 0 / 12%), 0 1px 2px 0 rgb(0 0 0 / 24%);\n}\n.disabled {\n  background-color: lightgrey;\n  color: white;\n  cursor: not-allowed;\n}\n.delete {\n  background-color: red;\n  color: #fff;\n}\n.no-outline {\n  border: none;\n  background-color: transparent;\n  color: #6d6d6d;\n  font-style: italic;\n  font-weight: 400;\n}\n.btn-sm{\n    padding: 4px 8px;\n  }\n.btn-text {\n  background-color: transparent;\n  padding: 0px;\n  color: var(--color-primary);\n  font-style: italic;\n  font-weight: 400;\n  color: var(--color-primary);\n}\n.btn-text svg {\n  fill: var(--color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
