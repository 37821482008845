import React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { Box } from '../../Common/Box/Box';
import LoadingBubbles from '../../Common/LoadingBubbles';
import { doPostRequest } from '../../Utils/utils';

import styles from './Card.module.css'
import { MiniDate } from './MiniDate';
import { NavLink } from 'react-router-dom';
import { PersonChip } from './PersonChip';
import { TimelineDates } from './TimelineDates';

export const ChangeCard = ({ number, onFailure }) => {

    const [isLoading, setIsLoading] = useState(true)
    const [change, setChange] = useState(null)

    const getChangeInfo = useCallback(async (number) => {

        setIsLoading(true)

        const response = await doPostRequest("/api/v1/changes/" + number, "get", null, { forcePull: true }, null)

        if (response.status === 200) {
            setChange(response.data.change)
            setIsLoading(false)
        } else {
            onFailure?.()
        }

    }, [setChange, setIsLoading])

    useEffect(() => {
        getChangeInfo(number)
    }, [number])

    return (
        <NavLink to={`/change?id=${number}`} target="_blank" className={`${styles.changeCard}`} key={number}>
            <Box flex across aC jB>
                <Box flex across aC jS>
                    <h3>{number}</h3>
                    {!isLoading && <span style={{ marginLeft: 8, fontWeight: 300, textTransform: 'uppercase' }}>{change.State}</span>}
                </Box>
                {!isLoading && <span>{change.Subexperience.Name}</span>}
            </Box>
            {isLoading ? <Box flex jC aC><LoadingBubbles /></Box> :
                <Box flex down jS aStr>
                    <Box flex across jB aC>
                        <PersonChip person={change.AssignedTo} label="ASSIGNED" />
                        <PersonChip person={change.AssignedManager} label="MANAGER" right />
                    </Box>
                    <Box flex across jB aC>
                        <PersonChip person={change.Coordinator} label="COORDINATOR" />
                        <PersonChip person={change.AssignedDirector} label="DIRECTOR" right />
                    </Box>
                    <Box flex down aStr mT={8} mB={8}>
                       <TimelineDates events={{ "Planned Start": change.PlannedStartDate, "Planned End": change.PlannedEndDate, "Actual Start": change.ActualWorkStart, "Actual End": change.ActualWorkEnd }} />
                    </Box>
                    <Box mT={16} mB={16} aS><b>{change.ShortDescription}</b></Box>
                    <Box mB={8} aS><pre style={{ fontFamily: '"Open Sans", sans-serif', whiteSpace: 'pre-wrap' }}>{change.Description}</pre></Box>
                </Box>
            }
        </NavLink>
    )
}