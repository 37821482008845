import React, { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import _ from 'lodash';
import ReactLoading from 'react-loading';
import { useSearchParams } from 'react-router-dom';
import { useResizeObserver } from 'usehooks-ts';

import { DecisionRecord } from '../DecisionRecords/DecisionRecordType';
import Form, { FormEntry } from '../../ui/form';
import Select from '../../ui/select';
import { Input } from '../../ui/input';
import Popover from '../../ui/popover';
import { Button } from '../../ui/button';
import { Check, ChevronsUpDown } from 'lucide-react';
import Command from '../../ui/command';
import { cn } from '../../lib/utils';
import { doRequest } from '../../Utils/utils';
import LoadingBubbles from '../../Common/LoadingBubbles';
import { useProfileContext } from '../../Utils/user';
import useNotification from '../../Common/Hooks/useNotification';

type ApplicationStepProps = {
  form: UseFormReturn;
  decisionRecord: DecisionRecord | null;
  onDecisionRecordChange: (dcr: DecisionRecord | null) => void;
}

export default function DecisionRecordStep({ form, decisionRecord, onDecisionRecordChange }: ApplicationStepProps) {
  const profile = useProfileContext()
  const [searchParams] = useSearchParams();
  const [dcrLoading, setDcrLoading] = useState(false)

  const [hasRole, setHasRole] = useState(false);

  const paramDCR = searchParams.get('num');

  const getDecisionRecord = (query: string) => {
    if (query.length > 3) {
      setDcrLoading(true)
      onDecisionRecordChange(null)
      doRequest('/api/v1/decisionrecords/' + query, 'get', null, null)
        .then((data) => {
          if (data.status === 200) {
            setDcrLoading(false)
            setHasRole(false)
            onDecisionRecordChange(data.data.record)
          }
        })
        .catch((err) => {
          console.error(err)
          setDcrLoading(false)
        })
    }
  }

  useEffect(() => {
    if (paramDCR) {
      form.setValue('DecisionRecordNumber', paramDCR)
      getDecisionRecord(paramDCR)
    }
  }, [])

  useEffect(() => {
    if (profile && decisionRecord) {
      decisionRecord.Editors.forEach((editor) => {
        if (editor.Email === profile.Email) {
          setHasRole(true)
        }
      })
      decisionRecord.Testers.forEach((tester) => {
        if (tester.Email === profile.Email) {
          setHasRole(true)
        }
      })
      if (decisionRecord.CreatedBy === profile.Email) {
        setHasRole(true)
      }
      if (decisionRecord.Application.ParentBusinessApp.Manager.Email === profile.Email) {
        setHasRole(true)
      }
      if (decisionRecord.Application.ParentBusinessApp.SeniorManager.Email === profile.Email) {
        setHasRole(true)
      }
      if (decisionRecord.Application.ParentBusinessApp.Director.Email === profile.Email) {
        setHasRole(true)
      }
      if (decisionRecord.Application.ParentBusinessApp.VP.Email === profile.Email) {
        setHasRole(true)
      }
    }
  }, [decisionRecord])

  const appSelectRef = React.useRef<HTMLButtonElement>(null);
  const { width } = useResizeObserver({
    ref: appSelectRef,
    box: 'border-box',
  });

  const debounce = _.debounce((query) => getDecisionRecord(query), 500);

  return (
    <div>
      <Form.Field
        control={form.control}
        name="DecisionRecordNumber"
        render={({ field }) => (
          <>
            <Form.Item className="flex justify-between align-baseline">
              <Form.Label className="w-1/3 mt-4">DCR Number</Form.Label>
              <Form.Control>
                <Input
                  {...field}
                  className="w-2/3"
                  onChange={(e) => {
                    field.onChange(e.target.value)
                    debounce(e.target.value)
                  }}
                />
              </Form.Control>
            </Form.Item>
            <Form.Message />
          </>
        )}
      />
      {dcrLoading && <LoadingBubbles size={2} />}
      {decisionRecord && (
        <div className="bg-muted rounded-lg p-4 mt-4 flex flex-col gap-4">
          <div className="flex">
            <div className="font-bold min-w-44">Test Name: </div>
            <div>{decisionRecord.TestName}</div>
          </div>
          <div className="flex">
            <div className="font-bold min-w-44">Application or Service: </div>
            <div>{decisionRecord.Application.Name}</div>
          </div>
          <div className="flex">
            <div className="font-bold min-w-44">Environment: </div>
            <div>{decisionRecord.Application.ParentBusinessApp.LifecyclePhase}</div>
          </div>
          <div className="flex">
            <div className="font-bold min-w-44">Test Description: </div>
            <div>{decisionRecord.TestDescription}</div>
          </div>
          <div className="flex">
            <div className="font-bold min-w-44">Estimated Duration: </div>
            <div>{decisionRecord.EstimatedDuration || 'None Given'}</div>
          </div>
          {decisionRecord != null && !hasRole && (
            <div className="flex justify-center">
              <div className="text-destructive">You do not have permission to create a test for this Decision Record</div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
