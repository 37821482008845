// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

form {
  width: 75%;
}

.form-section {
  background-color: white;
  padding: 0;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border:1px solid #cccccc;
}

.form-section h1, .form-section h2, .form-section h3 {
  background-color: #dedede;
  font-weight: bold;
  padding:15px;
  font-size: 16px;
  color:#464545;
  margin: 0;
  margin-bottom: 5px
}

.form-section-content {
  padding: 15px;
}

label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.form-field {
  width: calc(50% - 2px);
}

.form-field > input, .form-field > textarea {
  width: 100%;
}

.react-datepicker-wrapper {
  width: calc(50% - 2px);
}

.form-field i {
  position: absolute;
  z-index: 1;
  margin-left: 125px;
  margin-top: 7px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/recker/Form/form.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,uBAAuB;EACvB,UAAU;EACV,oBAAoB;EACpB,mBAAmB;EACnB,wBAAwB;AAC1B;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,SAAS;EACT;AACF;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,kBAAkB;EAClB,eAAe;EACf,eAAe;AACjB","sourcesContent":[".form-wrapper {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\nform {\n  width: 75%;\n}\n\n.form-section {\n  background-color: white;\n  padding: 0;\n  padding-bottom: 10px;\n  margin-bottom: 10px;\n  border:1px solid #cccccc;\n}\n\n.form-section h1, .form-section h2, .form-section h3 {\n  background-color: #dedede;\n  font-weight: bold;\n  padding:15px;\n  font-size: 16px;\n  color:#464545;\n  margin: 0;\n  margin-bottom: 5px\n}\n\n.form-section-content {\n  padding: 15px;\n}\n\nlabel {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 5px;\n}\n\n.form-field {\n  width: calc(50% - 2px);\n}\n\n.form-field > input, .form-field > textarea {\n  width: 100%;\n}\n\n.react-datepicker-wrapper {\n  width: calc(50% - 2px);\n}\n\n.form-field i {\n  position: absolute;\n  z-index: 1;\n  margin-left: 125px;\n  margin-top: 7px;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
