import { useContext } from 'react';
import ToastContext, { Toast, ToastType } from "../Toasts/contexts/ToastContext";

type UseNotificationType = {
  addToast: (toast: Toast) => string
  addPersistentToast: (toast: Toast) => string
  removeToast: (id: string) => void
  clearAllTemporaryToasts: () => void
  clearAllToasts: () => void
  updateToast: (id: string, message: string, type: ToastType) => void
};

const useNotification = (): UseNotificationType => {
  const toastContext = useContext(ToastContext);

  return {
    addToast: toastContext.addToastNotification,
    addPersistentToast: toastContext.addPersistentToastNotification,
    removeToast: toastContext.removeToastNotification,
    clearAllTemporaryToasts: toastContext.clearTemporaryToastNotifications,
    clearAllToasts: toastContext.clearToastNotifications,
    updateToast: toastContext.updateToastNotification,
  }
}

export default useNotification;