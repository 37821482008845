import React, { useEffect, useState } from 'react';
import { useLoaderData, useLocation, useParams } from 'react-router-dom';
import Loading from 'react-loading';
import { Legend, PieChart, Tooltip as RechartsTooltip, Pie, Cell } from 'recharts';
import Card from '../ui/card';
import { doRequest } from '../Utils/utils';
import { AppPageColumns, DecisionRecord } from '../Records/DecisionRecords/DecisionRecordType';
import DataTable from '../ui/dataTable/datatable';
import { ApplicationService, UserForConfigurationItem } from '../Applications/ApplicationType';
import LeaderReportingColumns from './ReportingCols';

type ChartData = {
  name: string;
  value: number;
}

const PIE_CHART_COLORS = [
  'green',
  'hsl(var(--destructive))',
  'hsl(var(--inactive))',
];

function ApplicationSingleView() {
  const { position, userId } = useParams();

  const { leaderProp } = useLocation()?.state as { leaderProp: UserForConfigurationItem } || { leaderProp: null }
  const loaderData = useLoaderData() as any

  const [leader, setLeader] = useState<UserForConfigurationItem>()
  const [records, setRecords] = useState<DecisionRecord[]>([])
  const [chartData, setChartData] = useState<ChartData[]>([])
  const [applications, setApplications] = useState<String[]>([])

  useEffect(() => {
    if (leaderProp) {
      setLeader(leaderProp)
      return
    }

    if (loaderData && loaderData.status === 200) {
      setRecords(loaderData.data.records)
      return
    }

    doRequest(`/api/v1/reporting/${userId}/decisionrecords?position=${position}`, 'get')
      .then(({ data }) => {
        setRecords(data.records)
      })
  }, [])

  useEffect(() => {
    if (records.length === 0) return

    const apps = new Set<String>()

    switch (position) {
      case 'manager':
        setLeader(records[0].Application.ParentBusinessApp.Manager)
        break;
      case 'director':
        setLeader(records[0].Application.ParentBusinessApp.Director)
        break;
      case 'vp':
        setLeader(records[0].Application.ParentBusinessApp.VP)
        break;
      case 'businessowner':
        setLeader(records[0].Application.ParentBusinessApp.BusinessOwner)
        break;
      default:
        break;
    }

    let passing = 0;
    let failing = 0;
    let notRun = 0;
    records.forEach((record) => {
      if (!record.LastTest) {
        notRun += 1
      } else if (record.LastTest.Status === 'pass') {
        passing += 1
      } else {
        failing += 1
      }

      apps.add(record.Application.Name)
    })

    setChartData([
      { name: 'Passing', value: passing },
      { name: 'Failing', value: failing },
      { name: 'Not Run', value: notRun },
    ])

    setApplications(Array.from(apps))
  }, [records])

  return (
    <>
      { records.length > 0
        ? (
          <div style={{
            maxWidth: '1400px',
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '30px',
          }}
          >
            <Card style={{
              width: '100%',
            }}
            >
              <Card.Header>
                <Card.Title>{`${position?.toLocaleUpperCase()} - ${leader?.Name}`}</Card.Title>
              </Card.Header>
              <Card.Content className="flex flex-wrap gap-y-2 mt-4">
                <div className="w-2/3 border-r-2 pr-4 flex flex-wrap gap-y-1 text-lg font-bold">
                  <div className="flex gap-4 w-1/2">
                    <div className="font-bold min-w-64">Application Count:</div>
                    <div>{applications.length}</div>
                  </div>
                  <div className="flex gap-4 w-1/2">
                    <div className="font-bold min-w-64">Decision Record Count:</div>
                    <div>{records.length}</div>
                  </div>
                  <div className="flex gap-4 w-full">
                    <div className="font-bold min-w-64">Passing Decision Records:</div>
                    <div>{`${chartData.length > 0 && chartData.filter((r) => r.name === 'Passing')[0].value}`}</div>
                    <div>{`(${chartData.length > 0 && Math.floor((chartData.filter((r) => r.name === 'Passing')[0].value / records.length) * 100)}%)`}</div>
                  </div>
                  <div className="flex gap-4 w-full">
                    <div className="font-bold min-w-64">Failing Decision Records:</div>
                    <div>{`${chartData.length > 0 && chartData.filter((r) => r.name === 'Failing')[0].value}`}</div>
                    <div>{`(${chartData.length > 0 && Math.floor((chartData.filter((r) => r.name === 'Failing')[0].value / records.length) * 100)}%)`}</div>
                  </div>
                  <div className="flex gap-4 w-full">
                    <div className="font-bold min-w-64">Decision Records Not Run:</div>
                    <div>{`${chartData.length > 0 && chartData.filter((r) => r.name === 'Not Run')[0].value}`}</div>
                    <div>{`(${chartData.length > 0 && Math.floor((chartData.filter((r) => r.name === 'Not Run')[0].value / records.length) * 100)}%)`}</div>
                  </div>
                </div>
                <PieChart width={300} height={180} style={{ outline: 'none' }}>
                  <Legend layout="vertical" verticalAlign="middle" align="right" />
                  <RechartsTooltip />
                  <Pie
                    style={{ cursor: 'pointer', outline: 'none' }}
                    dataKey="value"
                    nameKey="name"
                    data={chartData}
                    cx="50%"
                    cy="50%"
                    outerRadius="80%"
                    stroke="none"
                    animationBegin={0}
                    animationDuration={500}
                  >
                    {chartData.map((entry, index) => (
                      <Cell key={`cell-${entry.name}`} fill={PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </Card.Content>
            </Card>
            <Card className="w-full">
              <Card.Header>
                <Card.Title>Decision Records</Card.Title>
              </Card.Header>
              <Card.Content>
                <DataTable data={records} columns={LeaderReportingColumns} />
              </Card.Content>
            </Card>
          </div>
        )
        : <Loading />}
      <div />
    </>
  )
}

export default ApplicationSingleView;
