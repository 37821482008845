import React, { ReactNode, useMemo } from 'react';
import DropdownMenu from '../dropdownMenu';
import { IconNode } from 'lucide-react';
import Command from '../command';
import { Column, Table } from '@tanstack/react-table';
import _ from 'lodash';
import { Button } from '../button';

type Props<TData, TValue> = {
  title: string;
  column: Column<TData, TValue>;
  table: Table<TData>;
  Icon?: React.ComponentType<any>;
  isSubMenu?: boolean;
  presets?: string[];
};

function DataTableDropdownFilter<TData, TValue>({
  title,
  column,
  Icon,
  isSubMenu = false,
  table,
  presets,
}: Props<TData, TValue>) {
  const getFilterValues = () => (column.getFilterValue() ? (column.getFilterValue() as string[]) : []);

  const getOptions = () => {
    const values = presets ?? table.getCoreRowModel().flatRows.map((row) => row.getValue(column.id)) as string[];
    const selectedValues = getFilterValues();
    const filteredValues = _.difference(values, selectedValues);
    return _.uniq(filteredValues);
  };

  const options = getOptions();

  const addSelected = (selected: string) => {
    const current = getFilterValues();
    column.setFilterValue([...current, selected]);
  };

  return (
    <>
      {isSubMenu ? (
        <DropdownMenu.Sub>
          <DropdownMenu.SubTrigger>
            {Icon && <Icon className="mr-2 h-4 w-4" />}
            {title}
          </DropdownMenu.SubTrigger>
          <DropdownMenu.SubContent>
            <Command>
              <Command.Input />
              <Command.List>
                {options.length === 0 ? (
                  <p className="py-3 text-center text-sm">No more options.</p>
                ) : (
                  <Command.Empty className="py-3">No results found.</Command.Empty>
                )}
                {options && (
                  <Command.Group>
                    {options.map((option) => (
                      <Command.Item key={option} value={option} onSelect={() => addSelected(option)}>
                        {option}
                      </Command.Item>
                    ))}
                  </Command.Group>
                )}
              </Command.List>
            </Command>
          </DropdownMenu.SubContent>
        </DropdownMenu.Sub>
      ) : (
        <DropdownMenu>
          <DropdownMenu.Trigger asChild>
            <Button variant="outline" className="h-8 gap-2">
              {Icon && <Icon className="h-4 w-4" />}
              <p>{title}</p>
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            <Command>
              <Command.Input />
              <Command.List>
                {options.length === 0 ? (
                  <p className="py-3 text-center text-sm">No more options.</p>
                ) : (
                  <Command.Empty className="py-3">No results found.</Command.Empty>
                )}
                {options && (
                  <Command.Group>
                    {options.map((option) => (
                      <Command.Item key={option} value={option} onSelect={() => addSelected(option)}>
                        {option}
                      </Command.Item>
                    ))}
                  </Command.Group>
                )}
              </Command.List>
            </Command>
          </DropdownMenu.Content>
        </DropdownMenu>
      )}
    </>
  );
}

export default DataTableDropdownFilter;
