// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.VNSI34OwKhPZoXqryXOQ {
    background-color: #ffdec9;
    border-radius: 4px;
    font-weight: 300;
    font-size: 9px;
    padding: 2px;
}

.pfDisdYLjed4cfSMZfNu:hover .VNSI34OwKhPZoXqryXOQ {
    background-color: #f96303;
}`, "",{"version":3,"sources":["webpack://./src/recker/Search/Searches/ActionableItems.module.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["\n.resultSubexperienceLabel {\n    background-color: #ffdec9;\n    border-radius: 4px;\n    font-weight: 300;\n    font-size: 9px;\n    padding: 2px;\n}\n\n.changeResult:hover .resultSubexperienceLabel {\n    background-color: #f96303;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resultSubexperienceLabel": `VNSI34OwKhPZoXqryXOQ`,
	"changeResult": `pfDisdYLjed4cfSMZfNu`
};
export default ___CSS_LOADER_EXPORT___;
