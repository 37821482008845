// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchbar-container {
    display: flex;
    align-items: center;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding-block: 1px;
    margin: 1px;
}

.searchbar-container.focused {
    border-width: 2px;
    border-color: #2684FF;
    margin: 0px;
}

.searchbar-icon-right {
    padding-inline: 5px;
    background: white;
    border-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    color: inherit;
    transition: color 0.3s ease;
    cursor: pointer;
}

.searchbar-icon-right:hover {
    color: #272727
}

.searchbar-icon-left {
    padding-inline: 5px;
    background: white;
    border-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
}

.searchbar-input {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 0;
}`, "",{"version":3,"sources":["webpack://./src/recker/Common/Inputs/SearchBar/searchBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,qBAAqB;IACrB,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gCAAgC;IAChC,mCAAmC;IACnC,cAAc;IACd,2BAA2B;IAC3B,eAAe;AACnB;;AAEA;IACI;AACJ;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,+BAA+B;IAC/B,kCAAkC;AACtC;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,4BAA4B;IAC5B,0BAA0B;IAC1B,6BAA6B;IAC7B,SAAS;AACb","sourcesContent":[".searchbar-container {\n    display: flex;\n    align-items: center;\n    border: 1px solid #cccccc;\n    border-radius: 5px;\n    padding-block: 1px;\n    margin: 1px;\n}\n\n.searchbar-container.focused {\n    border-width: 2px;\n    border-color: #2684FF;\n    margin: 0px;\n}\n\n.searchbar-icon-right {\n    padding-inline: 5px;\n    background: white;\n    border-right: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-top-right-radius: inherit;\n    border-bottom-right-radius: inherit;\n    color: inherit;\n    transition: color 0.3s ease;\n    cursor: pointer;\n}\n\n.searchbar-icon-right:hover {\n    color: #272727\n}\n\n.searchbar-icon-left {\n    padding-inline: 5px;\n    background: white;\n    border-right: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-top-left-radius: inherit;\n    border-bottom-left-radius: inherit;\n}\n\n.searchbar-input {\n    border-left: 0;\n    border-top-left-radius: 0;\n    border-bottom-left-radius: 0;\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n    border: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
