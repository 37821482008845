import React from 'react'
import Card from '../../ui/card'

export default function GettingStarted() {
  return (
    <Card className="max-w-screen-xl mx-auto">
      <Card.Header>
        <Card.Title>
          Getting Started
        </Card.Title>
      </Card.Header>
      <Card.Content>
        <h2 className="font-bold text-xl my-2">Overview</h2>
        <p className="my-2">
          REcker is a tool designed to help document and track destructive testing for applications at Home Depot. Although REcker has a separate tool for running network attack tests, REcker can be used regardless of how a test is run.
          REcker was built based off of the
          <a href="https://team.homedepot.com/sites/ReliabilityEngineering/SitePages/Des.aspx" rel="noreferrer" target="_blank"> Destructive Testing policy and process </a>
          outlined on the Reliability Engineering site.
        </p>
        <h2 className="font-bold text-xl my-2">Decision Records</h2>
        <p className="my-2">
          A Decision Record is a document that details the reasons, impact, and expected outcomes of choosing to conduct a particular destructive test on an application.
          It also serves as a historical reference for your team and stakeholders, helping them understand why a specific test was deemed necessary,
          and captures the logic and potential risks at the time of the decision. They are NOT documentation for the results of a test being run.
          These should be created for each different test being run on an application.
        </p>
        <h2 className="font-bold text-xl my-2">Test Run Records</h2>
        <p className="my-2">
          Test Run Records are created to document the results of a test being run. These documents are created from a Decision Record and document test status, date, and actual results of the test. Test Run Records should be created
          each time a test for a Decision Record is run. Decision Records will pull the latest Test Run Record under it for the current status of that Decision Record.
        </p>
        <h2 className="font-bold text-xl my-2">Feedback</h2>
        <p className="my-2">
          If you have any feedback, questions, concerns, or feature requests for REcker, please reach out to our team on slack at
          <a href="https://thdengops.slack.com/messages/C9CKD6KA7" rel="noreferrer" target="_blank"> #recker</a>
        </p>

      </Card.Content>
    </Card>
  )
}
