import React, { useEffect, useRef, useState } from 'react'
import useAttachment from "../../Common/Hooks/useAttachment"
import { Button } from "../../ui/button"
import { FileIcon, PaperclipIcon, UploadCloudIcon, XCircleIcon } from "lucide-react"
import { cn } from "../../lib/utils"

type AttachmentStepProps = {
  attachments: File[]
  onAttachmentsChange: React.Dispatch<React.SetStateAction<File[]>>
}

function AttachmentStep({ attachments, onAttachmentsChange }: AttachmentStepProps) {
  const [isDragOver, setIsDragOver] = useState(false);

  const uploaderRef = useRef<HTMLInputElement>(null)

  function handleAddFile() {
    if (
      !uploaderRef.current ||
      !uploaderRef.current.files ||
      uploaderRef.current.files.length === 0 ||
      uploaderRef.current.files[0] === undefined
    ) return;

    const file = uploaderRef.current.files[0];
    onAttachmentsChange((prev) => [...prev, file])
    uploaderRef.current.files = null;
  }

  function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setIsDragOver(true);
  }

  function handleDragEnter(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
  }

  function handleDragLeave(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setIsDragOver(false);
  }

  function handleDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setIsDragOver(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      // // Process the files
      // console.log(e.dataTransfer.files);
      // // If you want to use the file input for further processing, you can do so here
      // uploaderRef.current!.files = e.dataTransfer.files;
      // console.log(uploaderRef.current!.files)
      onAttachmentsChange((prev) => [...prev, ...e.dataTransfer.files])
      
      e.dataTransfer.clearData();
    }
  }

  return (
    <>
      <input
        type="file"
        id="fileupload"
        accept="*"
        ref={uploaderRef}
        className="hidden"
        onClick={(e) => (e.currentTarget.value = '')}
        onChange={handleAddFile}
      />
      <div>
        <div className={cn("border-2 border-border border-dashed rounded-lg flex items-center justify-center flex-col w-full h-80 text-muted-foreground gap-3 transition-colors", isDragOver && "border-primary")}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <UploadCloudIcon className="size-16" />
          <p>Drag and drop files here</p>
          <p>OR</p>
          <label htmlFor="fileupload" className="w-fit">
            <Button type="button" onClick={() => uploaderRef.current?.click()}>
              Browse Files
            </Button>
          </label>
        </div>
        <div className="mt-8">
          {attachments.length > 0 && (
            <p className="text-base text-muted-foreground mb-2">Added Attachments</p>
          )}
          {attachments.map((attachment, i) => (
            <div key={i} className="flex items-center flex-row gap-2">
              <div className="relative w-8">
                <FileIcon strokeWidth={1} className="size-8 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 font-extralight" />
                <p className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-xs text-[0.5rem] font-bold">{attachment.type.split('/')[1].toLocaleUpperCase()}</p>
              </div>
              <span className="text-base">{attachment.name}</span>
              <Button variant="ghost" size="icon">
                <XCircleIcon className="cursor-pointer text-destructive" onClick={() => onAttachmentsChange((prev) => prev.filter((a) => a !== attachment))} />
              </Button>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default AttachmentStep