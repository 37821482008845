// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes Mq1PaYDaLWVEtOjIenRc {
    from {
        opacity: 0;
        transform: translateY(200px) scale(0.8);
    }
    to {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

.sPOTthIAvegzGNjFDZ6L {
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 8px;
    border-radius: 8px;
    animation: Mq1PaYDaLWVEtOjIenRc 0.2s ease-in-out
}`, "",{"version":3,"sources":["webpack://./src/recker/Search/Common/Card.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,UAAU;QACV,uCAAuC;IAC3C;IACA;QACI,UAAU;QACV,iCAAiC;IACrC;AACJ;;AAEA;IACI,sBAAsB;IACtB,iDAAiD;IACjD,YAAY;IACZ,kBAAkB;IAClB;AACJ","sourcesContent":["@keyframes animateChangeCardIn {\n    from {\n        opacity: 0;\n        transform: translateY(200px) scale(0.8);\n    }\n    to {\n        opacity: 1;\n        transform: translateY(0) scale(1);\n    }\n}\n\n.changeCard {\n    background-color: #fff;\n    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;\n    padding: 8px;\n    border-radius: 8px;\n    animation: animateChangeCardIn 0.2s ease-in-out\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"changeCard": `sPOTthIAvegzGNjFDZ6L`,
	"animateChangeCardIn": `Mq1PaYDaLWVEtOjIenRc`
};
export default ___CSS_LOADER_EXPORT___;
