import React, { useEffect, useRef, useState } from 'react';
import { PlusCircle } from 'lucide-react';
import { doFileRequest, doRequest } from '../../Utils/utils';
import { Button } from '../../ui/button';
import { cn } from '../../lib/utils';
import useAttachment from '../../Common/Hooks/useAttachment';
import AttachmentItem from './AttachmentItem';

type AttachmentsViewProps = {
  recordNumber: string;
  canEdit: boolean;
}

function AttachmentsView({ recordNumber, canEdit }: AttachmentsViewProps) {
  const [attachments, setAttachments] = useState<File[]>([]);
  const [attachmentNames, setAttachmentNames] = React.useState<string[]>([]);

  const [isDragOver, setIsDragOver] = useState(false);

  const uploaderRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    // Fetch attachments for RecordNumber
    if (!recordNumber) return;

    doRequest(`/api/v1/attachments/${recordNumber}`, 'get', null, null)
      .then((data) => {
        if (data.data.files) {
          setAttachmentNames(data.data.files);
        }
      });
  }, [recordNumber]);

  const { upload: uploadAttachment } = useAttachment({ POST: '/api/v1/attachments/:recordNum' })
  const { delete: deleteAttachment } = useAttachment({ DELETE: '/api/v1/attachments/:recordNum/files/:attachmentId' })

  const loadFiles = async () => {
    // Fetch attachments
    const files: File[] = []
    const promises: Promise<void>[] = []

    attachmentNames.forEach((name) => {
      if (attachments.find((a) => a.name === name)) return;

      const promise = doFileRequest(`/api/v1/attachments/${recordNumber}/files/${name}`, 'get')
        .then((resp) => {
          files.push(new File([new Blob([resp.data])], name, { type: resp.headers['content-type'] }))
        }).catch((e) => {
          console.error(e)
        })

      promises.push(promise)
    })

    await Promise.all(promises)
      .then(() => {
        setAttachments([...attachments, ...files])
        return Promise.resolve()
      }).catch(() => {
        // handle error
      })
  }

  useEffect(() => {
    loadFiles()
  }, [attachmentNames]);

  function handleAddFile() {
    if (
      !uploaderRef.current
      || !uploaderRef.current.files
      || uploaderRef.current.files.length === 0
      || uploaderRef.current.files[0] === undefined
    ) return;

    const file = uploaderRef.current.files[0];
    uploadAttachment({ recordNum: recordNumber }, file)
    setAttachments([...attachments, file])
    uploaderRef.current.files = null;
  }

  function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setIsDragOver(true);
  }

  function handleDragEnter(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
  }

  function handleDragLeave(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setIsDragOver(false);
  }

  function handleDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setIsDragOver(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      // // Process the files
      // // If you want to use the file input for further processing, you can do so here
      // uploaderRef.current!.files = e.dataTransfer.files;
      // console.log(uploaderRef.current!.files)
      uploadAttachment({ recordNum: recordNumber }, e.dataTransfer.files[0]).then(() => {
        setAttachments([...attachments, ...e.dataTransfer.files])
        setAttachmentNames([...attachmentNames, e.dataTransfer.files[0].name])
      }).catch((err) => {
        console.error(err);
      }).finally(() => {
        e.dataTransfer.clearData();
      })
    }
  }

  function handleRemoveAttachment(attachment: File) {
    deleteAttachment({ recordNum: recordNumber, attachmentId: attachment.name })
      .then(() => {
        setAttachments(attachments.filter((a) => a.name !== attachment.name));
        setAttachmentNames(attachmentNames.filter((a) => a !== attachment.name));
      }).catch((e) => {
        console.error(e);
      })
  }

  return (
    <>
      <h3 className="font-bold text-lg mt-4 mb-2">Attachments</h3>
      <input
        type="file"
        id="fileupload"
        accept="*"
        ref={uploaderRef}
        className="hidden"
        onClick={(e) => {
          e.currentTarget.value = ''
        }}
        onChange={handleAddFile}
      />
      <div className="flex gap-4 flex-wrap">
        {attachments && attachments.map((attachment) => <AttachmentItem attachment={attachment} canEdit handleRemoveAttachment={(a) => handleRemoveAttachment(a)} />)}
        {canEdit && (
          <div
            className={cn('border-2 border-border border-dashed rounded-lg flex items-center justify-center flex-col w-40 h-40 text-muted-foreground gap-3 transition-colors', isDragOver && 'border-primary')}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <label htmlFor="fileupload" className="w-full h-full">
              <Button className="bg-none flex flex-col gap-4 w-full h-full text-muted-foreground bg-transparent hover:bg-transparent" type="button" onClick={() => uploaderRef.current?.click()}>
                <PlusCircle className="size-16" />
                <p>Add Attachment</p>
              </Button>
            </label>
          </div>
        )}
      </div>
    </>
  )
}

export default AttachmentsView;
